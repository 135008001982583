import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building1101(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building1101.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_11_01_1.geometry}
            material={materials["MI_Color_04.003"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_11_01_2.geometry}
            material={materials.MI_Color_05}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_11_01_3.geometry}
            material={materials.MI_Marble_21_SM_Building_11_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_11_01_4.geometry}
            material={materials.MI_Metal_15_SM_Building_11_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_11_01_5.geometry}
            material={materials.Material__559}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_11_01_6.geometry}
            material={materials.Material__662}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_11_01_7.geometry}
            material={materials["NewMaterial_Inst3.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_11_01_8.geometry}
            material={materials.Object_Formica_Speckled_Grey_1_Inst2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_11_01_9.geometry}
            material={materials["WorldGridMaterial.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_11_01_10.geometry}
            material={materials.adskMatWorldGridMaterial}
          />
        </group>
      </group>
    </group>
  );
}
