
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building102(props) {


  
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building102.glb");



  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_1.geometry}
            material={materials["Blacktop_Old_01.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_2.geometry}
            material={materials["Formica_Graystone.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_3.geometry}
            material={materials["Formica_Graystone_1.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_4.geometry}
            material={materials["Formica_Graystone_2.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_5.geometry}
            material={materials["Formica_Graystone_3.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_6.geometry}
            material={materials["Granite_Light_Gray_1.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_7.geometry}
            material={materials["Granite_Light_Gray_2.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_8.geometry}
            material={materials["M_0014_DarkSalmon.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_9.geometry}
            material={materials["M_0035_Tan.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_10.geometry}
            material={materials["M_0045_Goldenrod.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_11.geometry}
            material={materials.M_0045_Goldenrod_2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_12.geometry}
            material={materials.M_0045_Goldenrod_3}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_13.geometry}
            material={materials.M_0045_Goldenrod_4}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_14.geometry}
            material={materials.M_0045_Goldenrod_5}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_15.geometry}
            material={materials["M_0096_SkyBlue.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_16.geometry}
            material={materials["M_0135_DarkGray.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_17.geometry}
            material={materials["Material__489.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_18.geometry}
            material={materials["Quartz_Light_Brown.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_19.geometry}
            material={materials["Translucent_Glass_Blue.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_20.geometry}
            material={materials["Translucent_Glass_Gray.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_21.geometry}
            material={materials["Wood_Lumber_ButtJoined.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_22.geometry}
            material={materials["Wood_Veneer_02.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_23.geometry}
            material={materials["Wood_Veneer_03.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_24.geometry}
            material={materials["adskMatTranslucent_Glass_Gray_1.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_25.geometry}
            material={materials["adskMatTranslucent_Glass_Gray_2.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_01_26.geometry}
            material={materials["simple_light.002"]}
          />
        </group>
      </group>
    </group>
  );
}




