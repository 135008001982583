import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building301(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building301.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={2.54}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_1.geometry}
            material={materials.Bianca_Carrara_Gioia_Marble}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_2.geometry}
            material={materials.Black_Rose_Granite}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_3.geometry}
            material={materials.Granite_Brown}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_4.geometry}
            material={materials["M_0096_SkyBlue.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_5.geometry}
            material={materials.Material__216}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_6.geometry}
            material={materials.PLain}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_7.geometry}
            material={materials["Translucent_Glass_Gray.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_8.geometry}
            material={materials._LightGray_}
          />
        </group>
      </group>
    </group>
  );
}
