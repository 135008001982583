import { createSlice } from "@reduxjs/toolkit"

const user = createSlice({
    name: 'user',
    initialState: {
      name:'',
      currentOwner:'',
      walletAddress:'',
      fullName:'',
      gender:'',
      email:'',
      linkedENSName:{}
    },
    reducers: {
 

        getUserSlice: (state, action) => {
            state = action.payload
            
            return state
        },

    }
})

export const { getUserSlice } = user.actions

export default user.reducer