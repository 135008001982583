import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building802(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building802.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_02_1.geometry}
            material={materials.Fbx_Default_Material}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_02_2.geometry}
            material={materials["MI_Color_04.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_02_3.geometry}
            material={materials.MI_Metal_03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_02_4.geometry}
            material={materials.MI_Metal_15}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_02_5.geometry}
            material={materials.MI_Veneer_08}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_02_6.geometry}
            material={materials.Material__555}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_02_7.geometry}
            material={materials.Material__557}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_02_8.geometry}
            material={materials["NewMaterial1.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_02_9.geometry}
            material={materials.NewMaterial_Inst4}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_02_10.geometry}
            material={materials["NewMaterial_Inst5.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_02_11.geometry}
            material={materials.Object_Formica_Speckled_Grey_1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_02_12.geometry}
            material={materials["WorldGridMaterial.001"]}
          />
        </group>
      </group>
    </group>
  );
}

