
import * as api from '../../apis/index'
import * as sliceUsers from '../slice/usersSlice'
import { put, takeEvery } from 'redux-saga/effects'
import * as user from '../slice/user'
import TYPES from "../types"


export function* getUsersSaga() {
    const users = yield api.getUsersAPI()
    yield put(sliceUsers.getUsersSlice(users.data.rows))
}

export function* getUserByIdSaga(action) {
    yield api.getUserByIdAPI(action.id)
    yield put(user.getUserSlice(action.id))
}

export function* getUserByWalletAddressSaga(action) {
  
    const currentUser = yield api.getUserByWalletAPI(action.walletAddress)

    yield put(user.getUserSlice(currentUser))
   
}


export function* createUserSaga(action) {
    yield api.createUserAPI(action.user)
    yield put(sliceUsers.addUserSlice(action.user))
}

export function* updateUserSaga(action) {
    yield api.updateUserAPI(action.id,action.data)
    yield put(sliceUsers.editUserSlice(action.id,action.data))
}



export function* watchUsersAsync() {
    yield takeEvery(TYPES.GET_USERS,         getUsersSaga)
    yield takeEvery(TYPES.GET_USER_BY_ID, getUserByIdSaga)
    yield takeEvery(TYPES.GET_USER_BY_WALLET_ADDRESS, getUserByWalletAddressSaga)
    yield takeEvery(TYPES.CREATE_USER,       createUserSaga)
    yield takeEvery(TYPES.UPDATE_USER_BY_ID, updateUserSaga)


}