
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building2203(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building2203.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_03_1.geometry}
            material={materials["Fbx_Default_Material.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_03_2.geometry}
            material={materials["Fbx_Default_Material_1.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_03_3.geometry}
            material={materials["MI_Marble_01.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_03_4.geometry}
            material={materials["MI_Metal_18.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_03_5.geometry}
            material={materials.Material__780}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_03_6.geometry}
            material={materials.Material__782}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_03_7.geometry}
            material={materials.Material__783}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_03_8.geometry}
            material={materials.Material__786}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_03_9.geometry}
            material={materials.Material__787}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_03_10.geometry}
            material={materials["NewMaterial1.007"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_03_11.geometry}
            material={materials["Object_Formica_Speckled_Grey_1.011"]}
          />
        </group>
      </group>
    </group>
  );
}
