
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building2202(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building2202.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_02_1.geometry}
            material={materials["Fbx_Default_Material.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_02_2.geometry}
            material={materials.Fbx_Default_Material_1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_02_3.geometry}
            material={materials.Fbx_Default_Material_2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_02_4.geometry}
            material={materials.Fbx_Default_Material_3}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_02_5.geometry}
            material={materials.MI_Marble_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_02_6.geometry}
            material={materials.MI_Marble_18}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_02_7.geometry}
            material={materials.MI_Metal_18}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_02_8.geometry}
            material={materials.MI_Plank_04}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_02_9.geometry}
            material={materials["MI_Veneer_08.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_02_10.geometry}
            material={materials["Object_Formica_Speckled_Grey_1.010"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_02_11.geometry}
            material={materials["Object_Formica_Speckled_Grey_1_Inst1.001"]}
          />
        </group>
      </group>
    </group>
  );
}



