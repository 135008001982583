
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building2101(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building2101.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_21_01_1.geometry}
            material={materials["MI_Color_04.006"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_21_01_2.geometry}
            material={materials.MI_Metal_18_SM_Building_21_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_21_01_3.geometry}
            material={materials["NewMaterial_Inst3.010"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_21_01_4.geometry}
            material={materials["NewMaterial_Inst4.006"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_21_01_5.geometry}
            material={materials["Object_Formica_Speckled_Grey_1.008"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_21_01_6.geometry}
            material={materials.adskMatMI_Color_04}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_21_01_7.geometry}
            material={materials.adskMatMI_Metal_18_SM_Building_21_01}
          />
        </group>
      </group>
    </group>
  );
}

