import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Fragment, useState, useEffect, useRef } from "react";
import TextField from '@mui/material/TextField';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MKInput from "components/MKInput";
import { Switch } from "@mui/material";
import MKButton from "components/MKButton";
import { useDispatch, useSelector } from 'react-redux'
import TYPES from "../redux/types"
import { useAccount, useConnect, useDisconnect, useEnsName } from 'wagmi';
import CloseIcon from "@mui/icons-material/Close";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios'
import { alpha, styled } from '@mui/material/styles';

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const ThemeSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#26F8FF',
    '&:hover': {
      backgroundColor: alpha('#26F8FF', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor:'#26F8FF',
  },
  '& .MuiSwitch-thumb': {
    
    backgroundColor:'#26F8FF',
  
    
  },
}));

function Account() {
  const { register, handleSubmit, setValue,  formState: { errors }   } = useForm({ mode: 'onBlur' });
  const [gender, setGender] = useState('');
  const [userName, setUserName] = useState('');

  const [ensSwitchSetting, setEnsSwitchSetting] = useState(false);
  const [ensSwitchSettingLabel, setEnsSwitchSettingLabel] = useState("No");
  const [ensNfts, setEnsNfts] = useState([]);
  const [linkedENSName, setlinkedENSName] = useState();
  const [ensNameLink, setEnsNameLink] = useState();
  const [showEns, setShowEns] = useState(false);
  const currUser = useSelector(state => state.user);
  const ensdata = useSelector(state => state.masterInventoryEns);
  const { address, isConnected } = useAccount();
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationMessageType, setNotificationMessageType] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const dispatch = useDispatch()


  


  useEffect(() => {
    setGender(currUser.gender);
    setValue("fullName", currUser.fullName) ;
    setValue("gender", currUser.gender) ;
    setValue("email", currUser.email);
    setValue("name", currUser.name);
    setValue("linkedENSName", currUser.linkedENSName);
    setValue("youtube", currUser.youtube);
    setValue("facebook", currUser.facebook);
    setValue("twitter", currUser.twitter);
    setValue("discord", currUser.discord);
    setValue("telegram", currUser.telegram);
    setValue("instagram", currUser.instagram);
checkEvent();


  }, [currUser])



  const checkEvent =async()=>{

    const response = await axios.get(`https://mint.infinityvoid.io/gameApi/gameUser/byWallet/${address.toLowerCase()}`);
     
  if(response.data.linkedENSName && response.data.linkedENSName.currentOwner == address.toLowerCase()){
    console.log(response.data.linkedENSName);
    setEnsSwitchSetting(true);
     setEnsSwitchSettingLabel("Yes");
    setlinkedENSName(response.data.linkedENSName);
    setEnsNameLink(response.data.linkedENSName.name)
    setShowEns(true);
    }
  }




    const handleEnsSwitchSetting = async() => {
      
  
      setEnsSwitchSetting(!ensSwitchSetting);
     if(ensSwitchSettingLabel == "No"){
      setEnsSwitchSettingLabel("Yes");
      connectENS();
     }
     if(ensSwitchSettingLabel == "Yes"){
      setEnsSwitchSettingLabel("No");
     }
  
  
       
  }
 

  const connectENS = async () =>{
   console.log(ensdata);
    setEnsNfts(ensdata);
  
  }

  const unLinkEns = async ()=>{
   
    dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:linkedENSName._id, data:{linkedENSDone:false} })
    dispatch({ type: TYPES.UPDATE_USER_BY_ID, id:currUser.id, data:{linkedENSName:{}} });
    setNotificationMessage("ENS Unlinked Successfully !");
    setNotificationMessageType("success");

  setTimeout(() => window.location.reload(false), 1000).then(()=>{
    dispatch({ type: TYPES.GET_ENS,walletAddress:address.toLowerCase() })
    setEnsSwitchSetting(true)
    setShowEns(false);
    setEnsNfts(ensdata);
    setlinkedENSName();
    });

  
  }

  const linkNewEns = async ()=>{
 
   
    dispatch({ type: TYPES.GET_ENS,walletAddress:address.toLowerCase() })
    setShowEns(false);
    setEnsNfts(ensdata);

    setNotificationMessage("Link New ENS");
    setNotificationMessageType("success");
    setOpenNotification(true)


  }

  const onCreateUser= async(data)=>{

    Object.assign(data, {name:data.name.replace(/\s/g, "").toLowerCase()
  });

  const arr = data.fullName.split(" "); 
 for (var i = 0; i < arr.length; i++) {
  arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");

  Object.assign(data, {fullName:str2
});

    
     if(ensSwitchSetting == true && data.linkedENSName && data.linkedENSName.name == undefined){
      setNotificationMessage("Please Select ENS First");
      setNotificationMessageType("error");
      setOpenNotification(true) 
     }else{

    if(currUser.name == undefined){
      Object.assign(data, {walletAddress:address.toLowerCase()
       });
   
  
      dispatch({ type: TYPES.CREATE_USER, user:data })
      setNotificationMessage("Account Created Successfully");
      setNotificationMessageType("success");
      setOpenNotification(true)
      setTimeout(() => window.location.reload(false), 6000);


    }else{



      if(data.linkedENSName && data.linkedENSName.name == undefined){
        dispatch({ type: TYPES.UPDATE_USER_BY_ID, id:currUser.id, data:data })
      }
      else{
        if(ensSwitchSetting == true){
          dispatch({ type: TYPES.UPDATE_USER_BY_ID, id:currUser.id, data:data })
          dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:data.linkedENSName.id, data:{linkedENSDone:true} })
        }
        if(ensSwitchSetting == false){
         
        dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:data.linkedENSName._id, data:{linkedENSDone:false} })

          var updatedData = data;
          Object.assign(updatedData, {linkedENSName:{}
          });

          console.log(updatedData);
       dispatch({ type: TYPES.UPDATE_USER_BY_ID, id:currUser.id, data:updatedData })
         
        }
      }
   
      setNotificationMessage("Account Setting Updated Successfully");
      setNotificationMessageType("success");
      setOpenNotification(true)
      setTimeout(() => window.location.reload(false), 6000);
    
     }
    }
  }

  const handleChange = (e) => {
    setGender(e.target.value);
  };

  const handleENSChange = async(event)=>{
   
    var ensNftSelected = ensNfts.filter(e=> e.name == event.target.value.name);
    var data = ensNftSelected[0];
    if(data.linkedENSDone == true){
      setOpenNotification(true);
      setNotificationMessage("Selected ENS is already linked");
      setNotificationMessageType("warning");
      setlinkedENSName();
      window.location.reload();
    }else{
      setlinkedENSName(event.target.value);
    }

    }

  return (
    <>       
    
<Snackbar open={openNotification} autoHideDuration={6000} onClose={()=>setOpenNotification(false)}>
            <Alert onClose={()=>setOpenNotification(false)} severity={notificationMessageType}>{notificationMessage}</Alert>
      </Snackbar>

    <>
    <Grid container item xs={12} lg={12} pl={{ base: '2px', md: '10px', lg: '135px' }} pr={{ base: '2px', md: '10px', lg: '135px' }}>
    <MKBox width="100%" >

    </MKBox>

    <MKBox mt={5} mb={"120px"} width="100%" >
    <MKBox >
    <form onSubmit={handleSubmit(onCreateUser)}>
    <Grid container spacing={3}>
    <Grid item xs={12} md={4}>
    <InputLabel shrink htmlFor="bootstrap-input">
          Full Name
        </InputLabel>
     <TextField required fullWidth {...register("fullName", {required: true})} 
     onChange={(event) => {
     
      const arr = event.target.value.split(" "); 
      for (var i = 0; i < arr.length; i++) {
       arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
       }
       const str2 = arr.join(" ");
                                event.target.value = str2;
                              }} 
                              />
    </Grid>


     <Grid item xs={12} md={4}>
     <InputLabel required shrink htmlFor="bootstrap-input">
          Email
        </InputLabel>
       <MKInput required type="email"fullWidth {...register("email", {required: true})} />
     </Grid>

     <Grid item xs={12} md={4}>
     <InputLabel required shrink htmlFor="bootstrap-input">
          Gender
        </InputLabel>
                     <FormControl   fullWidth >
                    
                     <Select
                     
                     {...register("gender", {required: true})}
                       value={gender}
                      
                       onChange={handleChange}
                       style={{height:'45px'}}
                     >
                     <MenuItem value={"Male"} key={"Male"}>Male</MenuItem>
                     <MenuItem value={"Female"} key={"Female"} >Female</MenuItem>
                     </Select>
                   </FormControl>
      </Grid>


      <Grid item xs ={12} md={4}>
                   <MKBox  >
                  <MKTypography color ="secondary" variant="body2">
                   Link ENS subdomain to username
                  </MKTypography>
                  <FormControlLabel control={ 
                  <ThemeSwitch 
                
      checked={ensSwitchSetting}
      onChange={handleEnsSwitchSetting}
    
    />} 
    label={ensSwitchSettingLabel} />
    
               </MKBox>
                   </Grid>


                   {ensSwitchSetting == false ?(       
                   <Grid item xs={12} md={4}>
                             <InputLabel required shrink htmlFor="bootstrap-input">
                             User Name
        </InputLabel>
                               <TextField
                               id="user-name"
                                required  {...register("name", {required: true})}
                               maxRows={1}
                              
                               onChange={(event) => {

                                var spaceRemove = event.target.value.trim().split(/ +/).join(' ');
                                event.target.value = spaceRemove.toLowerCase();
                              }}
                              fullWidth
                               e  InputProps={{
                                endAdornment: 
                                 
                                   <InputAdornment  position="end">
                                    
                                    <MKTypography color="primary" variant="body">#{address&& address.slice(38)}</MKTypography>
                              
                                   </InputAdornment>,
                                 

                               
                              }}
                             />
                        </Grid>):(
<>
{showEns == true ? (
  <Grid item xs={12} md={8}>
                           
  <TextField
 id="user-name"
 value={ensNameLink}
 maxRows={1}
 fullWidth
  e  InputProps={{
   endAdornment: 
    
      <InputAdornment sx={{
   
     }} position="end">
       <Stack direction={"row"} spacing={2}>
      {/* <MKButton size="small" variant="outlined" onClick={()=>linkNewEns()}>Link New</MKButton> */}
       <MKButton size="small" variant="outlined" onClick={()=>unLinkEns()} >Remove Selected ENS</MKButton>
      
         </Stack>
      </InputAdornment>,}}/>
</Grid>
):(
<Grid item xs={12} md={4}>
{ensNfts && ensNfts.length>0 ? (
<>
<InputLabel required shrink htmlFor="bootstrap-input">
 ENS
</InputLabel>

<FormControl   fullWidth >

<Select

{...register("linkedENSName", {required: true})}
  value={linkedENSName}
 
  onChange={handleENSChange}
  style={{height:'45px'}}
  MenuProps={MenuProps}

>

{ensNfts.map((item) => (
<MenuItem

key={item.name}
value={item}
>
{item.name}
</MenuItem>
))}
</Select>
</FormControl>
</>
) : (<>

<InputLabel required shrink htmlFor="bootstrap-input">
 ENS
</InputLabel>

<FormControl   fullWidth>
<Select
labelId="select-ens"
id="ens-select"
style={{height:'45px'}}
>

<MenuItem


>
No ENS
</MenuItem>

</Select>
</FormControl>
</>
)}
</Grid>


                        )}    
                               
    </>

)
}





 
</Grid>
<br/>
<br/>



<MKBox >

                  
<MKBox width="100%"   mb={2} >

<MKTypography color="primary" variant="subtitle1">
Social Media Settings
</MKTypography>

</MKBox>



<Grid container spacing={3}>
<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
Youtube Link
        </InputLabel>
<MKInput placeholder={'https://youtu.be/XA7LlHZlOJw'}  fullWidth {...register("youtube")}/>
</Grid>
<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
Facebook Link
        </InputLabel>
<MKInput placeholder={'https://www.facebook.com/infinityvoid'}  fullWidth {...register("facebook")} />
</Grid>

<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
Twitter Link
        </InputLabel>
<MKInput  placeholder={'https://twitter.com/InfinityVoid_io'}  fullWidth {...register("twitter")} />
</Grid>
  </Grid>


<Grid container spacing={3}  mt={1}>
<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
Discord Link
        </InputLabel>
<MKInput placeholder={'User#id'} fullWidth {...register("discord",)}/>
</Grid>


<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
Telegram Link
        </InputLabel>
<MKInput   placeholder={'@username'} fullWidth {...register("telegram")} />
</Grid>



<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
Instagram Link
        </InputLabel>
<MKInput  placeholder={'https://www.instagram.com/infinityvoid.io'}  fullWidth {...register("instagram")} />
</Grid>
</Grid>        
</MKBox>


<br/>


<Grid  container  item xs={12} md={4} my={2} pr={{lg:'1%',md:'2%',xs:'0%'}}>
  

  <MKButton type="submit"fullWidth color="primary"  sx={{


    color:'#000',
   '&:hover': {
    backgroundColor: '#26F8FF',
    color:'#000'
  }
}}>
                               Save 
                             </MKButton>
 
                            
     </Grid>
     
    </form>
    </MKBox>
    </MKBox>

    </Grid>
    </>


      

   
    </>
  );
}

export default Account;
