
import * as api from '../../apis/index'
import * as builderSceneSlice from '../slice/builderSceneSlice'
import { put, takeEvery } from 'redux-saga/effects'
import TYPES from "../types"



export function* getBuilderSceneSaga(action) {
    const items = yield api.getBuilderSceneAPI(action.walletAddress);
   
    yield put(builderSceneSlice.getBuilderSceneSlice(items.rows))
}


export function* getBuilderSceneSearchSaga(action) {
    const itemsSearch = yield api.getBuilderSceneSearchAPI(action.walletAddress,action.searchQuerry)
 
    yield put(builderSceneSlice.getBuilderSceneSlice(itemsSearch.rows))
}

export function* updateBuilderSceneSaga(action) {
    yield api.updateBuilderSceneAPI(action.id,action.data)
    yield put(builderSceneSlice.editBuilderSceneSlice(action.id,action.data))
}
export function* createBuilderSceneSaga(action) {
    yield api.createbuilderSceneAPI(action.data)
    yield put(builderSceneSlice.addBuilderSceneSlice(action.data))
}



export function* watchBuilderSceneAsync() {
    yield takeEvery(TYPES.GET_BUILDER_SCENES,         getBuilderSceneSaga)
    yield takeEvery(TYPES.GET_BUILDER_SCENES_SEARCH,         getBuilderSceneSearchSaga)
    yield takeEvery(TYPES.UPDATE_BUILDER_SCENES,         updateBuilderSceneSaga)
    yield takeEvery(TYPES.CREATE_BUILDER_SCENES,       createBuilderSceneSaga)
   


}