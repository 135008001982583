import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Fragment, useState, useEffect, useRef } from "react";
import TextField from '@mui/material/TextField';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";

import MKButton from "components/MKButton";

import axios from 'axios'

import { Box } from "@mui/material";
import useWebSocket, { ReadyState } from 'react-use-websocket';
const socketUrl = 'wss://websocket.infinityvoid.io/';

function PlayAsGuest() {


const { register, handleSubmit,  setValue, formState: { errors }   } = useForm({ mode: 'onBlur' });


  const [dataUsers, setDataUsers] = useState();
  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => console.log('opened'),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });
  

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );


  const sendWebMessage =()=>{

    var a = window.location.search;
    var b = a.split("?");
    console.log(b[1]);

  var message = {
    event: "suscription",
    message: "wallet Connected Done",
    id: localStorage.getItem("id"),
    type: "walletConnect",
    user:b[1]
  };
  var newMessage = JSON.stringify(message);
  console.log(newMessage);
  sendMessage(newMessage)
  }

  
  useEffect(()=>{
	  setValue("email", localStorage.getItem("email"));
    setValue("name", localStorage.getItem("name"));
  },[])

  const onCreateUser= async(data)=>{
     console.log(data);
	  var a = window.location.search;
     var b = a.split("?");
     if(b.length>1){
		 console.log(b[1]);
		 
		 console.log(localStorage.getItem("id"));
if(localStorage.getItem("id") == null){
	 try {
              var url = 'https://mint.infinityvoid.io/gameApi/gameuser';
              const formData = { name: data.name, email: data.email, walletAddress: "Guest" };
              const response = await axios.post(url, formData)
              console.log(response.data);
	  localStorage.setItem("name", response.data.name);
      localStorage.setItem("email", response.data.email);
	  localStorage.setItem("id", response.data.id);
    sendWebMessage();
            } catch (error) {
              console.error(error);
            }
}else{
	 try {
              var url = 'https://mint.infinityvoid.io/gameApi/gameuser/';
			  var updateUrl = url+localStorage.getItem("id");
			  
              const formData = { name: data.name, email: data.email };
              const response = await axios.put(updateUrl, formData);
              console.log(response.data);
	  localStorage.setItem("name", response.data.name);
      localStorage.setItem("email", response.data.email);
      sendWebMessage();
            } catch (error) {
              console.error(error);
            }
	
}
           
     
  }
  }
  
  return (
    <>       
    

    <Grid container item xs={12} lg={12}>
              


              <MKBox ml={{ xs: "auto", lg: 0 }}>

              <div style={{zIndex:'2',position:'absolute', left:'50%', top:'25%', transform: 'translateX(-50%)'}}>
<MKBox >

<MKBox sx={{backgroundColor: "#0A0519"}}
p={3.5}
height="400px"
position="relative"
width="360px"
display="flex"
flexDirection="column"
borderRadius="xl"
shadow="xl"

border="1px solid #1AADB2"
>
     <MKBox  xs={12} sm={12}  md={12} lg={12} xl={12}    >

   
      <Stack >
      <MKTypography color="primary" style={{textAlign:'center'}}></MKTypography>

      </Stack>
    </MKBox>

   
                              



    <MKBox  
mt={2}
mb={2}
p={2}
width="300px"
borderRadius="xl"
shadow="xl"
bgColor="walletBox"
sx={{ display: 'flex-row', backgroundColor:'#343a40',     
'&:hover': {
backgroundColor: "#0A0519",
opacity: [1.9,1.9, 1.9],
border:"1px solid #1AADB2",
transform: "scale(1.05)"


                                      },
                                  }}    >
                                  

   
        <form autoComplete="off" onSubmit={handleSubmit(onCreateUser)} >
                <TextField 
                    label="Name"
                    {...register("name", {required: true})} 
                    required
                    variant="outlined"
                    color="secondary"
                    type="text"
                    sx={{mb: 3}}
                      fullWidth
                    
                  
                 />
                 <TextField 
                     label="Email"
                    required
                    variant="outlined"
                    color="secondary"     
                    type="email"
                    fullWidth
                      sx={{ mb: 3 }}
                      {...register("email",  {required: true})}
                 />
                 
                    
                 <Box   style={{ width: "100%", textAlign:"center"}}>  
                 <MKButton  size="small" variant="outlined"   type='submit'  >Continue</MKButton>
                 </Box>
     
     
           </form>
                               
     <br/> 

                                  <hr />
                           

                                  <Box>
                                      
                                      
          
                    


   




</Box>



</MKBox>

</MKBox>
</MKBox>
</div>

</MKBox>



</Grid>
   

    </>
  );
}

export default PlayAsGuest;
