import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building701(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building701.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[0, -1.484, -Math.PI / 2]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_07_01_1.geometry}
            material={materials.MI_Color_04}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_07_01_2.geometry}
            material={materials.MI_Metal_10_SM_Building_07_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_07_01_3.geometry}
            material={materials.Material__318}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_07_01_4.geometry}
            material={materials.Material__319}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_07_01_5.geometry}
            material={materials.Material__321}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_07_01_6.geometry}
            material={materials.Material__322}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_07_01_7.geometry}
            material={materials.Material__323}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_07_01_8.geometry}
            material={materials["NewMaterial1.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_07_01_9.geometry}
            material={materials.NewMaterial2_Inst2_SM_Building_07_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_07_01_10.geometry}
            material={materials.NewMaterial2_Inst5_SM_Building_07_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_07_01_11.geometry}
            material={materials["NewMaterial_Inst4.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_07_01_12.geometry}
            material={materials.Object_Formica_Speckled_Grey_1_Inst}
          />
        </group>
      </group>
    </group>
  );
}

