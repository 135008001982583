import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export  default  function Building101(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building101.glb");



  return (
    <group {...props} dispose={null}>
      <group rotation={[0, -1.571, 0]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001.geometry}
          material={materials["Blacktop_Old_01.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_1.geometry}
          material={materials["Formica_Graystone.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_2.geometry}
          material={materials["Formica_Graystone_1.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_3.geometry}
          material={materials["Formica_Graystone_2.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_4.geometry}
          material={materials["Formica_Graystone_3.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_5.geometry}
          material={materials["Granite_Light_Gray_1.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_6.geometry}
          material={materials["Granite_Light_Gray_2.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_7.geometry}
          material={materials["M_0014_DarkSalmon.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_8.geometry}
          material={materials["M_0035_Tan.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_9.geometry}
          material={materials["M_0045_Goldenrod.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_10.geometry}
          material={materials["M_0045_Goldenrod_1.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_11.geometry}
          material={materials["M_0096_SkyBlue.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_12.geometry}
          material={materials["M_0135_DarkGray.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_13.geometry}
          material={materials["Material__489.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_14.geometry}
          material={materials["Quartz_Light_Brown.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_15.geometry}
          material={materials["Translucent_Glass_Blue.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_16.geometry}
          material={materials["Translucent_Glass_Gray.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_17.geometry}
          material={materials["Wood_Lumber_ButtJoined.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_18.geometry}
          material={materials["Wood_Veneer_02.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_19.geometry}
          material={materials["Wood_Veneer_03.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_20.geometry}
          material={materials["adskMatTranslucent_Glass_Gray_1.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_21.geometry}
          material={materials["adskMatTranslucent_Glass_Gray_2.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_002001_22.geometry}
          material={materials["simple_light.001"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building101.glb");