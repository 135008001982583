import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building1901(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building1901.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_19_01_1.geometry}
            material={materials["MI_Color_01.007"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_19_01_2.geometry}
            material={materials["MI_Color_04.005"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_19_01_3.geometry}
            material={materials.MI_Marble_26}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_19_01_4.geometry}
            material={materials.MI_Metal_19}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_19_01_5.geometry}
            material={materials.Material__720}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_19_01_6.geometry}
            material={materials.Material__721}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_19_01_7.geometry}
            material={materials["NewMaterial_Inst3.008"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_19_01_8.geometry}
            material={materials.Object_Formica_Speckled_Grey_1_Inst3}
          />
        </group>
      </group>
    </group>
  );
}
