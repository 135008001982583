import { Fragment, useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Link } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormLabel from '@mui/material/FormLabel';
import { useDispatch , useSelector} from 'react-redux'
import TYPES from "../redux/types"
import { useNavigate } from 'react-router-dom';
import { useAccount,useNetwork } from 'wagmi';
import { useWalletClient } from 'wagmi'

import { Input, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { Stack } from "@mui/system";
import Pagination from '@mui/material/Pagination';
import { makeStyles } from '@mui/styles';
import MKPagination from "components/MKPagination";
import { select } from "redux-saga/effects";
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from "@mui/material";
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import Web3 from "web3";
import { Alchemy, Network } from "alchemy-sdk";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ethers, ContractInterface } from "ethers";
import Container from "@mui/material/Container";
import Popover from '@mui/material/Popover';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton
} from "react-share";



import { NFTStorage,  Blob } from "nft.storage"
import { Buffer } from "buffer";
import { Biconomy } from "@biconomy/mexa";
import {
  getConfig,
  getSignatureParametersWeb3
} from "../utils";



import ReactHtmlParser from 'react-html-parser';







let editorState2;


let biconomy, web3, contractInstance, tokenMetaUrl, contractTokenId, tokenSupply, formDataValue

const client = new NFTStorage({ token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGZCZTI1MjAyM0VCOTFhOTJiNTE4ZkY5NDEyNDFjRWUzZTkwODlFQjQiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3ODUxNTQ1Mzk2MywibmFtZSI6IkluZmluaXR5Vm9pZE1hcCJ9.mj7AFDSMYowKjKoMpPyTjeZfSUbJ_qLgBgMfI2dgDm4" })

const config = {
  apiKey: 'r2J0yAXJyJ4Vsh-fSm4oWIjOYlMJJ5-f',
  network: Network.MATIC_MAINNET,
};
const alchemy = new Alchemy(config);
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#000',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles({
  paginationUL: {
   
    color:'white'
  },
  paginationLI: {
    
    color:'white'
  },
  paginationButton:{
    color:'white'
  }
});

function Inventory() {
  const { register, handleSubmit, setValue,  formState: { errors }   } = useForm({ mode: 'onBlur' });

  const classes = useStyles();

  const { address, isConnected, connector: activeConnector } = useAccount();

  const { chain } = useNetwork()
  
  const { data: walletClient } = useWalletClient()

  const [config, setConfig] = useState(getConfig("").sceneMint)
  const thubnailInput = useRef();
  const [circles, setCircles] = useState(false);
  const [circlesMint, setCirclesMint] = useState(false);

  const [upload, setUpload] = useState(false);

  const [Mall, setMall] = useState(true);
  const [Land, setLand] = useState(true);
  const [Vehicle, setVehicle] = useState(true);

  const [allItems, setallItems] = useState(true);
  const [UName, setUName] = useState(true);
  const [builderScenes, setBuilderScenes] = useState(true);
  const [searchName, setSearchName] = useState();
  const [show, setShow] = useState(false);
  const [thumbFileView, setThunbFileView] = useState(false);

  const [showMintModal, setShowMintModal] = useState(false);
  const [activeSceneMint, setActiveSceneMint] = useState();
  const [filterState, setFilterState] = useState(false);
  const [shareUrl,setShareUrl] = useState();
  const [thumbCID, setThumbCID] = useState();
  const [mintDone, setmintDone] = useState();
  const [bannerStyle, setBannerStyle] = useState({display: 'none'});
  const [showBanner, setShowBanner] = useState(false);
  const [fileUpStart, setFileUpStart] = useState(false);

  const [collectionItemsNumber, setCollectionItemsNumber] = useState();
  const [cardButtonStyle, setCardButtonStyle] = useState({display: 'none'});
  const [cardButtonStyleId, setCardButtonStyleId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(12);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState();
  const [ipfsUploadDone, setIpfsUploadDone] = useState(false);

  const dispatch = useDispatch()
  const inventoryItems = useSelector(state => state.masterInventory);
  const sceneItems = useSelector(state => state.builderScene);

  var dataItems = inventoryItems.concat(sceneItems);

  const currUser = useSelector(state => state.user);
 
  const navigate = useNavigate();


  const indexOfLastItem = currentPage * dataPerPage;
  const indexOfFirstItem = indexOfLastItem - dataPerPage;
  const currentData = dataItems.slice(indexOfFirstItem, indexOfLastItem);
  const [openPopOver, setOpenPopOver] = useState(false);
  const [modalId, setModalId] = useState();

  const handleOpen = (id) => {
    setOpenPopOver(true);
    setModalId(id);
  };

  const handleClose = () => {
    setOpenPopOver(false);
    setModalId();

  };






  const timer = useRef();

  const toggleModal = (shareUrl) => {
    
    setShow(!show);
    setShareUrl(shareUrl);
  }













  useEffect(() => {
    const conf = getConfig(chain?.id.toString() || "").sceneMint
    setConfig(conf)
  }, [chain?.id])

  const handleChangeCategory = (e) => {
    setCategory(e.target.value);
  };

  const handleFileChange =async() =>{

    const file = thubnailInput.current.files[0];
    setThunbFileView(URL.createObjectURL(file));
    setCircles(true);

    const cid = await client.storeBlob(file)
   var tokenUrl = "https://ipfs.io/ipfs/"+cid;
   console.log(tokenUrl);
   setCircles(false);

    setThumbCID(tokenUrl);



  }


  useEffect(() => {
    const initBiconomy = async () => {
     
    

      biconomy = new Biconomy((useWalletClient?.provider).provider, {
        apiKey: config.apiKey.prod,
        debug: true,
        contractAddresses: [config.contract.address]
      })

      await biconomy.init()
      web3 = new Web3(window.ethereum)
      contractInstance = await new web3.eth.Contract(
        config.contract.abi,
        config.contract.address
      )
  
  

    }
    if (address && chain && useWalletClient?.provider) initBiconomy()
  }, [address, chain, config, useWalletClient?.provider])



  useEffect(()=>{

    if(currUser.name == undefined){
      navigate("/account");
    }
    })
    

  useEffect(()=>{


  var a = window.location.search;

      var b = a.split("?");

      

    if(b.length == 1){
      setMall(true);
      setallItems(true);
      setUName(true);
      setBuilderScenes(true);
      setCardButtonStyleId();
      dispatch({ type: TYPES.GET_INVENTORY,walletAddress:address && address})
      dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:address && address })
      setLoading(true);
      timer.current = window.setTimeout(() => {
       setLoading(false);
      }, 4000);
   }


       if(b[1]=="Mall"){
         setMall(true);
         setallItems(false);
         setUName(false);
         setLand(false);
         setBuilderScenes(false);
         setCardButtonStyleId();
         dispatch({ type: TYPES.GET_INVENTORY_BY_CATEGORY, category:"Mall", walletAddress:address && address })
         dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:"xyz" })
         setLoading(true);
         timer.current = window.setTimeout(() => {
           
           setLoading(false);
         
         }, 4000);
      }

      if(b[1]=="Land"){
        setLand(true);
        setallItems(false);
        setUName(false);
        setMall(false);
        setBuilderScenes(false);
        setCardButtonStyleId();
        dispatch({ type: TYPES.GET_INVENTORY_BY_CATEGORY, category:"Land", walletAddress:address && address })
        dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:"xyz" })
        setLoading(true);
        timer.current = window.setTimeout(() => {
          
          setLoading(false);
        
        }, 4000);
     }


      if(b[1]=="UName"){
         setUName(true);
         setMall(false);
         setallItems(false);
         setLand(false);
         setBuilderScenes(false);
         setCardButtonStyleId();
         dispatch({ type: TYPES.GET_INVENTORY_BY_CATEGORY, category:"UName", walletAddress:address && address })
         dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:"xyz" })
         setLoading(true);
         timer.current = window.setTimeout(() => {
           
           setLoading(false);
         
         }, 4000);
    }

    if(b[1]=="Experience"){
      setBuilderScenes(true);
      setUName(false);
      setMall(false);
      setallItems(false);
      setLand(false);
      setCardButtonStyleId();
      dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:address && address })
      dispatch({ type: TYPES.GET_INVENTORY,walletAddress:"xyz"})
      setLoading(true);
      timer.current = window.setTimeout(() => {
        
        setLoading(false);
      
      }, 4000);
 }
     

  },[address, dispatch])


  
  function convertHtmlToPlainText(html) {
    // Create a temporary DOM element
    let temp = document.createElement('div');
    temp.innerHTML = html;
  
    // Extract the text content
    let plainText = temp.textContent || temp.innerText;
  
    // Remove leading and trailing whitespace and any extra line breaks
    plainText = plainText.trim().replace(/\n\s*\n/g, '\n');
  
    // Return the plain text
    return plainText;
  }






const handlePageChange = ({ selected }) => {
  console.log(selected);
  setCurrentPage(selected + 1);
}


const toggleModalMint=(activeScene)=>{
  setActiveSceneMint(activeScene);
  setThumbCID(activeScene.sceneFileThreeD);
  setCategory(activeScene.inventoryCategory);


  setmintDone(activeScene.mintDone);
  setValue("fullName", activeScene.name);
  setValue("category", activeScene.inventoryCategory);
  setValue("description", activeScene.description);
  setValue("externalUrl", activeScene.externalLink);

  setIpfsUploadDone(true);



  showMintModalFun();
  setThumbCID(activeScene.sceneFileThreeD) 
}

const showMintModalFun =()=>{
  setShowMintModal(!showMintModal);

}


const closeMint = (e,reason)=>{
       
  console.log(reason);
    if(reason !== "backdropClick" && fileUpStart == false){
      setShowMintModal(false);
      
    }
     
  }


const onSubmitMintUpdate =async(data)=>{

  console.log(data);
  setFileUpStart(true);

  formDataValue = data;

  if (!address) {
    return
  }
 
  if (ipfsUploadDone) {

    setCirclesMint(true);

var des =  data.description+"\n\      View this scene at https://viewer.infinityvoid.io/?sceneId="+activeSceneMint.id+"&userId="+currUser.id

  var  metadata = {
    "name": data.fullName,
    "description": des,
    "externalUrl":data.externalUrl,
    "image": thumbCID,

    "attributes": [
      {
        "trait_type": "Category", 
        "value": data.category
      }
    ]

    }

     contractTokenId = activeSceneMint.tokenId;

      const json = JSON.stringify(metadata, null, 3);
      console.log(json);
      
      const file = new Blob([json], { type: "application/json" });
      const cid = await client.storeBlob(file)
       tokenMetaUrl = "https://ipfs.io/ipfs/"+cid;
       console.log(tokenMetaUrl);

       console.log(contractInstance);

       let functionSignature = await contractInstance.methods
       .updateNft(address,contractTokenId,tokenMetaUrl)
       .encodeABI()
     let message = {
  
       from: address,
       functionSignature: functionSignature
     }
     const domainType = [
       { name: "name", type: "string" },
       { name: "version", type: "string" },
       { name: "verifyingContract", type: "address" },
       { name: "salt", type: "bytes32" }
     ]
     const metaTransactionType = [
       //  { name: "nonce", type: "uint256" },
       { name: "from", type: "address" },
       { name: "functionSignature", type: "bytes" }
     ]
     let domainData = {
       name: "InfinivoidScene",
       version: "1",
       verifyingContract: config.contract.address,
       salt: "0x" + (chain?.id || 137).toString(16).padStart(64, "0")
     }
     const dataToSign = JSON.stringify({
       types: {
         EIP712Domain: domainType,
         MetaTransaction: metaTransactionType
       },
       domain: domainData,
       primaryType: "MetaTransaction",
       message: message
     })


     var abc = await activeConnector.getSigner();
    const signRes =  await abc.provider.send(
        'personal_sign',
        [ ethers.utils.hexlify(ethers.utils.toUtf8Bytes(dataToSign)), address.toLowerCase() ]
    );
   
    let { r, s, v } = getSignatureParametersWeb3(signRes)
    sendSignedTransactionUpdate(address, functionSignature, r, s, v)

     /*
     web3.currentProvider.send(
      {
        jsonrpc: "2.0",
        id: 999999999999,
        method: "eth_signTypedData_v4",
        params: [address, dataToSign]
      },
      function(error, response) {
        console.info(`User signature is ${response.result}`)
        if (error || (response && response.error)) {
        
        } else if (response && response.result) {
          let { r, s, v } = getSignatureParametersWeb3(response.result)
          sendSignedTransactionUpdate(address, data, functionSignature, r, s, v)
        }
      }
    )

    */
    }else{
      console.log("Sending normal transaction")
    }


}


const onSubmitMint =async(data)=>{

  console.log(data);
  console.log(activeSceneMint);
  formDataValue = data;

  setFileUpStart(true);

  
  if (!address) {
    return
  }
 
  if (ipfsUploadDone) {

    const response = await alchemy.nft.getNftsForContract('0xc956C61D92a805C021f7f1b41F0d0C684894181a', {omitMetadata: false});
    contractTokenId = response.nfts.length+1;

    setCirclesMint(true);

     var des =  data.description+"\n\      View this scene at https://viewer.infinityvoid.io/?sceneId="+activeSceneMint.id+"&userId="+currUser.id


  var  metadata = {
    "name": data.fullName,
    "description": des,
    "externalUrl":data.externalUrl,
    "image": thumbCID,
    "attributes": [
      {
        "trait_type": "Category", 
        "value": data.category
      }]
    }

      const json = JSON.stringify(metadata, null, 3);
      console.log(json);
      
      const file = new Blob([json], { type: "application/json" });
      
      
      
      const cid = await client.storeBlob(file)
       tokenMetaUrl = "https://ipfs.io/ipfs/"+cid;
       console.log(tokenMetaUrl);

       let functionSignature = await contractInstance.methods
       .mint(address,contractTokenId,tokenMetaUrl)
       .encodeABI()
     let message = {
  
       from: address,
       functionSignature: functionSignature
     }
     const domainType = [
       { name: "name", type: "string" },
       { name: "version", type: "string" },
       { name: "verifyingContract", type: "address" },
       { name: "salt", type: "bytes32" }
     ]
     const metaTransactionType = [
       //  { name: "nonce", type: "uint256" },
       { name: "from", type: "address" },
       { name: "functionSignature", type: "bytes" }
     ]
     let domainData = {
       name: "InfinivoidScene",
       version: "1",
       verifyingContract: config.contract.address,
       salt: "0x" + (chain?.id || 137).toString(16).padStart(64, "0")
     }
     const dataToSign = JSON.stringify({
       types: {
         EIP712Domain: domainType,
         MetaTransaction: metaTransactionType
       },
       domain: domainData,
       primaryType: "MetaTransaction",
       message: message
     })

     var abc = await activeConnector.getSigner();



     const signRes =  await abc.provider.send(
        'personal_sign',
        [ ethers.utils.hexlify(ethers.utils.toUtf8Bytes(dataToSign)), address.toLowerCase() ]
    );
   
    let { r, s, v } = getSignatureParametersWeb3(signRes)
    sendSignedTransaction(address, functionSignature, r, s, v)


    /* web3.currentProvider.send(
      {
        jsonrpc: "2.0",
        id: 999999999999,
        method: "eth_signTypedData_v4",
        params: [address, dataToSign]
      },
      function(error, response) {
        console.info(`User signature is ${response.result}`)
        if (error || (response && response.error)) {
        
        } else if (response && response.result) {
          let { r, s, v } = getSignatureParametersWeb3(response.result)
          sendSignedTransaction(address, data, functionSignature, r, s, v)
        }
      }
    )
    */
    }else{
      console.log("Sending normal transaction")
    }


}



const sendSignedTransaction = async (userAddress, data, functionData, r, s, v) => {
  try {
    const web3 = new Web3(biconomy.provider)
    const contractInstance = new web3.eth.Contract(
      config.contract.abi,
      config.contract.address
    )

    console.log(contractInstance);

    biconomy.on("txHashGenerated", data => {
   
    
    })
    biconomy.on("txMined", data => {
              
      updateDataBase(data)
    
    
    })
    const tx = await contractInstance.methods
      .mint(address,contractTokenId,tokenMetaUrl)
      .send({
        from: userAddress
      })

   
  } catch (error) {
    console.log(error)

  }
}


const sendSignedTransactionUpdate = async (userAddress, data, functionData, r, s, v) => {
  try {
    const web3 = new Web3(biconomy.provider)
    const contractInstance = new web3.eth.Contract(
      config.contract.abi,
      config.contract.address
    )

    console.log(contractTokenId);


    biconomy.on("txHashGenerated", data => {
             
     
    
    })
    biconomy.on("txMined", data => {
      updateDataBase(data)
    
    
    })
    const tx = await contractInstance.methods
      .updateNft(address,contractTokenId,tokenMetaUrl)
      .send({
        from: userAddress
      })

   
  } catch (error) {



    console.log(error)

  }
}


const updateDataBase =async(data)=>{

  if(data.receipt.status ==1){
 
        console.log(data.receipt.status);

    const newUpdatedata = {
      id:activeSceneMint.id,
      tokenId:contractTokenId,
      contractAddresses:"0x394a322585ac0f7249fc037e986d458c542cb3e1",
      mintDone:true,
      sceneFileThreeD:thumbCID,
      nftOwner:address,
      inventoryCategory:formDataValue.category,
      description:formDataValue.description,
      externalLink:formDataValue.externalUrl
    }

    dispatch({type: TYPES.UPDATE_BUILDER_SCENES, id:activeSceneMint.id, data:newUpdatedata });
    
          
  setCirclesMint(false);
  window.location.reload()
       
        }

}


const handleChangePageMUI =(event,value)=>{
setCurrentPage(value);
}
  const handleClickSearch = () => {


    dispatch({ type: TYPES.GET_INVENTORY_SEARCH, walletAddress:address && address, searchQuerry:searchName })
    dispatch({ type: TYPES.GET_BUILDER_SCENES_SEARCH, walletAddress:address && address, searchQuerry:searchName })
         

  }

  const handleFilter =()=>{

  setFilterState(!filterState);
  }
  
  
  const handleChangeSearch = (event) => {
    setCardButtonStyleId();
    setSearchName(event.target.value);
 
  };

  const handleChangeAll = (event) => {

    setLoading(true);
    timer.current = window.setTimeout(() => {
      
      setLoading(false);
      setCardButtonStyleId();
    }, 4000);



    setallItems(event.target.checked);
    setCardButtonStyleId();
    if(event.target.checked == true){
      setMall(true);
      setUName(true);
      setLand(true);
      setBuilderScenes(true);
      dispatch({ type: TYPES.GET_INVENTORY, walletAddress:address && address })
      dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:address && address })
   }else{
      setMall(false);
      setUName(false);
      setLand(false);
      setBuilderScenes(false);
      setVehicle(false);
    }


   
  };


  const handleChangeLand = (event) => {
    
    setLoading(true);
    timer.current = window.setTimeout(() => {
      
      setLoading(false);
      setCardButtonStyleId();
    }, 4000);

    setLand(event.target.checked);
  
    if(event.target.checked == true ){
      dispatch({ type: TYPES.GET_INVENTORY_BY_CATEGORY, category:"Land", walletAddress:address && address })
      dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:"xyx" && "xyz"})
    
      setallItems(false);
      setUName(false);
      setMall(false);
      setBuilderScenes(false);

    
      }

      if(event.target.checked == false ){
   
        dispatch({ type: TYPES.GET_INVENTORY, walletAddress:address && address })
        dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:address && address })
        
        setallItems(true);
        setUName(false);
        setMall(false);
        setLand(false);
        setBuilderScenes(false);
        setVehicle(false);
      
      }


  };


  const handleChangeVehicle = (event) => {
    
    setLoading(true);
    timer.current = window.setTimeout(() => {
      
      setLoading(false);
      setCardButtonStyleId();
    }, 4000);

    setVehicle(event.target.checked);
  
    if(event.target.checked == true ){
      dispatch({ type: TYPES.GET_INVENTORY_BY_CATEGORY, category:"Vehicles", walletAddress:address && address })
      dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:"xyx" && "xyz"})
    
      setallItems(false);
      setMall(false);
      setUName(false);
      setLand(false);
      setBuilderScenes(false);

    
      }

      if(event.target.checked == false ){
   
        dispatch({ type: TYPES.GET_INVENTORY, walletAddress:address && address })
        dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:address && address })
        
        setallItems(true);
        setUName(false);
        setLand(false);
        setBuilderScenes(false);
        setMall(false);
      
      }


  };


  const handleChangeMall = (event) => {
    
    setLoading(true);
    timer.current = window.setTimeout(() => {
      
      setLoading(false);
      setCardButtonStyleId();
    }, 4000);

    setMall(event.target.checked);
  
    if(event.target.checked == true ){
      dispatch({ type: TYPES.GET_INVENTORY_BY_CATEGORY, category:"Mall", walletAddress:address && address })
      dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:"xyx" && "xyz"})
    
      setallItems(false);
      setUName(false);
      setLand(false);
      setBuilderScenes(false);

    
      }

      if(event.target.checked == false ){
   
        dispatch({ type: TYPES.GET_INVENTORY, walletAddress:address && address })
        dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:address && address })
        
        setallItems(true);
        setUName(false);
        setLand(false);
        setBuilderScenes(false);
        setVehicle(false);
  
      
      }


  };

 const handleChangeUName = (event) => {

  setLoading(true);
  timer.current = window.setTimeout(() => {
    
    setLoading(false);
    setCardButtonStyleId();
  }, 4000);


  setUName(event.target.checked);
if(event.target.checked == true ){
   
  dispatch({ type: TYPES.GET_INVENTORY_BY_CATEGORY, category:"UName", walletAddress:address && address })
  dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:"xyx" && "xyz"})
  
  setallItems(false);
  setMall(false);
  setLand(false);
  setBuilderScenes(false);

}

if(event.target.checked == false ){
   
  dispatch({ type: TYPES.GET_INVENTORY,  walletAddress:address && address })
  dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:address && address})

  
  setallItems(true);
  setMall(false);
  setLand(false);
  setBuilderScenes(false);
  setVehicle(false);

}

  };



  const handleChangeExperience = (event) => {

    setLoading(true);
    timer.current = window.setTimeout(() => {
      
      setLoading(false);
      setCardButtonStyleId();
    }, 4000);
  
  
    setBuilderScenes(event.target.checked);
  if(event.target.checked == true ){
     
    dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:address && address })
    dispatch({ type: TYPES.GET_INVENTORY,  walletAddress:"xyz" && "xyz" })
   
    setallItems(false);
    setMall(false);
    setLand(false);
    setLand(false);
    
  
  }
  
  if(event.target.checked == false ){
     
    dispatch({ type: TYPES.GET_INVENTORY,  walletAddress:address && address })
    dispatch({ type: TYPES.GET_BUILDER_SCENES, walletAddress:address && address})

    
    setallItems(true);
    setMall(false);
    setLand(false);
    setBuilderScenes(false);
    setUName(false);
    setVehicle(false);
  
  }
  
    };

    const copyUrl = (url)=>{
      navigator.clipboard.writeText(url)
    
    }


  const collectionItemCard = currentData.map(item => {

    
    var Itemid = item.id;
    var OpenseaLink = item.permalink;
    var itemImage = item.imageUrl && item.imageUrl;
    var shareUrl  = "https://viewer.infinityvoid.io/?sceneId="+Itemid+"&userId="+currUser.id;
    if(item.name && item.name.slice(0,2) == "16"){
      var remainingWords = item.name && item.name.slice(13);

    }else{
      var remainingWords = item.name;

    }

    console.log(item);
   
let result = itemImage && itemImage.includes("mp4");



    return (

      <Grid item xs={12} md={6} lg={4} p={3} key={item.id} >

<MKBox key={item.id} 
     
   
   sx={{
           
             backgroundColor: "#212529",
             borderRadius:"12px",
              
           }} >


       <MKBox textAlign="center" p={1}  >
       {item.category == "UName"?(
         <MKBox textAlign="center"  >
         <MKTypography color="white" variant="footerTextCopy"  sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.name}.voidpay.eth</MKTypography>
           </MKBox>
       ):(null)
       }

{item.category == "Land"?(
        <MKBox textAlign="center" >
        <MKTypography color="white" variant="footerTextCopy" sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}} >Parcel No. {item.name}</MKTypography>
        </MKBox>
       ):(null)
       }


{item.category == "Mall"?(
      
      <MKBox textAlign="center" >
      <MKTypography color="white" variant="footerTextCopy" sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}} >Unit No. {item.name}</MKTypography>
      </MKBox>
       ):(null)
       }

{item.category == "Vehicles"?(
      
      <MKBox textAlign="center" >
      <MKTypography color="white" variant="footerTextCopy" sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}} >{item.name}</MKTypography>
      </MKBox>
       ):(null)
       }

       
{!item.category?(
      
      <MKBox textAlign="center" >
      <MKTypography color="white" variant="footerTextCopy" sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}} >{remainingWords}</MKTypography>
      </MKBox>
       ):(null)
       }

          


         
           </MKBox>

           <MKBox>

           {item.category == "Land" || item.category == "Mall"?(
    <MKBox
    minHeight="25vh"
    width="100%"
    sx={{
      backgroundImage: `url("https://dashboard.infinityvoid.io/Nftplaceholder.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
     
    }}
   
/> 

           ):(
            null
           )
           }
       
      
           {item.category == "UName"?(
    <MKBox
    minHeight="25vh"
    width="100%"
    sx={{
        backgroundImage: `url(${item.imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
     
       
      
    }}
   
/> 
      
           ):(

           null

           )
           }

                 
{item.category == "Vehicles"?(
    <MKBox
    minHeight="25vh"
    width="100%"
    sx={{
        backgroundImage: `url(${item.imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
     
       
      
    }}
   
/> 
      
           ):(

           null

           )
           }
       
        
       {!item.category?(
       <MKBox
            minHeight="25vh"
            width="100%"
            sx={{
             backgroundImage: `url(${item.sceneFileThreeD})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "grid",
                placeItems: "center",
             
               
              
            }}
           
        />):(null)
        } 


  
       {item.category == "UName"?(
  
  
  
  <Box textAlign={'center'} p={{lg:'6%', xs:'2%', md:'1%'}} >

  <Grid item container spacing={1}>
       <Grid item xs={12} md={12} lg ={12} >

  <a href={OpenseaLink} target="blank">
  
  <Button color="primary" size ="small" variant="outlined"  component="label"  sx={{
      color:'white',
      textTransform:'capitalize',
     '&:hover': {
      backgroundColor: '#26F8FF',
      color:'#000'
    }
  }}>
   Opensea Link
    </Button>
  </a>
  </Grid>
  </Grid>
  </Box>
  
  ):(
 null
  )
  }


{item.category == "Vehicles"?(
  
  
  
  <Box textAlign={'center'} p={{lg:'6%', xs:'2%', md:'1%'}} >

  <Grid item container spacing={1}>
       <Grid item xs={12} md={12} lg ={12} >

  <a href={OpenseaLink} target="blank">
  
  <Button color="primary" size ="small" variant="outlined"  component="label"  sx={{
      color:'white',
      textTransform:'capitalize',
     '&:hover': {
      backgroundColor: '#26F8FF',
      color:'#000'
    }
  }}>
   Opensea Link
    </Button>
  </a>
  </Grid>
  </Grid>
  </Box>
  
  ):(
 null
  )
  }


{item.category == "Land" || item.category == "Mall"?(
               <Box textAlign={'center'} p={{lg:'6%', xs:'2%', md:'12%'}} >
      <Grid item container spacing={1}>
       <Grid item xs={6} md={12} lg ={6} >
  <Link to={`/inventory/${item.category}/${item.id}`}>
  
  <Button  color="primary" size ="small" variant="outlined"  component="label"  sx={{
      color:'white',
      textTransform:'capitalize',
     '&:hover': {
      backgroundColor: '#26F8FF',
      color:'#000'
    }
  }}>
    Settings
    </Button>
  </Link>
  </Grid>
  
  
  <Grid item xs={6} md={12} lg ={6} >
  <a href={OpenseaLink} target="blank">
  
  <Button  color="primary" size ="small" variant="outlined"  component="label"  sx={{
      color:'white',
      textTransform:'capitalize',
     '&:hover': {
      backgroundColor: '#26F8FF',
      color:'#000'
    }
  }}>
   Opensea Link
    </Button>
  
  </a>
  </Grid>
  </Grid>
  
  </Box>
):(

  null
)}
         

         {!item.category?(
      <>
         {item.projectType == "SDK"?(
<>
<Box textAlign={'center'} p={{lg:'6%', xs:'2%', md:'1%'}} >
<Grid item container spacing={1}>
<Grid item xs={6} md={12} lg ={6} >
{item.status == "Reject"?(
<>
<Stack direction={'row'} spacing={1} >

<Button startIcon={<HighlightOffIcon  fontSize="small" sx={{color:"red"}}/>} onClick={()=>handleOpen(item.id)}
color="primary" size ="small" variant="text"  sx={{
  color:'red',
  textTransform:'capitalize',
  textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',
  '&:hover': {
  backgroundColor: 'black',
  color:'#fff',
 }
  }}
      >
      {/*Action Required */}
         Rejected
      </Button>
      </Stack>
      {modalId == item.id?(
<>
<Modal
  open={openPopOver}
  onClose={()=>handleClose()}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <MKBox
  sx={style}
>
               <MKBox display="flex" alginItems="center" justifyContent="space-between" p={1}>
                <MKTypography color="primary" variant="h5">Objection
                </MKTypography>

                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={()=>handleClose()} />
              </MKBox>

 
              <hr style={{color:'white'}} />
                <MKBox p={1}>
                < p> <span  style={{color:"white"}}>   {ReactHtmlParser(item.objection)}
                </span> </p> 
        </MKBox>
</MKBox>

</Modal>
</>

      ):(
        null
      )}

</>
):(
<>
<Stack direction={'row'} spacing={1} sx={{marginTop:"5px"}}>
  {item.status == "Pending"?(
    <>
    <PendingIcon  fontSize="small" sx={{color:"yellow"}}/>
    <MKTypography
color='white'
 variant="footerTextCopy"  sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}    >
      In Review
      </MKTypography>
      </>
  ):(
    <>
    <CheckCircleOutlineIcon  fontSize="small" sx={{color:"green"}}/>
    <MKTypography
color='white'
 variant="footerTextCopy"  sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}    >
      {item.status}
      </MKTypography>
      </>
  )}


</Stack>

</>
)}


</Grid>

<Grid item xs={6} md={12} lg ={6} >

<a href={`/uploadUnrealPackage/?${item.id}`} target="blank">

<Button  color="primary" size ="small" variant="outlined"  component="label"  sx={{
color:'white',
textTransform:'capitalize',
'&:hover': {
backgroundColor: '#26F8FF',
color:'#000'
}
}}>
Settings
</Button>

</a>

</Grid>
</Grid>
</Box>



</>
         ):(
<>
<Box textAlign={'center'} p={{lg:'6%', xs:'2%', md:'1%'}} >

<Grid item container spacing={1}>
     <Grid item xs={6} md={12} lg ={6} >

     <Button  onClick={()=>toggleModal(shareUrl)} color="primary" size ="small" variant="outlined"  component="label"  sx={{
    color:'white',
    textTransform:'capitalize',
   '&:hover': {
    backgroundColor: '#26F8FF',
    color:'#000'
  }
}}>
 Share Link

      </Button>

</Grid>

{item.mintDone == true?(<>


<Grid item xs={6} md={12} lg ={6} >

<Button  onClick={()=>toggleModalMint(item)} color="primary" size ="small" variant="outlined"  component="label"  sx={{
color:'white',
textTransform:'capitalize',
'&:hover': {
backgroundColor: '#26F8FF',
color:'#000'
}
}}>
Update Nft

</Button>

</Grid>

</>):(<>
<Grid item xs={6} md={12} lg ={6} >

<Button  onClick={()=>toggleModalMint(item)} color="primary" size ="small" variant="outlined"  component="label"  sx={{
color:'white',
textTransform:'capitalize',
'&:hover': {
backgroundColor: '#26F8FF',
color:'#000'
}
}}>
Mint Now

</Button>

</Grid>

</>)}

</Grid>

</Box>
</>
         )}
         </>
 

       ):(null)
       }

         </MKBox>
       

       </MKBox>


      </Grid>




    );
  });


  const collectionItemCardCol4 = currentData.map(item => {


    var Itemid = item.id;
    var OpenseaLink = item.permalink;
    var itemImage = item.imageUrl && item.imageUrl;
    var shareUrl  = "https://viewer.infinityvoid.io/?sceneId="+Itemid+"&userId="+currUser.id;
    if(item.name && item.name.slice(0,2) == "16"){
      var remainingWords = item.name && item.name.slice(13);

    }else{
      var remainingWords = item.name;

    }

   
let result = itemImage && itemImage.includes("mp4");
    return (

      <Grid item xs={12} md={6} lg ={3} p={3} >
<MKBox key={item.id} 
     
   
     sx={{
             
               backgroundColor: "#212529",
               borderRadius:"12px",
                
             }} >
  
  
         <MKBox textAlign="center" p={1}  >
         {item.category == "UName"?(
           <MKBox textAlign="center"  >
           <MKTypography color="white" variant="footerTextCopy"  sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.name}.voidpay.eth</MKTypography>
             </MKBox>
         ):(null)
         }
  
  {item.category == "Land"?(
          <MKBox textAlign="center" >
          <MKTypography color="white" variant="footerTextCopy" sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}} >Parcel No. {item.name}</MKTypography>
          </MKBox>
         ):(null)
         }
  
  
  {item.category == "Mall"?(
        
        <MKBox textAlign="center" >
        <MKTypography color="white" variant="footerTextCopy" sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}} >Unit No. {item.name}</MKTypography>
        </MKBox>
         ):(null)
         }


{item.category == "Vehicles"?(
        
        <MKBox textAlign="center" >
        <MKTypography color="white" variant="footerTextCopy" sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}} >{item.name}</MKTypography>
        </MKBox>
         ):(null)
         }       
  
         
  {!item.category?(
        
        <MKBox textAlign="center" >
        <MKTypography color="white" variant="footerTextCopy" sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}} >{remainingWords}</MKTypography>
        </MKBox>
         ):(null)
         }
  
            
  
  
           
             </MKBox>
  
             <MKBox>
         
        
             {item.category == "Land" || item.category == "Mall"?(
    <MKBox
    minHeight="25vh"
    width="100%"
    sx={{
      backgroundImage: `url("https://dashboard.infinityvoid.io/Nftplaceholder.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
     
       
      
    }}
   
/> 

           ):(
            null
           )
           }
       
      
           {item.category == "UName"?(
    <MKBox
    minHeight="25vh"
    width="100%"
    sx={{
        backgroundImage: `url(${item.imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
     
       
      
    }}
   
/> 
      
           ):(

           null

           )
           }


{item.category == "Vehicles"?(
    <MKBox
    minHeight="25vh"
    width="100%"
    sx={{
        backgroundImage: `url(${item.imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
     
       
      
    }}
   
/> 
      
           ):(

           null

           )
           }
       
        
       {!item.category?(
       <MKBox
            minHeight="25vh"
            width="100%"
            sx={{
             backgroundImage: `url(${item.sceneFileThreeD})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "grid",
                placeItems: "center",
             
               
              
            }}
           
        />):(null)
        } 
         
          
  
    
         {item.category == "UName"?(
    
    
    
    <Box textAlign={'center'} p={{lg:'6%', xs:'2%', md:'1%'}} >
  
    <Grid item container spacing={1}>
         <Grid item xs={12} md={12} lg ={12} >
  
    <a href={OpenseaLink} target="blank">
    
    <Button color="primary" size ="small" variant="outlined"  component="label"  sx={{
        color:'white',
        textTransform:'capitalize',
       '&:hover': {
        backgroundColor: '#26F8FF',
        color:'#000'
      }
    }}>
     Opensea Link
      </Button>
    </a>
    </Grid>
    </Grid>
    </Box>
    
    ):(
   null
    )
    }


{item.category == "Vehicles"?(
    
    
    
    <Box textAlign={'center'} p={{lg:'6%', xs:'2%', md:'1%'}} >
  
    <Grid item container spacing={1}>
         <Grid item xs={12} md={12} lg ={12} >
  
    <a href={OpenseaLink} target="blank">
    
    <Button color="primary" size ="small" variant="outlined"  component="label"  sx={{
        color:'white',
        textTransform:'capitalize',
       '&:hover': {
        backgroundColor: '#26F8FF',
        color:'#000'
      }
    }}>
     Opensea Link
      </Button>
    </a>
    </Grid>
    </Grid>
    </Box>
    
    ):(
   null
    )
    }
  
  
  {item.category == "Land" || item.category == "Mall"?(
                 <Box textAlign={'center'} p={{lg:'6%', xs:'2%', md:'12%'}} >
        <Grid item container spacing={1}>
         <Grid item xs={6} md={12} lg ={6} >
    <Link to={`/inventory/${item.category}/${item.id}`}>
    
    <Button  color="primary" size ="small" variant="outlined"  component="label"  sx={{
        color:'white',
        textTransform:'capitalize',
       '&:hover': {
        backgroundColor: '#26F8FF',
        color:'#000'
      }
    }}>
      Settings
      </Button>
    </Link>
    </Grid>
    
    
    <Grid item xs={6} md={12} lg ={6} >
    <a href={OpenseaLink} target="blank">
    
    <Button  color="primary" size ="small" variant="outlined"  component="label"  sx={{
        color:'white',
        textTransform:'capitalize',
       '&:hover': {
        backgroundColor: '#26F8FF',
        color:'#000'
      }
    }}>
     Opensea Link
      </Button>
    
    </a>
    </Grid>
    </Grid>
    
    </Box>
  ):(
  
    null
  )}
           
  
           {!item.category?(
  <>
  {item.projectType == "SDK"?(
<>
<Box textAlign={'center'} p={{lg:'6%', xs:'2%', md:'1%'}} >

<Grid item container spacing={1}>
<Grid item xs={6} md={12} lg ={6} >
{item.status == "Reject"?(
<>

<Stack direction={'row'} spacing={1} >

<Button startIcon={<HighlightOffIcon  fontSize="small" sx={{color:"red"}}/>} onClick={()=>handleOpen(item.id)}
color="primary" size ="small" variant="text"  sx={{
  color:'red',
  textTransform:'capitalize',
  textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',
  '&:hover': {
  backgroundColor: 'black',
  color:'#fff',
 }
  }}
      >
      {/* Action Required  */}
      Rejected
      </Button>
      </Stack>
      {modalId == item.id?(
<>
<Modal
  open={openPopOver}
  onClose={()=>handleClose()}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <MKBox
  sx={style}
>
               <MKBox display="flex" alginItems="center" justifyContent="space-between" p={1}>
                <MKTypography color="primary" variant="h5">Objection
                </MKTypography>

                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={()=>handleClose()} />
              </MKBox>

 
              <hr style={{color:'white'}} />
                <MKBox p={1}>



       
       < p> <span  style={{color:"white"}}>
             {ReactHtmlParser(item.objection)}
            </span> </p> 
                
      


        </MKBox>
</MKBox>

</Modal>
</>

      ):(
        null
      )}

</>
):(
<>
<Stack direction={'row'} spacing={1} sx={{marginTop:"5px"}}>
{item.status == "Pending"?(
    <>
    <PendingIcon  fontSize="small" sx={{color:"yellow"}}/>
    <MKTypography
color='white'
 variant="footerTextCopy"  sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}    >
      In Review
      </MKTypography>
      </>
  ):(
    <>
    <CheckCircleOutlineIcon  fontSize="small" sx={{color:"green"}}/>
    <MKTypography
color='white'
 variant="footerTextCopy"  sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}    >
      {item.status}
      </MKTypography>
      </>
  )}
</Stack>

</>
)}


</Grid>


<Grid item xs={6} md={12} lg ={6} >

<a href={`/uploadUnrealPackage/?${item.id}`} target="blank">

<Button  color="primary" size ="small" variant="outlined"  component="label"  sx={{
color:'white',
textTransform:'capitalize',
'&:hover': {
backgroundColor: '#26F8FF',
color:'#000'
}
}}>
Settings
</Button>

</a>

</Grid>



</Grid>

</Box>



</>
  ):(
<>
<Box textAlign={'center'} p={{lg:'6%', xs:'2%', md:'1%'}} >

<Grid item container spacing={1}>
<Grid item xs={6} md={12} lg ={6} >

<Button  onClick={()=>toggleModal(shareUrl)} color="primary" size ="small" variant="outlined"  component="label"  sx={{
color:'white',
textTransform:'capitalize',
'&:hover': {
backgroundColor: '#26F8FF',
color:'#000'
}
}}>
Share Link

</Button>

</Grid>

{item.mintDone == true?(<>


<Grid item xs={6} md={12} lg ={6} >

<Button  onClick={()=>toggleModalMint(item)} color="primary" size ="small" variant="outlined"  component="label"  sx={{
color:'white',
textTransform:'capitalize',
'&:hover': {
backgroundColor: '#26F8FF',
color:'#000'
}
}}>
Update Nft

</Button>

</Grid>

</>):(<>
<Grid item xs={6} md={12} lg ={6} >

<Button  onClick={()=>toggleModalMint(item)} color="primary" size ="small" variant="outlined"  component="label"  sx={{
color:'white',
textTransform:'capitalize',
'&:hover': {
backgroundColor: '#26F8FF',
color:'#000'
}
}}>
Mint Now

</Button>

</Grid>

</>)}

</Grid>

</Box>
</>
  )}
  </>


  
         ):(null)
         }
  
           </MKBox>
         
  
         </MKBox>

      </Grid>

    );
  });
  return (
    <>       
    

        <MKBox pt={2}>


      <Grid container item pl={{ base: '2px', md: '10px', lg: '135px' }} pr={{ base: '2px', md: '10px', lg: '135px' }} >
      <Grid item xs={12} md={3} lg={0.5} pl={{ xs:'10px',md: '2px', }} > 
      
      <IconButton  color="primary" onClick={()=>handleFilter()}>
      {filterState == false ? (
<FilterListOffIcon/>
      ):(<FilterListIcon/>)}
</IconButton>
       </Grid>
     

     

<Grid item xs={12} md={6} lg={9.5} pl={{ xs:'20px', lg: '2px'}} pr={{ xs:'20px', lg: '10px'}}>

  <FormControl  variant="outlined"   fullWidth>
    <InputLabel >Search </InputLabel>
    <OutlinedInput
      onChange={handleChangeSearch}
      onKeyDown={(e) => {
        if (e.key === "Enter") {handleClickSearch()}
    }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
          color="primary"
            aria-label="Search"
            onClick={handleClickSearch}
    
            edge="end"
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      }
      label="Search"
    />
  </FormControl>

</Grid>
<Grid item xs={12} md={3} lg={2} pl={{ xs:'20px',md: '12px', lg: '55px'}} > <MKTypography> Items Owned: {dataItems.length} </MKTypography> </Grid>

</Grid>

         {filterState == true ? (
<>
        <Grid container item pl={{ base: '2px', md: '10px', lg: '135px' }} pr={{ base: '2px', md: '10px', lg: '135px' }}>

        <Grid container >
        <Grid item xs={12} lg={3} p={4} md={3.5}>
  
            
            <Stack >

             
              <Grid container >
        <Grid item xs={6} md={10} >
        <MKTypography pt={{xs:'7px', lg:'10px'}} pb={{xs:'7px', lg:'5px'}} style={{fontSize:'14px'}}>All</MKTypography>
             
          </Grid>

          <Grid item xs={6} md={2} >
          <FormControlLabel
         control={<Checkbox
          checked={allItems}
          onChange={handleChangeAll}
         
        />}   />
          </Grid>
          
          </Grid>


          <Grid container >
        <Grid item xs={6} md={10} >
        <MKTypography pt={{xs:'7px', lg:'10px'}} pb={{xs:'7px', lg:'5px'}} style={{fontSize:'14px'}}>Virtual Mall Units</MKTypography>
                 
          </Grid>

          <Grid item xs={6} md={2} >
          <FormControlLabel
         control={<Checkbox
          checked={Mall}
          onChange={handleChangeMall}
         
        />} 
      />
          </Grid>
          
          </Grid>


          <Grid container >
        <Grid item xs={6} md={10} >
        <MKTypography pt={{xs:'7px', lg:'10px'}} pb={{xs:'7px', lg:'5px'}} style={{fontSize:'14px'}}>Virtual Land Parcel</MKTypography>
                 
          </Grid>

          <Grid item xs={6} md={2} >
          <FormControlLabel
         control={<Checkbox
          checked={Land}
          onChange={handleChangeLand}
         
        />} 
      />
          </Grid>
          
          </Grid>



          <Grid container >
        <Grid item xs={6} md={10} >
        <MKTypography pt={{xs:'7px', lg:'10px'}} pb={{xs:'7px', lg:'5px'}} style={{fontSize:'14px'}}>Unique Name</MKTypography>
                  
          </Grid>

          <Grid item xs={6} md={2} >
          <FormControlLabel
         control={<Checkbox
          checked={UName}
          onChange={handleChangeUName}
          iconStyle={{color: 'black'}}
          style ={{
            color: "#00e676",
          }}
         
        />} 
      />
          </Grid>
          
          </Grid>


          <Grid container >
        <Grid item xs={6} md={10} >
        <MKTypography pt={{xs:'7px', lg:'10px'}} pb={{xs:'7px', lg:'5px'}} style={{fontSize:'14px'}}>Experience</MKTypography>
                  
          </Grid>

          <Grid item xs={6} md={2} >
          <FormControlLabel
         control={<Checkbox
          checked={builderScenes}
          onChange={handleChangeExperience}
          iconStyle={{color: 'black'}}
          style ={{
            color: "#00e676",
          }}
         
        />} 
      />
          </Grid>


          <Grid container >
        <Grid item xs={6} md={10} >
        <MKTypography pt={{xs:'7px', lg:'10px'}} pb={{xs:'7px', lg:'5px'}} style={{fontSize:'14px'}}>Vehicles</MKTypography>
                 
          </Grid>

          <Grid item xs={6} md={2} >
          <FormControlLabel
         control={<Checkbox
          checked={Vehicle}
          onChange={handleChangeVehicle}
         
        />} 
      />
          </Grid>
          
          </Grid>
          
          </Grid>


       </Stack>
            </Grid>

       


            
            {loading == false ? (     
   <Grid item xs={12} lg={9} md={8.5}  >
   
    <Grid container >
     
{collectionItemCard}
</Grid>
</Grid>
      ) : (
        <Grid item xs={12} lg={12} md={12}  >
        <MKBox   sx={{display: "grid", placeItems: "center", textAlign:'center'}}>
        <CircularProgress
        size={38}
        sx={{
          color: "#26F8FF",
          textAlign:'center'
        }}
      />
      </MKBox>
     </Grid>
      )}

 
        </Grid>

     

      </Grid>
   <MKBox pb={'2%'} pt={'2%'} sx={{display: "grid", placeItems: "center", textAlign:'center'}}>
     
   <Pagination style={{background:'#26F8FF', borderRadius:'2px'}}  count={Math.ceil(dataItems.length / dataPerPage)} page={currentPage} onChange={handleChangePageMUI} />
   
       </MKBox>
       </>
   
         ):(
<>
          {loading == false ? (
            <Grid container item pl={{ base: '2px', md: '10px', lg: '120px' }} pr={{ base: '2px', md: '10px', lg: '120px' }} >

            <Grid container >
            
          {collectionItemCardCol4}
        
             
            </Grid>
      
          </Grid>
          ):(
            <Grid item xs={12} lg={12} md={12}  >
            <MKBox   sx={{display: "grid", placeItems: "center", textAlign:'center'}}>
            <CircularProgress
            size={38}
            sx={{
              color: "#26F8FF",
              textAlign:'center'
            }}
          />
          </MKBox>
         </Grid>
          )}
          
     
        
<MKBox pb={'2%'} pt={'2%'} sx={{display: "grid", placeItems: "center", textAlign:'center'}}>
     
     <Pagination style={{background:'#26F8FF', borderRadius:'2px', textAlign:'center'}}  count={Math.ceil(dataItems.length / dataPerPage)} page={currentPage} onChange={handleChangePageMUI} />
     
         </MKBox>
         </>

         )}





      </MKBox>

          
      <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
    <Slide direction="down" in={show} timeout={500}>
      <MKBox
        position="relative"
        width="300px"
        display="flex"
        flexDirection="column"
        borderRadius="xl"
        bgColor="black"
        shadow="xl"
        padding="20px"
      >
             
 
             
       

        <Stack spacing={2}>
       
        <TwitterShareButton
            url={shareUrl}
            title="View Scene"
          
          >
             
  <Button startIcon={<TwitterIcon />} color="primary"   variant="outlined"  component="label"  sx={{
      color:'white',
      width:'180px',
      textTransform:'capitalize',
     '&:hover': {
      backgroundColor: '#26F8FF',
      color:'#000'
    }
  }}>Share on Twitter 
    </Button>
          </TwitterShareButton>

         <FacebookShareButton 
          url={shareUrl}
          quote="View Scene"
        >
          
          <Button startIcon={<FacebookIcon />} color="primary"  variant="outlined"  component="label"  sx={{
      color:'white',
      width:'180px',
      textTransform:'capitalize',
     '&:hover': {
      backgroundColor: '#26F8FF',
      color:'#000'
    }
  }}>Share on Facebook 
    </Button>

          </FacebookShareButton> 
       
 
          <WhatsappShareButton
            url={shareUrl}
            title="View Scene"
            separator=":: "
           
          
          >
             <Button startIcon={<WhatsAppIcon />} color="primary"  variant="outlined"  component="label"  sx={{
      color:'white',
      width:'180px',
      textTransform:'capitalize',
     '&:hover': {
      backgroundColor: '#26F8FF',
      color:'#000'
    }
  }}>Share on WhatsApp 
    </Button>
          </WhatsappShareButton>


<MKBox sx={{paddingLeft:"15%"}}>
          <Button startIcon={<ContentCopyIcon />} color="primary" onClick={() => {copyUrl(shareUrl)}} variant="outlined"  component="label"  sx={{
      color:'white',
      width:'180px',
      textTransform:'capitalize',
     '&:hover': {
      backgroundColor: '#26F8FF',
      color:'#000'
    }
  }}>Copy Link
    </Button>
    </MKBox>


        
</Stack>


     
      </MKBox>
    </Slide>
  </Modal>

   





  <Modal open={showMintModal} onClose={closeMint}  
  disableEscapeKeyDown 
  sx={{ 
    display: "grid", placeItems: "center",
    overflow:'scroll',
    height:'100%'}}
    >
    <Slide direction="down" in={showMintModal}>
      <MKBox
        position="relative"
        width="100%"
        display="flex"
        flexDirection="column"
        borderRadius="xl"
        bgColor="black"
        shadow="xl"
        padding="12px"
      >
             


             
             <MKBox display="flex" alginItems="center" justifyContent="space-between">
                  <MKTypography variant="h5">Mint/Update Nft
                  </MKTypography>
                  <CloseIcon   
               
               fontSize="medium" sx={{ cursor: "pointer", zIndex:'20', color:'#fff' }} onClick={closeMint} />
                </MKBox>


            
             
       

        <Stack spacing={2}>
        
        {mintDone == true?(<>
        

          <form onSubmit={handleSubmit(onSubmitMintUpdate)}>
             
          {circlesMint == true ? (
                                      <CircularProgress
                                            size={38}
                                          sx={{
                                        color: "#26F8FF",
                                             textAlign: 'center'
                                             }}
                                      />
                         ) : (null)}
              
             <Grid pt={2} pl={{lg:'25%', xs:'2%', md:'1%'}} pr={{lg:'25%', xs:'2%', md:'1%'}} container  spacing={3} >
               
             <Grid  item xs={12} sm={12} md={6} lg={6} xl={6} >
           
           <InputLabel shrink htmlFor="bootstrap-input">
            Full Name* 
         </InputLabel>
      
         <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  {activeSceneMint.name}  </Typography>
        
       
 
         </Grid>

         <Grid  item xs={12} sm={12} md={6} lg={6} xl={6}  >
                  
                  <InputLabel shrink htmlFor="bootstrap-input">
                  View Url 
                </InputLabel>
                
                 <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>https://viewer.infinityvoid.io/?sceneId={activeSceneMint && activeSceneMint.id}&userId={currUser.id}</Typography>
       
                </Grid>

 
         <Grid  item  xs={12} sm={12} md={12} lg={6} xl={6}>
 
         <InputLabel required shrink htmlFor="bootstrap-input">
           Category
         </InputLabel>

         <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  Select Category </Typography>
         <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>

                      <FormControl   fullWidth >
                     
                      <Select
                      
                      {...register("category", {required: true})}
                        value={category}
                       
                        onChange={handleChangeCategory}
                        style={{height:'45px'}}
                      >
                      <MenuItem value={"A"} key={"A"}>A</MenuItem>
                      <MenuItem value={"B"} key={"B"} >B</MenuItem>
                      <MenuItem value={"C"} key={"C"}>C</MenuItem>
                      <MenuItem value={"D"} key={"D"} >D</MenuItem>
                      <MenuItem value={"E"} key={"E"}>E</MenuItem>
                      <MenuItem value={"F"} key={"F"} >F</MenuItem>
                      <MenuItem value={"G"} key={"G"}>G</MenuItem>
                      <MenuItem value={"H"} key={"H"} >H</MenuItem>
                      <MenuItem value={"I"} key={"I"}>I</MenuItem>
                      <MenuItem value={"J"} key={"J"} >J</MenuItem>
                      <MenuItem value={"K"} key={"K"}>K</MenuItem>
                      <MenuItem value={"L"} key={"L"} >L</MenuItem>
                      <MenuItem value={"M"} key={"M"}>M</MenuItem>
                      <MenuItem value={"N"} key={"N"} >N</MenuItem>
                      <MenuItem value={"O"} key={"O"} >O</MenuItem>
                      <MenuItem value={"P"} key={"P"} >P</MenuItem>
                     
                 
                      </Select>
                    </FormControl>
 
         </Grid>
 
 

         <Grid  item xs={12} sm={12} md={6} lg={6} xl={6}  >
                  
             
                  <InputLabel shrink htmlFor="bootstrap-input">
                   External link* 
                </InputLabel>
                
                <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}> You are welcome to link to your own webpage with more details.  </Typography>
               
                <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>
             
 
                  <TextField  fullWidth    placeholder="https://yoursite.io/item/123" {...register("externalUrl", {required: true})} />
 
 
 
                   
                </Grid>

             
             <Grid  item  xs={12} sm={12} md={12} lg={12} xl={12}  >
         
         
         
                  <InputLabel shrink htmlFor="bootstrap-input">
                        File Upload* 
                   </InputLabel>
     
                   <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  File types: PNG,JPG,Max Size: 10MB  </Typography>
        
                  <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>

                  <MKBox
                minHeight="45vh"
                width="100%"
                sx={{
                    backgroundImage: `url(${thumbCID})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                    position:'relative',
                    zIndex:'1',
                    padding:"0px",
                   
                }}
               
              
            >
 <MKBox
                minHeight="45vh"
                width="100%"
                sx={{
                 
                    '&:hover': {
                        backgroundColor: "#000",
                        opacity: [0.5, 0.5, 0.5]
                      },
                }}
                onMouseEnter={e => {
                    setBannerStyle({display: 'block'});
                }}
                onMouseLeave={e => {
                    setBannerStyle({display: 'none'})
                }}
              
            >

            <Container >
                 <Grid mt={20} container item xs={12} lg={12} justifyContent="center" mx="auto"  >
                  
                 <IconButton  color="primary"  style={{ fontSize:"50px"}}     aria-label="upload picture" component="label">
                       <input hidden type="file" accept=".png, .jpg" onChange={(e)=> handleFileChange(e)} ref={thubnailInput} />
             
                       {circles == true ? (
                               <CircularProgress
                                     size={38}
                                   sx={{
                                 color: "#26F8FF",
                                      textAlign: 'center'
                                      }}
                               />
                  ) : (
                <CloudUploadIcon />)
                }
                </IconButton>
       
              </Grid>
           
          </Container>
</MKBox>
</MKBox>
                        
                   
          
             </Grid>


 
 
                <Grid  item  xs={12} sm={12} md={12} lg={12} xl={12}>
        
              
        <InputLabel shrink htmlFor="bootstrap-input">
            Description 
        </InputLabel>
 
       <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  The description will be included on the item's detail page underneath its image. Markdown syntax is supported.  </Typography>
 
       <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>
 
 
 
 
 <TextField
 
 multiline
 rows={4}
 style={{width:"100%"}}
 placeholder="Provide a detailed description of your item."
 {...register("description", {required: true})}
 />    
 </Grid>
 
 



 
 <Grid  item  xs={12} sm={12} md={12} lg={12} xl={12}>
               
               <Button   type="submit" variant="contained" color="primary"  sx={{
 
 width:"100%",
 color:'#000',
 '&:hover': {
 backgroundColor: '#26F8FF',
 color:'#000'
 }
 }}>Update</Button>
              
 </Grid>
 
 
 
             </Grid>
         </form>

        </>):(<>
        
          <form onSubmit={handleSubmit(onSubmitMint)}>
             
          {circlesMint == true ? (
                                      <CircularProgress
                                            size={38}
                                          sx={{
                                        color: "#26F8FF",
                                             textAlign: 'center'
                                             }}
                                      />
                         ) : (null)}
              
             <Grid pt={2} pl={{lg:'30%', xs:'2%', md:'1%'}} pr={{lg:'30%', xs:'2%', md:'1%'}} container  spacing={3} >
               
               
               <Grid  item xs={12} sm={12} md={6} lg={6} xl={6} >
             
             <InputLabel shrink htmlFor="bootstrap-input">
              Full Name* 
           </InputLabel>
        
           <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  {activeSceneMint && activeSceneMint.name}  </Typography>
          
         
   
           </Grid>
  
           <Grid  item xs={12} sm={12} md={6} lg={6} xl={6}  >
                    
                    <InputLabel shrink htmlFor="bootstrap-input">
                    View Url 
                  </InputLabel>
                  
                   <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>https://viewer.infinityvoid.io/?sceneId={activeSceneMint&& activeSceneMint.id}&userId={currUser.id}</Typography>
         
                  </Grid>
 
                  <Grid  item  xs={12} sm={12} md={12} lg={6} xl={6}>
 
 <InputLabel required shrink htmlFor="bootstrap-input">
   Category
 </InputLabel>

 <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  Select Category </Typography>
 <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>

              <FormControl   fullWidth >
             
              <Select
              
              {...register("category", {required: true})}
                value={category}
               
                onChange={handleChangeCategory}
                style={{height:'45px'}}
              >
              <MenuItem value={"A"} key={"A"}>A</MenuItem>
              <MenuItem value={"B"} key={"B"} >B</MenuItem>
              <MenuItem value={"C"} key={"C"}>C</MenuItem>
              <MenuItem value={"D"} key={"D"} >D</MenuItem>
              <MenuItem value={"E"} key={"E"}>E</MenuItem>
              <MenuItem value={"F"} key={"F"} >F</MenuItem>
              <MenuItem value={"G"} key={"G"}>G</MenuItem>
              <MenuItem value={"H"} key={"H"} >H</MenuItem>
              <MenuItem value={"I"} key={"I"}>I</MenuItem>
              <MenuItem value={"J"} key={"J"} >J</MenuItem>
              <MenuItem value={"K"} key={"K"}>K</MenuItem>
              <MenuItem value={"L"} key={"L"} >L</MenuItem>
              <MenuItem value={"M"} key={"M"}>M</MenuItem>
              <MenuItem value={"N"} key={"N"} >N</MenuItem>
              <MenuItem value={"O"} key={"O"} >O</MenuItem>
              <MenuItem value={"P"} key={"P"} >P</MenuItem>
             
         
              </Select>
            </FormControl>

 </Grid>



 <Grid  item xs={12} sm={12} md={6} lg={6} xl={6}  >
          
     
          <InputLabel shrink htmlFor="bootstrap-input">
           External link* 
        </InputLabel>
        
        <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}> You are welcome to link to your own webpage with more details.  </Typography>
       
        <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>
     

          <TextField  fullWidth    placeholder="https://yoursite.io/item/123" {...register("externalUrl", {required: true})} />



           
        </Grid>

     
     <Grid  item  xs={12} sm={12} md={12} lg={12} xl={12}  >
 
 
 
          <InputLabel shrink htmlFor="bootstrap-input">
                File Upload* 
           </InputLabel>

           <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  File types: PNG,JPG,Max Size: 10MB  </Typography>

          <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>

          <MKBox
        minHeight="45vh"
        width="100%"
        sx={{
            backgroundImage: `url(${thumbCID})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
            position:'relative',
            zIndex:'1',
            padding:"0px",
           
        }}
       
      
    >
<MKBox
        minHeight="45vh"
        width="100%"
        sx={{
         
            '&:hover': {
                backgroundColor: "#000",
                opacity: [0.5, 0.5, 0.5]
              },
        }}
        onMouseEnter={e => {
            setBannerStyle({display: 'block'});
        }}
        onMouseLeave={e => {
            setBannerStyle({display: 'none'})
        }}
      
    >

    <Container >
         <Grid mt={20} container item xs={12} lg={12} justifyContent="center" mx="auto"  >
          
         <IconButton  color="primary"  style={{ fontSize:"50px"}}     aria-label="upload picture" component="label">
               <input hidden type="file" accept=".png, .jpg" onChange={(e)=> handleFileChange(e)} ref={thubnailInput} />
     
               {circles == true ? (
                       <CircularProgress
                             size={38}
                           sx={{
                         color: "#26F8FF",
                              textAlign: 'center'
                              }}
                       />
          ) : (
        <CloudUploadIcon />)
        }
        </IconButton>

      </Grid>
   
  </Container>
</MKBox>
</MKBox>
                
           
  
     </Grid>




        <Grid  item  xs={12} sm={12} md={12} lg={12} xl={12}>

      
<InputLabel shrink htmlFor="bootstrap-input">
    Description 
</InputLabel>

<Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  The description will be included on the item's detail page underneath its image. Markdown syntax is supported.  </Typography>

<Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>




<TextField

multiline
rows={4}
style={{width:"100%"}}
placeholder="Provide a detailed description of your item."
{...register("description", {required: true})}
/>    
</Grid>



 
 <Grid  item  xs={12} sm={12} md={12} lg={12} xl={12}>
               
               <Button   type="submit" variant="contained" color="primary"  sx={{
 
 width:"100%",
 color:'#000',
 '&:hover': {
 backgroundColor: '#26F8FF',
 color:'#000'
 }
 }}>MINT</Button>
              
 </Grid>
 
 
 
             </Grid>
         </form>

        </>)}

        
</Stack>


     
      </MKBox>
    </Slide>
  </Modal>

  
    </>
  );
}

 
 
export default Inventory; 


