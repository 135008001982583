import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building201(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building201.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[0.003, 0, 0.003]} rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_1.geometry}
            material={materials["M_0135_DarkGray.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_2.geometry}
            material={materials.Material__2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_3.geometry}
            material={materials.Material__413}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_4.geometry}
            material={materials.Material__414}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_5.geometry}
            material={materials.Material__416}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_6.geometry}
            material={materials.Material__417}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_7.geometry}
            material={materials.Suede_Dark_Gray}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_8.geometry}
            material={materials.Suede_Dark_Gray_1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_9.geometry}
            material={materials.Terrazzo_Tile_Light}
          />
        </group>
      </group>
    </group>
  );
}
