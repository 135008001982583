import Grid from "@mui/material/Grid";
import {  useState, useEffect, useRef } from "react";
import MKBox from "components/MKBox";

import InputLabel from '@mui/material/InputLabel';

import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { useDispatch, useSelector } from 'react-redux'
import TYPES from "../redux/types"
import { useAccount, useConnect, useDisconnect, useEnsName } from 'wagmi';
import CloseIcon from "@mui/icons-material/Close";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { alpha, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import AWS from 'aws-sdk';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";







console.log(process.env.REACT_APP_ACCES_KEY_ID)

const s3FileUpload = new AWS.S3({
  correctClockSkew:true,
  endpoint:'fra1.digitaloceanspaces.com', //Specify the correct endpoint based on where your bucket is
 
  //accessKeyId:process.env.REACT_APP_ACCES_KEY_ID,
 // secretAccessKey:process.env.REACT_APP_SECRET_ACCESS_KEY,


 accessKeyId:'DO00XXDKZP3UZCWF7U7C',
 secretAccessKey:'MkP0FbYj0yQ1G3Pi63WyNjb0zOnKwtLU+KeGw29aAvM',



  region:'fra1',//Specify the correct region name based on where your bucket is
  logger: console
  
  });





/*
AWS.config.update({
  accessKeyId: process.env.REACT_AWS_APP_ACCES_KEY_ID,
  accessSecretKey: process.env.REACT_AWS_APP_SECRET_ACCESS_KEY,
  region: "ap-south-1",
  apiVersion: '2016-11-15'
});

const s3FileUpload = new AWS.S3();
*/
let uploadRequest;

let fileNameNew;




    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
      height: 10,
      borderRadius: 5,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
      },
    }));

 {/*  
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <BorderLinearProgress  variant="determinate" {...props} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="white">{`${Math.round(
              props.value,
            )}%`}</Typography>
          </Box>
        </Box>

            */}
            
    function LinearProgressWithLabel(props) {
      return (<>

            <ProgressBar   completed={props.value} maxCompleted={100} bgColor="#26F8FF"/>
    


      </>);
    }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };

function UploadPackage() {
  
    const [file, setFile] = useState(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progress, setProgress] = useState(0);
    const[fileUrl, setFileUrl]= useState();
    const [sceneName,setSceneName]= useState();
    const [sceneId,setSceneId]= useState();
    const [updateType, setUpdateType] = useState(false);
    const dispatch = useDispatch()
    const { address, isConnected } = useAccount();
    const navigate = useNavigate();
    const [openNotification, setOpenNotification] = useState(false);
    const [notificationMessageType, setNotificationMessageType] =useState();
    const [notificationMessage, setNotificationMessage] = useState("");

    const [fileName , setFileName]= useState()


    const handleDragEnter = (e) => {
      e.preventDefault();
      setIsDragOver(true);
    };
  
    const handleDragLeave = (e) => {
      e.preventDefault();
      setIsDragOver(false);
    };
  
    const handleDragOver = (e) => {
      e.preventDefault();
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      setIsDragOver(false);
   
      const file = e.dataTransfer.files[0];
      

     if(file.size > 53687091200){
      setNotificationMessageType("error");
      setOpenNotification(true);
      setNotificationMessage("Invalid file size");

     }else{


      var extArray=['.zip'];

      const fileValid = extArray.some(el => file.name.includes(el));
 

         
         if(fileValid == true){
      setShowProgressBar(true);
 
      const currDate = Date.now();

      let newName = currDate+file.name.replace(/\s/g, "");

      console.log(newName)
      fileNameNew = newName



     let fileData = file.name.replace(/\s/g, "");
    //  var nameWithoutExtension = fileData.replace(".zip", "");
    
    var nameWithoutExtension = fileNameNew.replace(".zip", "");
console.log(nameWithoutExtension);
 
      setFileName(nameWithoutExtension)
     


    const newFile = new File([file], newName);
/*
    const params = {
      Bucket: S3_BUCKET,
      Key: newName,
      Body: newFile,
    };
          

    uploadRequest = s3FileUpload.putObject(params)
        
console.log(uploadRequest)
*/




uploadRequest = new AWS.S3.ManagedUpload({
  params: { 
    //Bucket:S3_BUCKET
   
    Bucket:'unreal-engine-sdk',
    Key:newName, 
    Body:newFile, 
    ACL: 'public-read',
    partSize:  200 * 1024 * 1024,
    queueSize: 1 },  
  service:s3FileUpload               
});




           /*    uploadRequest = new AWS.S3.ManagedUpload({
              params: { Bucket:S3_BUCKET, Key:newName, Body:newFile, ACL: 'public-read' },  
              service:s3FileUpload               
          });
*/



          
 
          
      
    uploadRequest.on("httpUploadProgress", function(evt) {
     
      setProgress(parseInt((evt.loaded * 100) / evt.total));
    })
              uploadRequest.send(function(err,response) {
                  if (err) {
                  console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));                  
                  } else {                  
                  
                    //setFileUrl(response.Location); 
                    
                    
 // Copy from saveScene Function code   in place of fileUrl we put direct response.Location
                 

                    if(response.Location !== undefined && response.Location !== null){
                      console.log("%%%%%^^^^^^********")
                     console.log(response.Location)



                     if(updateType == true  ){
                     // console.log("================")  
                      //console.log(response.Location)
                  
                      const newUpdatedata = {
                          id:sceneId,
                         // name:sceneName,
                         // name: fileName,
                           name: nameWithoutExtension, 
                         unrealSaveFile:response.Location,
                      //    projectId:fileNameNew
                        }
                    
                    //    console.log("||||||||||||")  
                     // console.log(newUpdatedata)
                        dispatch({type: TYPES.UPDATE_BUILDER_SCENES, id:sceneId, data:newUpdatedata });
                      
                     }else{
                  var saveData = {
                  
                      data:{
                  // name:sceneName,
                  // name: fileName,
                  name: nameWithoutExtension,
                  projectType:"SDK",
                  fileOwner:address.toLowerCase(),
                  unrealSaveFile:response.Location,
                //  projectId:fileNameNew
                  }
                  }
                  
                  
                  console.log("&&&&&&&&&&&&&")  
                  console.log(saveData)
                  
                     dispatch({ type: TYPES.CREATE_BUILDER_SCENES, data:saveData });
                  
                     }
                  
                  



                    }





                  }
                  
            });
            
     }else{
      setNotificationMessageType("error");
      setOpenNotification(true);
      setNotificationMessage("Invalid file type");
     }
    }



    };

useEffect(()=>{
    async function logJSONData() {

        var a = window.location.search;
        var b = a.split("?");
         if(b.length > 1){
         console.log(b[1]);
         
        const response = await fetch(`https://mint.infinityvoid.io/gameApi/builderscene/${b[1]}`);
        const jsonData = await response.json();
        setUpdateType(true);
        setSceneName(jsonData.name);
        setSceneId(jsonData.id);
    }
      }

      logJSONData()

},[])
   


{/*
const saveScene = async()=>{
   if(updateType == true){
    const newUpdatedata = {
        id:sceneId,
       // name:sceneName,
        name: fileName,
        unrealSaveFile:fileUrl,
        projectId:fileNameNew
      }
  

      console.loh(newUpdatedata)

      dispatch({type: TYPES.UPDATE_BUILDER_SCENES, id:sceneId, data:newUpdatedata });
    
   }else{
var saveData = {

    data:{
// name:sceneName,
name: fileName,
projectType:"SDK",
fileOwner:address.toLowerCase(),
unrealSaveFile:fileUrl,
projectId:fileNameNew
}
}


console.log(saveData)
   dispatch({ type: TYPES.CREATE_BUILDER_SCENES, data:saveData });

   }


    }

  */}



  









    
const cancelFileUpload =()=>{
  uploadRequest.abort();
  setShowProgressBar(false);
  window.location.reload(false);

}




    const handleFileUpload = async(event) => {

      
 
        const file = event.target.files[0];
     

        if(file.size > 53687091200){
          setNotificationMessageType("error");
          setOpenNotification(true);
          setNotificationMessage("Invalid file size");
    
         }else{
    
       var ext = file.name.substring(file.name.lastIndexOf('.') + 1);
     
       var extArray=['.zip'];

       const fileValid = extArray.some(el => file.name.includes(el));
  

          
          if(fileValid == true){
          setShowProgressBar(true);
     
          const currDate = Date.now();
    

          let newName = currDate+file.name.replace(/\s/g, "");
      
          console.log(newName)
          fileNameNew = newName
          
          
          let fileData =file.name.replace(/\s/g, "");
       //   var nameWithoutExtension = fileData.replace(".zip", "");
       
       var nameWithoutExtension = fileNameNew.replace(".zip", "");
console.log(nameWithoutExtension);
     
          setFileName(nameWithoutExtension)
         


               console.log(newName)
              const newFile = new File([file], newName);
          
                console.log(newFile)

       /*       const params = {
                Bucket: S3_BUCKET,
                Key: newName,
                Body: newFile,
              };
                    
                
                  uploadRequest = s3FileUpload.putObject(params)
*/
               

uploadRequest = new AWS.S3.ManagedUpload({
  params: { 
    //Bucket:S3_BUCKET
 //   Bucket:'development-test',
    Bucket:'unreal-engine-sdk',
    Key:newName, 
    Body:newFile, 
    ACL: 'public-read',
    partSize:  200 * 1024 * 1024,
    queueSize: 1 },  
  service:s3FileUpload               
});


      
              uploadRequest.on("httpUploadProgress", function(evt) {
         
          setProgress(parseInt((evt.loaded * 100) / evt.total));
        })
                  uploadRequest.send(function(err,response) {
                      if (err) {
                      console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));                  
                      } else {                  
                      
                       // setFileUrl(response.Location);      
           
                      // Copy from saveScene Function code   in place of fileUrl we put direct response.Location
                        if(response.Location !== undefined && response.Location !== null){
                          console.log("%%%%%^^^^^^********")
                         console.log(response.Location)



                         if(updateType == true  ){
                         // console.log("================")  
                          //console.log(response.Location)
                      
                          const newUpdatedata = {
                              id:sceneId,
                             // name:sceneName,
                             // name: fileName,
                               name: nameWithoutExtension, 
                             unrealSaveFile:response.Location,
                            //  projectId:fileNameNew
                            }
                        
                        //    console.log("||||||||||||")  
                         // console.log(newUpdatedata)
                            dispatch({type: TYPES.UPDATE_BUILDER_SCENES, id:sceneId, data:newUpdatedata });
                          
                         }else{
                      var saveData = {
                      
                          data:{
                      // name:sceneName,
                      // name: fileName,
                      name: nameWithoutExtension,
                      projectType:"SDK",
                      fileOwner:address.toLowerCase(),
                      unrealSaveFile:response.Location,
                     // projectId:fileNameNew
                      }
                      }
                      
                      
                      console.log("&&&&&&&&&&&&&")  
                      console.log(saveData)
                      
                         dispatch({ type: TYPES.CREATE_BUILDER_SCENES, data:saveData });
                      
                         }
                      
                      



                        }


                      }
                      
                });

                
         }else{
          setNotificationMessageType("error");
          setOpenNotification(true);
          setNotificationMessage("Invalid file type");
         }
        }
    
    
    };
    
  {/*
    console.log("===============")
console.log(fileUrl)
  */}


  return (
    <>       
    
    <Snackbar open={openNotification} autoHideDuration={6000} onClose={()=>setOpenNotification(false)}>
            <Alert onClose={()=>setOpenNotification(false)} severity={notificationMessageType}>{notificationMessage}</Alert>
      </Snackbar>

    <Grid container item xs={12} lg={12} pl={{ base: '2px', md: '10px', lg: '135px' }} pr={{ base: '2px', md: '10px', lg: '135px' }}>
{/*
    <MKBox mt={5} mb={"10px"} width="100%"  >
    <Grid container spacing={3}>
    <Grid item xs={12} md={4}>
    <InputLabel shrink htmlFor="bootstrap-input"  style={{fontSize:"20px", color:"white"}}>
          EXPERIENCE NAME
        </InputLabel>
             <MKInput type="text" value={sceneName} fullWidth onChange={e=> setSceneName(e.target.value)} />
    </Grid>


     <Grid item xs={12} md={4}>
   
     </Grid>

     <Grid item xs={12} md={4}>
    
      </Grid>


     </Grid>
     
   

    </MKBox>
  */}
              
    <MKBox mt={5} mb={"120px"} width="100%"  >
    
    <Grid container >
    <Grid item xs={12} md={12}  lg={12}  >
          <InputLabel shrink htmlFor="bootstrap-input"  style={{fontSize:"20px", color:"white"}}>
            UPLOAD PACKAGE
          </InputLabel>
            
                              

          <Grid container item  xs={12} sm={12} md={12} lg={12} xl={12} p={5} style={{border:"2px solid #fa7fcb"}}>
                       <Grid container item  xs={12} sm={12} md={12} lg={12} xl={12} component="span"   
                                    
                                   
                           
                                    sx={{
                                      border: '2px dashed #fa7fcb',
                                
                                   backgroundPosition: "center",
                                   display: "grid",
                                    placeItems: "center",
                                 //   position:'relative',
                                    zIndex:'1',
                                  //  padding:"0px",
                                      height: "460px",
                                    //  borderRadius: "10px",
                                      
                                      '&:hover': {
                                       backgroundColor: "#000",
                                       opacity: [0.5, 0.5, 0.5]
                                     },
                             
                                    }}
          
               > {/*
                              {showProgressBar == true ? (<>
                                        <Grid style={{ paddingTop: "60px", textAlign: "center" }}>
                                              <Box  sx={{ width: '100%' }}>
                                                     <LinearProgressWithLabel variant="determinate"
                                                     value={progress} 
                                                     />
                                                 </Box>
                                          
                                        {progress == 100?(
                                            <Box  style={{  padding:"10px"}}>
                                            <Typography variant="body2" color="text.secondary"  style={{color:"#fa7fcb"}}>File Uploaded SuccessFully</Typography>
                                        </Box>

                                        ):(null)}
                                              
                                        </Grid>
                                     </>) :
                              
                                     (<></>)}

                                        */}

                                         <Grid style={{ paddingTop: "60px", textAlign: "center", width:"100%" }}
                                          className={`drop-zone ${isDragOver ? 'dragover' : ''}`}
                                          onDragEnter={handleDragEnter}
                                          onDragLeave={handleDragLeave}
                                          onDragOver={handleDragOver}
                                              onDrop={handleDrop}
                                              >
                                          
                                  {/*
                                          <Box>
                                                  <IconButton color="primary" style={{ fontSize: "8vw" }} aria-label="upload picture" component="label">
                                                      <input hidden type="file" accept=".zip" onChange={handleFileUpload} />
                                      
                                                      <CloudUploadIcon />
                                             
                                      
                                                  </IconButton>
                                              </Box>

                                              <Box style={{ textAlign: "center" }}>
                                                  <Box>
                                                      <Typography style={{ fontSize: "13px", color: "white" }}>
                                                          Drag + drop or browse your package
                                                      </Typography>
                                                  </Box>
                                              </Box>
                                      */}


                                              {showProgressBar == true?(
                                                <>


                                            <Box  sx={{ width: '100%', }}>
                                            <LinearProgressWithLabel variant="determinate"
                                            value={progress} 
                                          
                                            />
                                            <br/>
                                            <MKButton type="secondary" onClick={()=>cancelFileUpload()}>Cancel </MKButton>
                                            
                                        </Box>


                                        </>
                                        ):(
                              <>
                              
                              <Box>
                                                  <IconButton color="primary" style={{ fontSize: "8vw" }} aria-label="upload picture" component="label">
                                                      <input hidden type="file" accept=".zip" onChange={handleFileUpload} />
                                      
                                                      <CloudUploadIcon />
                                             
                                      
                                                  </IconButton>
                                              </Box>

                                              <Box style={{ textAlign: "center" }}>
                                                  <Box>
                                                      <Typography style={{ fontSize: "13px", color: "white" }}>
                                                          Drag + drop or browse your package
                                                      </Typography>
                                                  </Box>
                                              </Box>



                                              <Box style={{ paddingTop: "20px", textAlign: "center", }}>
                                                  <Box>
                                                      <Typography style={{ fontSize: "13px", color: "white" }}>
                                                          Please browse and select the zipped, pixelstreaming-enabled Unreal Engine project from your
                                                          computer. You can also drag and drop the file here to start the automatic process. You can check the upload
                                                          status using the progress bar. Max file size is 50 GB
                                                      </Typography>

                                                  </Box>
                                              </Box>



                              </>
                                        )}

                                              {progress == 100?(
                                            <Box  style={{  padding:"10px"}}>
                                            <Typography variant="body2" color="text.secondary"  style={{color:"#fa7fcb"}}>File Uploaded Successfully</Typography>
                                        </Box>

                                        ):(null)}
                                             
                                           

                                           
                              
                                {/*
                                             <Box style={{ paddingTop: "20px", textAlign: "center", }}>
                                                  <Box>
                                                      <Typography style={{ fontSize: "13px", color: "white" }}>
                                                          Please browse and select the zipped, pixelstreaming-enabled Unreal Engine project from your
                                                          computer. You can also drag and drop the file here to start the automatic process. You can check the upload
                                                          status using the progress bar. Max file size is 50 GB
                                                      </Typography>

                                                  </Box>
                                              </Box>
                                              */}   
                                           
                                           
                                </Grid>
                                              
                        
                                      


                 </Grid>
                                                 

           </Grid>  

    </Grid>


    </Grid>
     
    
    </MKBox>




{/*
    <MKBox mt={"-100px"} mb={"120px"} width="100%"  >
    <Grid container spacing={3}>
    <Grid item xs={12} md={4}>
  
    <MKButton onClick={()=>saveScene()} fullWidth color="primary"  sx={{


color:'#000',
'&:hover': {
backgroundColor: '#26F8FF',
color:'#000'
}
}}>
                           Save 
                         </MKButton>
    </Grid>

     </Grid>
     
   

    </MKBox>

*/}



    </Grid>

   
    </>
  );
}

export default UploadPackage;
