
import React, { useRef, useEffect,lazy} from "react";

import Building101 from "./buildingDesign/Building101";
import Building102 from "./buildingDesign/Building102";
import Building103 from "./buildingDesign/Building103";
import Building201 from "./buildingDesign/Building201";
import Building202 from "./buildingDesign/Building202";
import Building301 from "./buildingDesign/Building301";
import Building302 from "./buildingDesign/Building302";
import Building501 from "./buildingDesign/Building501";
import Building502 from "./buildingDesign/Building502";
import Building503 from "./buildingDesign/Building503";
import Building504 from "./buildingDesign/Building504";
import Building701 from "./buildingDesign/Building701";
import Building702 from "./buildingDesign/Building702";
import Building703 from "./buildingDesign/Building703";
import Building801 from "./buildingDesign/Building801";
import Building802 from "./buildingDesign/Building802";
import Building1101 from "./buildingDesign/Building1101";
import Building1201 from "./buildingDesign/Building1201";
import Building1202 from "./buildingDesign/Building1202";
import Building1203 from "./buildingDesign/Building1203";
import Building1401 from "./buildingDesign/Building1401";
import Building1501 from "./buildingDesign/Building1501";
import Building1601 from "./buildingDesign/Building1601";
import Building1701 from "./buildingDesign/Building1701";
import Building1901 from "./buildingDesign/Building1901";
import Building2001 from "./buildingDesign/Building2001";
import Building2101 from "./buildingDesign/Building2101";
import Building2201 from "./buildingDesign/Building2201"; 
import Building2202 from "./buildingDesign/Building2202";
import Building2203 from "./buildingDesign/Building2203";
import BuildingNull from "./buildingDesign/BuildingNull";





let ActiveCompGround = Building101;
let ActiveCompUpper = BuildingNull;

function BuildingLayout(dataNew, baseId,topId,buildingId) {

console.log(dataNew.props)

if(dataNew !== undefined){


      let dataNameBase = dataNew.props.baseId
      let dataNameUpper = dataNew.props.topId

 
      console.log(dataNew.props);

      if(dataNameBase == 'Building101'){
            ActiveCompGround =  Building101;
      }

      if(dataNameBase == 'Building102'){
           ActiveCompGround = Building102;
      }
      if(dataNameBase == 'Building103'){
            ActiveCompGround = Building103;
      }
      if(dataNameBase == 'Building201'){
            ActiveCompGround = Building201;
       }
       if(dataNameBase == 'Building202'){
             ActiveCompGround = Building202;
       }
       if(dataNameBase == 'Building301'){
            ActiveCompGround = Building301;
       }
       if(dataNameBase == 'Building302'){
             ActiveCompGround = Building302;
       }
       if(dataNameBase == 'Building501'){
            ActiveCompGround = Building501;
       }
       if(dataNameBase == 'Building502'){
             ActiveCompGround = Building502;
       }
       if(dataNameBase == 'Building503'){
            ActiveCompGround = Building503;
      }
     if(dataNameBase == 'Building504'){
            ActiveCompGround = Building504;
      }
      if(dataNameBase == 'Building701'){
            ActiveCompGround = Building701;
      }
      if(dataNameBase == 'Building702'){
            ActiveCompGround = Building702;
      }
      if(dataNameBase == 'Building703'){
            ActiveCompGround = Building703;
      }
      if(dataNameBase == 'Building801'){
            ActiveCompGround = Building801;
      }
      if(dataNameBase == 'Building802'){
            ActiveCompGround = Building802;
      }
      if(dataNameBase == 'Building1101'){
            ActiveCompGround = Building1101;
      }
      if(dataNameBase == 'Building1201'){
            ActiveCompGround = Building1201;
      }
      if(dataNameBase == 'Building1202'){
            ActiveCompGround = Building1202;
      }
      if(dataNameBase == 'Building1203'){
            ActiveCompGround = Building1203;
      }
      if(dataNameUpper == 'Building1401'){
            ActiveCompUpper = Building1401;
      }
      if(dataNameUpper == 'Building1501'){
            ActiveCompUpper = Building1501;
      }
      
      if(dataNameUpper == 'Building1601'){
            ActiveCompUpper = Building1601;
      }
      
      if(dataNameBase == 'Building1701'){
            ActiveCompGround = Building1701;
      }
      
      if(dataNameUpper == 'Building1901'){
            ActiveCompUpper = Building1901;
      }
      
      if(dataNameUpper == 'Building2001'){
            ActiveCompUpper = Building2001;
      }
      

      if(dataNameUpper == 'Building2101'){
            ActiveCompUpper = Building2101;
      }
      
      
      if(dataNameBase == 'Building2201'){
            ActiveCompGround = Building2201;
      }
      
      
      if(dataNameBase == 'Building2202'){
            ActiveCompGround = Building2202;
      }
      
      
      if(dataNameBase == 'Building2203'){
            ActiveCompGround = Building2203;
      }
      

}


      return (
         <>
                 

            <group    >
                  <group name="ground" position={[-20.78, 0, -5.88]}>

                      <ActiveCompGround props="Active"/> 

                    
                  </group>
                  <group name="upper" position={[-20.78, 12.45, -5.58]}>
                      <ActiveCompUpper/>
                     
                  </group>
            </group>
        
            </>
  );
}

export default BuildingLayout;





