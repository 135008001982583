
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building801(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building801.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_01_1.geometry}
            material={materials.MI_Color_04}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_01_2.geometry}
            material={materials.MI_Metal_03_SM_Building_08_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_01_3.geometry}
            material={materials.MI_Veneer_09_SM_Building_08_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_01_4.geometry}
            material={materials.Material__532}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_01_5.geometry}
            material={materials.NewMaterial1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_01_6.geometry}
            material={materials.NewMaterial_Inst2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_01_7.geometry}
            material={materials.NewMaterial_Inst3}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_01_8.geometry}
            material={materials.NewMaterial_Inst5}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_08_01_9.geometry}
            material={materials.WorldGridMaterial}
          />
        </group>
      </group>
    </group>
  );
}

