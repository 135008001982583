import { Fragment, useState,useRef, useEffect,Suspense, } from "react";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import MKButton from "components/MKButton";
import Divider from '@mui/material/Divider';
import { Stack } from "@mui/system";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import MKAvatar from "components/MKAvatar";
import S3FileUpload from 'react-s3';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MKInput from "components/MKInput";
import Switch from '@mui/material/Switch'
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import ShopFImage from "../assets/images/shopF.png";
import ShopSideImage from "../assets/images/shopSide.png";
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { useNavigate } from 'react-router-dom';
import { useDispatch , useSelector} from 'react-redux'
import TYPES from "../redux/types"
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { useAccount, useConnect, useDisconnect, useEnsName } from 'wagmi';
import Alert from '@mui/material/Alert';
import axios from 'axios'
import Snackbar from '@mui/material/Snackbar';
import { alpha } from '@mui/material/styles';
import { Box } from "@mui/material";
import { Link } from 'react-router-dom';
import AWS from 'aws-sdk';
import Experience from "./Experience";
import { Canvas, extend, useThree, useFrame } from "@react-three/fiber";
import { Html, useProgress } from '@react-three/drei'
import {
  CubeTextureLoader,

} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Sky } from "@react-three/drei";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

extend({ OrbitControls });

let uploadRequest;
let SharedLink;

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCES_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
}


const s3FileUpload = new AWS.S3({
    
  correctClockSkew:true,
  
  endpoint:'fra1.digitaloceanspaces.com', //Specify the correct endpoint based on where your bucket is
  
  accessKeyId:process.env.REACT_APP_ACCES_KEY_ID,
  
  secretAccessKey:process.env.REACT_APP_SECRET_ACCESS_KEY,
  
  region:'fra1'//Specify the correct region name based on where your bucket is
  
  ,logger: console
  
  });


 


const Tab = styled(TabUnstyled)`
font-family: proxima_nova_rgregular, sans-serif;  
color:white;
cursor: pointer;
font-size: 0.875rem;
font-weight: bold;
background-color: transparent;
outline: 3px solid #26F8FF;
width: 15%;
padding: 12px;
margin: 6px 6px;
border: none;
border-radius: 7px;
display: flex;
justify-content: center;


&:hover {
  background-color: #26F8FF;
  color: black;
}

&:focus {
  color: black;
  outline: 3px solid #26F8FF;
}

&.${tabUnstyledClasses.selected} {
  background-color: #26F8FF;
  color: black;
}

&.${buttonUnstyledClasses.disabled} {
  background-color: white;
  color: black;
  outline: 3px solid #26F8FF;
  cursor: not-allowed;
}
`;

const TabPanel = styled(TabPanelUnstyled)`
font-family: proxima_nova_rgregular, sans-serif;
width: 100%;
 font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)(
({ theme }) => `
min-width: 400px;

border-radius: 12px;
margin-bottom: 16px;
display: flex;
align-items: center;
justify-content: center;
align-content: space-between;
box-shadow: 0px 4px 8px ;
`,
);

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const ThemeSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#26F8FF',
    '&:hover': {
      backgroundColor: alpha('#26F8FF', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor:'#26F8FF',
  },
  '& .MuiSwitch-thumb': {
    
    backgroundColor:'#26F8FF',
  
    
  },
}));





let unitDetail;

let ThreeDUrl;
let activeSceneNew;


function InventoryDetail() {

  const [ensNfts, setEnsNfts] = useState([]);
  const [valueEns, setValueEns] = useState("");
  const { address, isConnected } = useAccount();

const [contractCardInput, setContractCardInput] = useState();
const [bannerCard, setBannerCard] = useState();
const [modalCard, setModalCard] = useState();
const [bannerItems, setBannerItems] = useState([]);
const [show, setShow] = useState(false);

const [isLand, setIsLand] = useState(false);


const [sideBannerCard, setSideBannerCard] = useState();
const [sideModalCard, setSideModalCard] = useState();
const [sideBannerItems, setSideBannerItems] = useState([]);
const [showSide, setShowSide] = useState(false);
const [eventSetting, setEventSetting] = useState(false);
const [eventSettingLabel, setEventSettingLabel] = useState("Public");
const [allowedWalletAddress, setAllowedWalletAddress] = useState();
const [unitDescription, setUnitDescription] = useState();
const [webUrl, setWebUrl] = useState();
const [disCount, setDisCount] = useState('0');

const [viewLayoutUrl, setViewLayoutUrl] = useState();
const [downloadPlanLayoutUrl, setDownloadPlanLayoutUrl] = useState();
const [downloadLayoutUrl, setDownloadLayoutUrl] = useState();
const [allowedContractAddressInput, setAllowedContractAddressInput] = useState();
const [allowedContractAddress, setAllowedContractAddress] = useState([]);

const [inputContract, setInputContract] = useState([]);
  const dispatch = useDispatch()
  const itemDetail = useSelector(state => state.masterInventoryDetail);
  const ensdata = useSelector(state => state.masterInventoryEns);

  const currUser = useSelector(state => state.user);
  const navigate = useNavigate();
  const [linkedENSName, setlinkedENSName] = useState();
  const [ensNameLink, setEnsNameLink] = useState();
  const [showEns, setShowEns] = useState(false);
  const [showScenes, setShowScenes] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessageType, setNotificationMessageType] = useState("");
  const [e1BannerTopImage, setE1BannerTopImage] = useState("");
  const [e1BannerTopImageUrl, setE1BannerTopImageUrl] = useState('');
  const [e1BannerLeftImage, setE1BannerLeftImage] = useState("");
  const [e1BannerLeftImageUrl, setE1BannerLeftImageUrl] = useState('');
  const [e1BannerRightImage, setE1BannerRightImage] = useState("");
  const [e1BannerRightImageUrl, setE1BannerRightImageUrl] = useState('');

const [e2BannerTopImage, setE2BannerTopImage] = useState("");
const [e2BannerTopImageUrl, setE2BannerTopImageUrl] = useState('');
const [e2BannerLeftImage, setE2BannerLeftImage] = useState("");
const [e2BannerLeftImageUrl, setE2BannerLeftImageUrl] = useState('');
const [e2BannerRightImage, setE2BannerRightImage] = useState("");
const [e2BannerRightImageUrl, setE2BannerRightImageUrl] = useState('');

const [e3BannerTopImage, setE3BannerTopImage] = useState("");
const [e3BannerTopImageUrl, setE3BannerTopImageUrl] = useState('');
const [e3BannerLeftImage, setE3BannerLeftImage] = useState("");
const [e3BannerLeftImageUrl, setE3BannerLeftImageUrl] = useState('');
const [e3BannerRightImage, setE3BannerRightImage] = useState("");
const [e3BannerRightImageUrl, setE3BannerRightImageUrl] = useState('');

const [s1SideTopImage, setS1SideTopImage] = useState("");
const [s1SideTopImageUrl, setS1SideTopImageUrl] = useState('');
const [s1SideCenterImage, setS1SideCenterImage] = useState("");
const [s1SideCenterImageUrl, setS1SideCenterImageUrl] = useState('');

const [s2SideTopImage, setS2SideTopImage] = useState("");
const [s2SideTopImageUrl, setS2SideTopImageUrl] = useState('');
const [s2SideCenterImage, setS2SideCenterImage] = useState("");
const [s2SideCenterImageUrl, setS2SideCenterImageUrl] = useState('');

const [s3SideTopImage, setS3SideTopImage] = useState("");
const [s3SideTopImageUrl, setS3SideTopImageUrl] = useState('');
const [s3SideCenterImage, setS3SideCenterImage] = useState("");
const [s3SideCenterImageUrl, setS3SideCenterImageUrl] = useState('');
const[propsSend, setPropsSend] = useState();

const [buildingId, setBuildingId] = useState()
const [deployedScene, setdeployedScene] = useState();
const [buildingType, setBuildingType] = useState(false);
const [selectedScene, setSelectedScene] = useState();

const [baseId, setBaseId] = useState()
const [topId, setTopId] = useState()
const [openNotificationCopy, setOpenNotificationCopy] = useState(false);
// My Code
const[designType,setDesignType] = useState('Preset');
// ==================End===================

useEffect(()=>{
  console.log(itemDetail.currentOwner);


},[])





useEffect(()=>{
 
var a = window.location.pathname;
var b = a.split("/");
var c = address && address.toString();

dispatch({ type: TYPES.GET_INVENTORY_BY_ID, id:b[b.length - 1] })

dispatch({type: TYPES.GET_ENS,walletAddress:address && address.toLowerCase()})

if(b[2]=="Land"){
  setIsLand(true);

}
  },[]);

  
  const CameraControls = () => {
  
    const {
      camera,
      gl: { domElement }
    } = useThree();
  
    // Ref to the controls, so that we can update them on every frame using useFrame
    const controls = useRef();
    useFrame(() => controls.current.update());
    return (
      <orbitControls
        ref={controls}
        args={[camera, domElement]}
        autoRotate={true}
        enableZoom={false}
      />
    );
  };
  
  function SkyBox() {
    const { scene } = useThree();
    const loader = new CubeTextureLoader();
    const texture = loader.load([
      "/1.jpg",
      "/1.jpg",
      "/1.jpg",
      "/1.jpg",
      "/1.jpg",
      "/1.jpg"
    ]);
  
    scene.background = texture;
    return null;
  }

  function Loader() {

    const { active, progress, errors, item, loaded, total } = useProgress()
  
    return (<Html center >
     
  
   <h3 style={{color:'#fff'}}>Loading... {progress.toFixed(0)} %</h3>
  
    </Html>)
  }


  

    useEffect(()=>{
      if(isConnected == true){
        var a = window.location.pathname;
        var b = a.split("/");

       async function fetchdata(){
        const response = await axios.get(`https://mint.infinityvoid.io/gameApi/masterInventory/${b[b.length - 1]}`);
        console.log(address);

       // ========= My Code ===================      
        unitDetail = response.data;
        console.log("//////////////////////////////////")
        console.log(unitDetail)
         
        setTimeout(function() {
          ThreeDUrl = unitDetail.plotDesign && unitDetail.plotDesign.glbUrl

      }, 100);

      // if Design Type is Custom show Custom Box or Presets Box
     setTimeout(function() {
      if(unitDetail.plotDesign && unitDetail.plotDesign.type == "Custom"){
        setDesignType("Custom")
      }else if(unitDetail.plotDesign && unitDetail.plotDesign.type == "Preset"){
        setDesignType("Preset")
      }else{
        setDesignType("")
      }
 
    }, 100);
    
         // ============= End ============================


         const grounddata = await axios.get(`https://mint.infinityvoid.io/gameApi/plotBuilding?filter[meshId]?q=${unitDetail.plotDesign.baseId}`);
         const upperdata = await axios.get(`https://mint.infinityvoid.io/gameApi/plotBuilding?filter[meshId]?q=${unitDetail.plotDesign.topId}`);
         const buildingdata = await axios.get(`https://mint.infinityvoid.io/gameApi/plotBuilding?filter[meshId]?q=${unitDetail.plotDesign.buildingId}`);
 
 console.log(grounddata.data.rows[0].name);
 
        setBaseId(grounddata.data && grounddata.data.rows[0].name);
      
        setTopId(upperdata.data && upperdata.data.rows[0].name);
    
        setBuildingId(buildingdata.data && buildingdata.data.rows[0].name);
    

        if(response.data.currentOwner !== address.toLowerCase()){
           console.log(response.data.currentOwner);
         navigate("/");
          }
       }
    
      fetchdata();
      }
      else{
        navigate("/");
      }
    },[]);



    
  const handleSelectSceneChange = (event) => {
   console.log(event.target.value);
    setSelectedScene(event.target.value);
  };








  useEffect(()=>{


    

   setUnitDescription(itemDetail.description);
   setWebUrl(itemDetail.webUrl);

    setE1BannerTopImage(itemDetail.e1BannerImageUrl);
    setE1BannerLeftImage(itemDetail.e1AdLeftImageUrl);
    setE1BannerRightImage(itemDetail.e1AdRightImageUrl);

    setE2BannerTopImage(itemDetail.e2BannerImageUrl);
    setE2BannerLeftImage(itemDetail.e2AdLeftImageUrl);
    setE2BannerRightImage(itemDetail.e2AdRightImageUrl);

    setE3BannerTopImage(itemDetail.e3BannerImageUrl);
    setE3BannerLeftImage(itemDetail.e3AdLeftImageUrl);
    setE3BannerRightImage(itemDetail.e3AdRightImageUrl);

    setS3SideTopImage(itemDetail.w3BannerImageUrl);
    setS3SideCenterImage(itemDetail.w3AdImageUrl);

    setS2SideTopImage(itemDetail.w2BannerImageUrl);
    setS2SideCenterImage(itemDetail.w2AdImageUrl);

    setS1SideTopImage(itemDetail.w1BannerImageUrl);
    setS1SideCenterImage(itemDetail.w1AdImageUrl);

    
    let sideBannerArray = [];
    for (let i = 0; i <itemDetail.numberOfWindows; i++) {
     var baseInputNew = { id:i};
     sideBannerArray.push(baseInputNew);
       }
   setSideBannerItems(sideBannerArray);  

   let bannerArray = [];
   for (let i = 0; i <itemDetail.numberOfEntries; i++) {
    var baseInputNewBanner = { id:i};
   bannerArray.push(baseInputNewBanner);
      }
  setBannerItems(bannerArray);   

    if(itemDetail.inventorySetting && itemDetail.inventorySetting.entryType == undefined){
      setEventSetting(false);
      setEventSettingLabel("Public");

    }

  
    if(itemDetail.inventorySetting && itemDetail.inventorySetting.entryType == "Private"){
    setEventSetting(true);
    setEventSettingLabel(itemDetail.inventorySetting && itemDetail.inventorySetting.entryType);


         
    
    }else{
      setEventSetting(false);
      setEventSettingLabel("Public");
    }
    
    
    var startAlloesWalletAddress = itemDetail.inventorySetting && itemDetail.inventorySetting.allowedAddress && itemDetail.inventorySetting && itemDetail.inventorySetting.allowedAddress;
    var startAlloesWalletAddressString = startAlloesWalletAddress.join(",");

     if(startAlloesWalletAddressString.length>3){
      setAllowedWalletAddress(startAlloesWalletAddressString);
       }      
   
       var startAlloesContractAddress = itemDetail.inventorySetting && itemDetail.inventorySetting.allowedContractAddress && itemDetail.inventorySetting && itemDetail.inventorySetting.allowedContractAddress;
       let startAlloesContractAddressString
      
       if(startAlloesContractAddress!== null){
         startAlloesContractAddressString = startAlloesContractAddress.join(",");
      }
   
       if(startAlloesContractAddressString.length>3){
        setAllowedContractAddress(startAlloesContractAddressString);
         }  


    if(itemDetail.linkedENSName && itemDetail.linkedENSName.currentOwner == address.toLowerCase()){
      setlinkedENSName(itemDetail.linkedENSName);
      setEnsNameLink(itemDetail.linkedENSName.name);
      setValueEns(linkedENSName);
      setShowEns(true);
      }



      SharedLink = "https://metaverse.infinityvoid.io/?plotId@"+itemDetail.id;
   

      const callSceneData =async()=>{

       



         


        var response = await axios.get(`https://mint.infinityvoid.io/gameApi/builderscene?filter[fileOwner]=${itemDetail.currentOwner}`);
      
        console.log("^^^^^^^^^^^^^^^^")
        console.log(response)

        var a1 =[]
        a1= response.data.rows.filter(item => item.projectType == "builderTool"    )
      
       
         var a2= []
         a2= response.data.rows.filter(item => item.projectType === "SDK" && item.status =="Approved")
     
         a1 = a1.concat(a2);

         for (let i = 0; i < a1.length; i++) {
          const originalName = a1[i].name;
     

         
          if(originalName.slice(0,2) == "16"){
             a1[i].name =originalName.slice(13);
      
         }
        }

         //Test Data
         console.log("********")
         console.log(a1)
  



         if(itemDetail.deployedScene){
          
          var activeScene = a1.filter(e=> e.id == itemDetail.deployedScene.id);
 activeSceneNew = activeScene[0];
          

           setSelectedScene(activeScene[0]);
        }

         if(a1.length > 0){
          setShowScenes(true);
         }
       
    setdeployedScene(a1);
         
        }
        callSceneData();
  
      setEnsNfts(ensdata);
  },[address, ensdata, itemDetail.inventorySetting && itemDetail.inventorySetting.allowedAddress,itemDetail.deployedScene, itemDetail.inventorySetting && itemDetail.inventorySetting.entryType, itemDetail.linkedENSName, itemDetail.numberOfEntries, itemDetail.numberOfWindows, linkedENSName])

  


  const copyGameLink = (SharedLink)=>{
    navigator.clipboard.writeText(SharedLink)
    setOpenNotificationCopy(true);
    setNotificationMessage("Link successfully copied to clipboard");
  }


  useEffect(()=>{
    var dataCategory = itemDetail.inventoryCategory;
    let viewUrl
    let downloadPlanUrl
    if (dataCategory == "A"){
       viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderA.jpg";
       downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryAStandard.zip"
       setViewLayoutUrl(viewUrl);
       setDownloadPlanLayoutUrl(downloadPlanUrl);
    }
    if (dataCategory == "B"){
       viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderB.jpg";
       downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryBDrawingStandard.zip"
       setViewLayoutUrl(viewUrl);
       setDownloadPlanLayoutUrl(downloadPlanUrl);
    }
    if (dataCategory == "C"){
      viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderC.jpg";
      downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryCDrawingStandard.zip"
      setViewLayoutUrl(viewUrl);
      setDownloadPlanLayoutUrl(downloadPlanUrl);
    }
    if (dataCategory == "D"){
      viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderD.jpg";
      downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryDDrawingStandard.zip"
      setViewLayoutUrl(viewUrl);
      setDownloadPlanLayoutUrl(downloadPlanUrl);
    }
    if (dataCategory == "E"){
      viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderE.jpg";
      downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryEDrawingStandard.zip"
      setViewLayoutUrl(viewUrl);
      setDownloadPlanLayoutUrl(downloadPlanUrl);
    }
    if (dataCategory == "F"){
      viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderF.jpg";
      downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryFDrawingStandard.zip"
      setViewLayoutUrl(viewUrl);
      setDownloadPlanLayoutUrl(downloadPlanUrl);
    }
    if (dataCategory == "G"){
      viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderG.jpg";
      downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryGDrawingStandard.zip"
      setViewLayoutUrl(viewUrl);
      setDownloadPlanLayoutUrl(downloadPlanUrl);
    }
    if (dataCategory == "H"){
      viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderH.jpg";
      downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryHDrawingStandard.zip"
      setViewLayoutUrl(viewUrl);
      setDownloadPlanLayoutUrl(downloadPlanUrl);
    }

    if (dataCategory == "I"){
      viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderI.jpg";
      downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryIDrawingStandard.zip"
      setViewLayoutUrl(viewUrl);
      setDownloadPlanLayoutUrl(downloadPlanUrl);
    }

    if (dataCategory == "J"){
      viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderJ.jpg";
      downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryJDrawingStandard.zip"
      setViewLayoutUrl(viewUrl);
      setDownloadPlanLayoutUrl(downloadPlanUrl);
    }

    if (dataCategory == "K"){
      viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderK.jpg";
      downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryKDrawingStandard.zip"
      setViewLayoutUrl(viewUrl);
      setDownloadPlanLayoutUrl(downloadPlanUrl);
    }

    if (dataCategory == "L"){
      viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderL.jpg";
      downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryLDrawingStandard.zip"
      setViewLayoutUrl(viewUrl);
      setDownloadPlanLayoutUrl(downloadPlanUrl);
    }

    if (dataCategory == "M"){
      viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderM.jpg";
      downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryMDrawingStandard.zip"
      setViewLayoutUrl(viewUrl);
      setDownloadPlanLayoutUrl(downloadPlanUrl);
    }

    if (dataCategory == "N"){
      viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderN.jpg";
      downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryNDrawingStandard.zip"
      setViewLayoutUrl(viewUrl);
      setDownloadPlanLayoutUrl(downloadPlanUrl);
    }

    if (dataCategory == "O"){
      viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderO.jpg";
      downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopCOStandard.zip"
      setViewLayoutUrl(viewUrl);
      setDownloadPlanLayoutUrl(downloadPlanUrl);
    }

    if (dataCategory == "P"){
      viewUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/shopRenderPArtboard16.jpg";
      downloadPlanUrl = "https://assorted.fra1.cdn.digitaloceanspaces.com/category-plan/categoryPStandard.zip"
      setViewLayoutUrl(viewUrl);
      setDownloadPlanLayoutUrl(downloadPlanUrl);
    }
  })

useEffect( () => {

    if(bannerItems.length >0){
         AddBannerCard();
    }
    
}, [ bannerItems.length])




useEffect( () => {
  
  if(sideBannerItems.length >0){
       AddSideBannerCard();
  }
}, [sideBannerItems.length])



useEffect( () => {
  AddBannerCard();
}, [e1BannerTopImage])

useEffect( () => {
  AddBannerCard();
}, [e1BannerLeftImage])

useEffect( () => {
  AddBannerCard();
}, [e1BannerRightImage])
useEffect( () => {
  AddBannerCard();
}, [e2BannerTopImage])

useEffect( () => {
  AddBannerCard();
}, [e2BannerLeftImage])

useEffect( () => {
  AddBannerCard();
}, [e2BannerRightImage])
useEffect( () => {
  AddBannerCard();
}, [e3BannerTopImage])

useEffect( () => {
  AddBannerCard();
}, [e3BannerLeftImageUrl])

useEffect( () => {
  AddBannerCard();
}, [e3BannerRightImage])

useEffect( () => {
  AddSideBannerCard();
}, [s1SideTopImage])

useEffect( () => {
  AddSideBannerCard();
}, [s1SideCenterImage])

useEffect( () => {
  AddSideBannerCard();
}, [s2SideTopImageUrl])

useEffect( () => {
  AddSideBannerCard();
}, [s2SideCenterImage])

useEffect( () => {
  AddSideBannerCard();
}, [s3SideTopImage])

useEffect( () => {
  AddSideBannerCard();
}, [s3SideCenterImage])


const handleEventSetting = async() => {
  console.log(eventSettingLabel);
  setEventSetting(!eventSetting);
 if(eventSettingLabel == "Public"){
     setEventSettingLabel("Private");
 }
 if(eventSettingLabel == "Private"){
     setEventSettingLabel("Public");
 }


   
}

const toggleModal = () => setShow(!show);
const toggleModalSide = () => setShowSide(!showSide);

const openPopup=async(x,y)=>{
 

  let fileWidth = "0";
  let fileHeight = "0";
  if ( x == "topBox" && y == 0){
         fileWidth = itemDetail.e1BannerW;
         fileHeight = itemDetail.e1BannerH;
         
  }
  if ( x == "topBox" && y == 1){
      
      fileWidth = itemDetail.e2BannerW;
      fileHeight = itemDetail.e2BannerH;
      
  }
  if ( x == "topBox" && y == 2){
      
      fileWidth = itemDetail.e3BannerW;
      fileHeight = itemDetail.e3BannerH;
      
  }
  if ( x == "leftBox" && y == 0){
   
      fileWidth = itemDetail.e1AdW;
      fileHeight = itemDetail.e1AdH;
      
  }
  if ( x == "leftBox" && y == 1){
      
      fileWidth = itemDetail.e2AdW;
      fileHeight = itemDetail.e2AdH;
      
  }
  if ( x == "leftBox" && y == 2){
      
      fileWidth = itemDetail.e3AdW;
      fileHeight = itemDetail.e3AdH;
      
  }
  if ( x == "rightBox" && y == 0){

      fileWidth = itemDetail.e1AdW;
      fileHeight = itemDetail.e1AdH;
      
  }
  if ( x == "rightBox" && y == 1){
      
      fileWidth = itemDetail.e2AdW;
      fileHeight = itemDetail.e2AdH;
      
  }
  if ( x == "rightBox" && y == 2){
      
      fileWidth = itemDetail.e1AdW;
      fileHeight = itemDetail.e1AdH;
      
  }

  

setShow(true);

modalView(x,y, fileWidth, fileHeight);

}


const openPopupSide=async(x,y)=>{

  
  let fileWidth = "0";
  let fileHeight = "0";
  if ( x == "sideTopBox" && y == 0){
         fileWidth = itemDetail.w1BannerW;
         fileHeight = itemDetail.w1BannerH;
         console.log(fileHeight);
  }
  if ( x == "sideTopBox" && y == 1){
      
      fileWidth = itemDetail.w2BannerW;
      fileHeight = itemDetail.w2BannerH;
      
  }
  if ( x == "sideTopBox" && y == 2){
      
      fileWidth = itemDetail.w3BannerW;
      fileHeight = itemDetail.w3BannerH;
      
  }
  if ( x == "sideCenterBox" && y == 0){
   
      fileWidth = itemDetail.w1AdW;
      fileHeight = itemDetail.w1AdH;
      console.log(fileHeight);
  }
  if ( x == "sideCenterBox" && y == 1){
      
      fileWidth = itemDetail.w2AdW;
      fileHeight = itemDetail.w2AdH;
      
  }
  if ( x == "sideCenterBox" && y == 2){
      
      fileWidth = itemDetail.w3AdW;
      fileHeight = itemDetail.w3AdH;
      
  }
  

 setShowSide(true);

 modalViewSide(x,y,fileWidth, fileHeight);
 
 }


 const modalView = (x,y, fileWidth, fileHeight)=>{

  const NewModal = (
     
  <>
      <MKBox>
       <MKTypography size="small" variant="p">Dimensions</MKTypography>
      <MKTypography mt={1} size="small" variant="subtitle2">Height- {fileHeight} Pixels</MKTypography>
      <MKTypography size="small" variant="subtitle2">Width- {fileWidth} Pixels</MKTypography>
      <MKTypography  size="small" variant="subtitle2">Max File Size- 500kb</MKTypography>
      </MKBox>
      
      <MKBox pt={1} >
      <Button p="10" color="primary" size ="small" variant="outlined"  component="label" endIcon={<AddCircleOutlineIcon />} sx={{
    color:'white',
   '&:hover': {
    backgroundColor: '#26F8FF',
    color:'#000'
  }
}}>
          Upload
      <input hidden accept="image/*" multiple type="file"  onChange={event => handleBannerImage(event,x,y)}  />
     
     </Button>
     
  </MKBox>
  
     
   </>
    
  );
  setModalCard(NewModal);
   }
  
   const modalViewSide = (x,y, fileWidth, fileHeight)=>{
    
  
     const NewModal = (
  <>
      <MKBox>
            <MKTypography variant="p">Dimensions</MKTypography>
      <MKTypography mt={1} size="small" variant="subtitle2">Height- {fileHeight} Pixels</MKTypography>
      <MKTypography  size="small" variant="subtitle2">Width- {fileWidth} Pixels</MKTypography>
      <MKTypography  size="small" variant="subtitle2">Max File Size- 500kb</MKTypography>
     </MKBox>
  
      <MKBox pt={1} >
      <Button p="10" color="primary" size ="small" variant="outlined"  component="label" endIcon={<AddCircleOutlineIcon />} sx={{
    color:'white',
   '&:hover': {
    backgroundColor: '#26F8FF',
    color:'#000'
  }
}}>
          Upload
      <input hidden accept="image/*" multiple type="file"  onChange={event => handleSideBannerImage(event,x,y)}  />
     
     </Button>
         </MKBox>
         </>
        
     );
     setSideModalCard(NewModal);
       }


   

     const handleENSChange = async (event) => {
      

      var ensNftSelected = ensNfts.filter(e=> e.name == event.target.value.name);
      var data = ensNftSelected[0];

      if(data.linkedENSDone == true){
        setValueEns();
        setOpenNotification(true)
        setNotificationMessage("Selected ENS is already linked");
         setNotificationMessageType("warning");

        
      }else{
        setValueEns(event.target.value);
      }
  }


  const handleE1bannerImageChange = (file, itemPos) => {

    const uploadRequest = new AWS.S3.ManagedUpload({params: 
      { Bucket:'dashboard', Key:file.name, Body:file, ACL: 'public-read' },service:s3FileUpload});




    if(itemPos == "topBox"){
      setE1BannerTopImage(URL.createObjectURL(file));

   
        uploadRequest.send(function(err,response) {
    
          if (err) {
          
          console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
          
          } else {
          
            setE1BannerTopImageUrl(response.Location)
            setShow(false)
              var updatedData ={data:{e1BannerImageUrl:response.Location}}
              console.log(updatedData);
             dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData });
             setNotificationMessageType("success");
             setOpenNotification(true);
             setNotificationMessage("Image uploaded successfully");
          }
          
          });
    }

    if(itemPos == "leftBox"){
      setE1BannerLeftImage(URL.createObjectURL(file));

      uploadRequest.send(function(err,response) {
    
        if (err) {
        
        console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
        
        } else {
        
          setE1BannerLeftImageUrl(response.Location)
          setShow(false)
           var updatedData ={data:{e1AdLeftImageUrl:response.Location}}
           dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData });
           setNotificationMessageType("success");
           setOpenNotification(true);
           setNotificationMessage("Image uploaded successfully");
        }
        
        });

  

    }
    if(itemPos == "rightBox"){
      setE1BannerRightImage(URL.createObjectURL(file));

      uploadRequest.send(function(err,response) {
    
        if (err) {
        
        console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
        
        } else {
        
          setE1BannerRightImageUrl(response.Location)
                setShow(false)
                var updatedData ={data:{e1AdRightImageUrl:response.Location}}
                dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData });
                setNotificationMessageType("success");
                setOpenNotification(true);
                setNotificationMessage("Image uploaded successfully");
        }
        
        });


        
    }

}

const handleE2bannerImageChange = (file, itemPos) => {

  const uploadRequest = new AWS.S3.ManagedUpload({params: 
    { Bucket:'dashboard', Key:file.name, Body:file, ACL: 'public-read' },service:s3FileUpload});


  if(itemPos == "topBox"){
    setE2BannerTopImage(URL.createObjectURL(file));

    
    uploadRequest.send(function(err,response) {
    
      if (err) {
      
      console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
      
      } else {
      
        setE2BannerTopImageUrl(response.Location)
            setShow(false)
              var updatedData ={data:{e2BannerImageUrl:response.Location}}
             dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData });
             setNotificationMessageType("success");
             setOpenNotification(true);
             setNotificationMessage("Image uploaded successfully");
      }
      
      });

  }

  if(itemPos == "leftBox"){
    setE2BannerLeftImage(URL.createObjectURL(file));

    uploadRequest.send(function(err,response) {
    
      if (err) {
      
      console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
      
      } else {
      
        setE2BannerLeftImageUrl(response.Location)
              setShow(false)
              var updatedData ={data:{e2AdLeftImageUrl:response.Location}}
              dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData });
              setNotificationMessageType("success");
              setOpenNotification(true);
              setNotificationMessage("Image uploaded successfully");
      }
      
      });

      

  }
  if(itemPos == "rightBox"){
    setE2BannerRightImage(URL.createObjectURL(file));
    uploadRequest.send(function(err,response) {
    
      if (err) {
      
      console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
      
      } else {
      
        setE2BannerRightImageUrl(response.Location)
        setShow(false)
        var updatedData ={data:{e2AdRightImageUrl:response.Location}}
        dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData });
        setNotificationMessageType("success");
        setOpenNotification(true);
        setNotificationMessage("Image uploaded successfully");
      }
      
      });
       
      
  }

}

const handleE3bannerImageChange = (file, itemPos) => {

  const uploadRequest = new AWS.S3.ManagedUpload({params: 
    { Bucket:'dashboard', Key:file.name, Body:file, ACL: 'public-read' },service:s3FileUpload});



  if(itemPos == "topBox"){
    setE3BannerTopImage(URL.createObjectURL(file));


    uploadRequest.send(function(err,response) {
    
      if (err) {
      
      console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
      
      } else {
      
        setE3BannerTopImageUrl(response.Location)
        setShow(false)
         var updatedData ={data:{e3BannerImageUrl:response.Location}}
        dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData });
        setNotificationMessageType("success");
        setOpenNotification(true);
        setNotificationMessage("Image uploaded successfully");
      
      }
      
      });
      
  }

  if(itemPos == "leftBox"){
    setE3BannerLeftImage(URL.createObjectURL(file));

    
    uploadRequest.send(function(err,response) {
    
      if (err) {
      
      console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
      
      } else {
      
        setE3BannerLeftImageUrl(response.Location)
            setShow(false)
              var updatedData ={data:{e3AdLeftImageUrl:response.Location}}
              dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData });
              setNotificationMessageType("success");
              setOpenNotification(true);
              setNotificationMessage("Image uploaded successfully");
      }
      
      });
      

  }
  if(itemPos == "rightBox"){
    setE3BannerRightImage(URL.createObjectURL(file));

    uploadRequest.send(function(err,response) {
    
      if (err) {
      
      console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
      
      } else {
      
        setE3BannerRightImageUrl(response.Location)
        setShow(false)
         var updatedData ={data:{e3AdRightImageUrl:response.Location}}
         dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData });
         setNotificationMessageType("success");
         setOpenNotification(true);
         setNotificationMessage("Image uploaded successfully");
      }
      
      });

}
}


const handleBannerImage = (event,itemPos,itemId) => {
  const currDate = Date.now();
  let newName = currDate+event.target.files[0].name.replace(/\s/g, "");
 
  let newFile = new File([event.target.files[0]], newName);

  var fileSize = event.target.files[0].size;
 if ( fileSize > 500000){

  setNotificationMessage("File size must be less than 500KB");
  setNotificationMessageType("error");
  setOpenNotification(true)

 }else{
   if (itemId == 0) {
    
     if (itemPos == "topBox") {
     
       if (itemDetail.e1BannerImageUrl) {
         var k = itemDetail.e1BannerImageUrl
         let urlParts = k.split("/");
         let lastPart = urlParts.pop();
         newName = lastPart;
         console.log(newName)
         newFile = new File([event.target.files[0]], newName);
       }
       handleE1bannerImageChange(newFile, itemPos)
     }

     else if (itemPos == "leftBox") {
      
      if (itemDetail.e1AdLeftImageUrl) {
        var k = itemDetail.e1AdLeftImageUrl
        let urlParts = k.split("/");
        let lastPart = urlParts.pop();
        newName = lastPart;
        console.log(newName)
        newFile = new File([event.target.files[0]], newName);
      }
      handleE1bannerImageChange(newFile, itemPos)
       
     }
     
     
     else if (itemPos == "rightBox") {

      if (itemDetail.e1AdRightImageUrl) {
        var k = itemDetail.e1AdRightImageUrl
        let urlParts = k.split("/");
        let lastPart = urlParts.pop();
        newName = lastPart;
        console.log(newName)
        newFile = new File([event.target.files[0]], newName);
      }
      handleE1bannerImageChange(newFile, itemPos)

     }



     
    }
  
   
   
    if(itemId == 1){
    
      if (itemPos == "topBox") {
     
        if (itemDetail.e2BannerImageUrl) {
          var k = itemDetail.e2BannerImageUrl
          let urlParts = k.split("/");
          let lastPart = urlParts.pop();
          newName = lastPart;
          console.log(newName)
          newFile = new File([event.target.files[0]], newName);
        }
        handleE2bannerImageChange(newFile, itemPos)
      }
 
      else if (itemPos == "leftBox") {
       
       if (itemDetail.e2AdLeftImageUrl) {
         var k = itemDetail.e2AdLeftImageUrl
         let urlParts = k.split("/");
         let lastPart = urlParts.pop();
         newName = lastPart;
         console.log(newName)
         newFile = new File([event.target.files[0]], newName);
       }
       handleE2bannerImageChange(newFile, itemPos)
        
      }
      
      
      else if (itemPos == "rightBox") {
 
       if (itemDetail.e2AdRightImageUrl) {
         var k = itemDetail.e2AdRightImageUrl
         let urlParts = k.split("/");
         let lastPart = urlParts.pop();
         newName = lastPart;
         console.log(newName)
         newFile = new File([event.target.files[0]], newName);
       }
      
       handleE2bannerImageChange(newFile, itemPos)
 
      }


    }
  
   
   
   
    if(itemId == 2){
     
      if (itemPos == "topBox") {
     
        if (itemDetail.e3BannerImageUrl) {
          var k = itemDetail.e3BannerImageUrl
          let urlParts = k.split("/");
          let lastPart = urlParts.pop();
          newName = lastPart;
          console.log(newName)
          newFile = new File([event.target.files[0]], newName);
        }
        handleE3bannerImageChange(newFile, itemPos)
      }
 
      else if (itemPos == "leftBox") {
       
       if (itemDetail.e3AdLeftImageUrl) {
         var k = itemDetail.e3AdLeftImageUrl
         let urlParts = k.split("/");
         let lastPart = urlParts.pop();
         newName = lastPart;
         console.log(newName)
         newFile = new File([event.target.files[0]], newName);
       }
       handleE3bannerImageChange(newFile, itemPos)
        
      }
      
      
      else if (itemPos == "rightBox") {
 
       if (itemDetail.e3AdRightImageUrl) {
         var k = itemDetail.e3AdRightImageUrl
         let urlParts = k.split("/");
         let lastPart = urlParts.pop();
         newName = lastPart;
         console.log(newName)
         newFile = new File([event.target.files[0]], newName);
       }
      
   
       handleE3bannerImageChange(newFile, itemPos)
 
      }





    }
 }


     
  }








  const handleSideBannerImage = (event,itemPos,itemId) => {

    const currDate = Date.now();
    var newName = currDate+event.target.files[0].name.replace(/\s/g, "");

    let newFile = new File([event.target.files[0]], newName);
   

    
    var fileSize = event.target.files[0].size;
    if ( fileSize > 500000){
      setNotificationMessage("File size must be less than 500KB");
      setNotificationMessageType("error");
      setOpenNotification(true)
    }else{

    if(itemId == 0){
  

      if(itemPos == "sideTopBox"){
     
       if (itemDetail.w1BannerImageUrl) {
         var k = itemDetail.w1BannerImageUrl
        
         let urlParts = k.split("/");
         let lastPart = urlParts.pop();
         newName = lastPart;
         console.log(newName)
         newFile = new File([event.target.files[0]], newName);
       }
       handleS1SidebannerImageChange(newFile, itemPos)
     }

     else if(itemPos == "sideCenterBox"){
      
      if (itemDetail.w1AdImageUrl) {
        var k = itemDetail.w1AdImageUrl
        let urlParts = k.split("/");
        let lastPart = urlParts.pop();
        newName = lastPart;
        console.log(newName)
        newFile = new File([event.target.files[0]], newName);
      }
      handleS1SidebannerImageChange(newFile, itemPos)
       
     }
     
     

     
    }
  
    if(itemId == 1){
    
      if(itemPos == "sideTopBox"){
     
        if (itemDetail.w2BannerImageUrl) {
          var k = itemDetail.w2BannerImageUrl
          let urlParts = k.split("/");
          let lastPart = urlParts.pop();
          newName = lastPart;
          console.log(newName)
          newFile = new File([event.target.files[0]], newName);
        }
        handleS2SidebannerImageChange(newFile, itemPos)
      }
 
      else if(itemPos == "sideCenterBox"){
       
       if (itemDetail.w2AdImageUrl) {
         var k = itemDetail.w2AdImageUrl
         let urlParts = k.split("/");
         let lastPart = urlParts.pop();
         newName = lastPart;
         console.log(newName)
         newFile = new File([event.target.files[0]], newName);
       }
    
       handleS2SidebannerImageChange(newFile, itemPos)
        
      }


    }
  
    if(itemId == 2){
     
      if(itemPos == "sideTopBox"){
     
        if (itemDetail.w3BannerImageUrl) {
          var k = itemDetail.w3BannerImageUrl
          let urlParts = k.split("/");
          let lastPart = urlParts.pop();
          newName = lastPart;
          console.log(newName)
          newFile = new File([event.target.files[0]], newName);
        }
        handleS3SidebannerImageChange(newFile, itemPos)
      }
 
      else if(itemPos == "sideCenterBox"){
       
       if (itemDetail.w3AdImageUrl) {
         var k = itemDetail.w3AdImageUrl
         let urlParts = k.split("/");
         let lastPart = urlParts.pop();
         newName = lastPart;
         console.log(newName)
         newFile = new File([event.target.files[0]], newName);
       }
       handleS3SidebannerImageChange(newFile, itemPos)
        
      }
      

     
    }}
         
      }


         
      const handleS1SidebannerImageChange = (file, itemPos) => {

        const uploadRequest = new AWS.S3.ManagedUpload({params: 
          { Bucket:'dashboard', Key:file.name, Body:file, ACL: 'public-read' },service:s3FileUpload});
      

          
        if(itemPos == "sideTopBox"){
          setS1SideTopImage(URL.createObjectURL(file));

          uploadRequest.send(function(err,response) {
    
            if (err) {
            
            console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
            
            } else {
            
              setS1SideTopImageUrl(response.Location)
              setShow(false)
              
                var updatedData ={data:{w1BannerImageUrl:response.Location}}
          dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData });
          setNotificationMessageType("success");
          setOpenNotification(true);
          setNotificationMessage("Image uploaded successfully");
            }
            
            });

         
        }
    
        if(itemPos == "sideCenterBox"){
          setS1SideCenterImage(URL.createObjectURL(file));

          uploadRequest.send(function(err,response) {
    
            if (err) {
            
            console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
            
            } else {
              setS1SideCenterImageUrl(response.Location)
              setShow(false)
               var updatedData ={data:{w1AdImageUrl:response.Location}}
         dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData })
         setNotificationMessageType("success");
         setOpenNotification(true);
         setNotificationMessage("Image uploaded successfully");
            }
            
            });

    
        }
    
    
    }  


    const handleS2SidebannerImageChange = (file, itemPos) => {

      const uploadRequest = new AWS.S3.ManagedUpload({params: 
        { Bucket:'dashboard', Key:file.name, Body:file, ACL: 'public-read' },service:s3FileUpload});
    



      if(itemPos == "sideTopBox"){
        setS2SideTopImage(URL.createObjectURL(file));

        uploadRequest.send(function(err,response) {
    
          if (err) {
          
          console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
          
          } else {
            setS2SideTopImageUrl(response.Location)
               setShow(false)
                  var updatedData ={data:{w2BannerImageUrl:response.Location}}
            dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData });
            setNotificationMessageType("success");
            setOpenNotification(true);
            setNotificationMessage("Image uploaded successfully");
          }
          
          });


      }
  
      if(itemPos == "sideCenterBox"){
        setS2SideCenterImage(URL.createObjectURL(file));
        uploadRequest.send(function(err,response) {
    
          if (err) {
          
          console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
          
          } else {
            setS2SideCenterImageUrl(response.Location)
            setShow(false)
              var updatedData ={data:{w2AdImageUrl:response.Location}}
        dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData })
        setNotificationMessageType("success");
        setOpenNotification(true);
        setNotificationMessage("Image uploaded successfully");
          }
          
          });

    
  
      }
  
  
  }  

  const handleS3SidebannerImageChange = (file, itemPos) => {

    const uploadRequest = new AWS.S3.ManagedUpload({params: 
      { Bucket:'dashboard', Key:file.name, Body:file, ACL: 'public-read' },service:s3FileUpload});
  



    if(itemPos == "sideTopBox"){
      setS3SideTopImage(URL.createObjectURL(file));

      uploadRequest.send(function(err,response) {
    
        if (err) {
        
        console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
        
        } else {
          setS3SideTopImageUrl(response.Location)
               setShow(false)
                var updatedData ={data:{w3BannerImageUrl:response.Location}}
          dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData });
          setNotificationMessageType("success");
          setOpenNotification(true);
          setNotificationMessage("Image uploaded successfully");
        }
        
        });

    }

    if(itemPos == "sideCenterBox"){
      setS3SideCenterImage(URL.createObjectURL(file));

      uploadRequest.send(function(err,response) {
    
        if (err) {
        
        console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
        
        } else {
          setS3SideCenterImageUrl(response.Location)
          setShow(false)
           var updatedData ={data:{w3AdImageUrl:response.Location}}
     dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:updatedData })
     setNotificationMessageType("success");
     setOpenNotification(true);
     setNotificationMessage("Image uploaded successfully");
        }
        
        });

    

    }


}  




const handleContractInput = (event)=>{
  var allowedAddress = event.target.value.split(",");
  if(allowedAddress.length>5){
    setNotificationMessage("Max 5 contract addresss allowed");
    setNotificationMessageType("warning");
    setOpenNotification(true)
  }else{
    setAllowedContractAddress(event.target.value);
  }

}

const handleAllowedWallet =(event)=>{
  var allowedAddress = event.target.value.split(",");
  if(allowedAddress.length>10){
    setNotificationMessage("Max 10 wallet addresss allowed");
    setNotificationMessageType("warning");
    setOpenNotification(true)
  }else{
    setAllowedWalletAddress(event.target.value);
  }
}


const handleAddDescription =(event)=>{
setDisCount(event.target.value.length);
  if(event.target.value.length>99){
    setNotificationMessage("Max 100 characters allowed");
    setNotificationMessageType("warning");
    setOpenNotification(true)
  }else{
    setUnitDescription(event.target.value);
  }

    
  
}

const handleAddWebUrl =(event)=>{

  setWebUrl(event.target.value);

}




const onUpdateInventoryDetails = ()=>{
  if(allowedWalletAddress !== undefined ){
    var allowedAddressWallet = allowedWalletAddress && allowedWalletAddress.split(",");
 
  }

  
  if(allowedContractAddress.length>0){
    var allowedAddressContract = allowedContractAddress && allowedContractAddress.split(",");

  }


console.log(selectedScene);

  let updatedData={};

  if(selectedScene !== undefined){

    dispatch({ type: TYPES.UPDATE_BUILDER_SCENES, id:selectedScene.id, data:{unitId:itemDetail.id} })


var selScene = {
  name:selectedScene.name,
  currentOwner:selectedScene.fileOwner,
  projectId:selectedScene.projectId,
  id:selectedScene.id,
  networkIp:selectedScene.networkIp
}

console.log(selScene);

    Object.assign(updatedData, {
      deployedScene:selScene});
  }

  if(eventSetting == true){

      const settingData = {
          allowedAddress: allowedAddressWallet,
            entryType:"Private",
            externalWebUrl:"",
            mapImageUrl:"",
            allowedContractAddress: allowedAddressContract,
         }
         Object.assign(updatedData, {
          inventorySetting:settingData,
          description:unitDescription,
          webUrl:webUrl});

    }

    if(eventSetting == false){

        const settingData = {
            allowedAddress: allowedWalletAddress,
              entryType:"Public",
              externalWebUrl:"",
              mapImageUrl:"",
              allowedContractAddress: allowedContractAddress
           }
           Object.assign(updatedData, {
            inventorySetting:settingData,
            description:unitDescription,
            webUrl:webUrl});

      }

      if(ensNfts.length > 0 && valueEns !== undefined){
          Object.assign(updatedData, {
          linkedENSName:valueEns});
      }

console.log(itemDetail.id);


  dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:{data:updatedData} });
  if(valueEns !== undefined){
    dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:valueEns.id, data:{data:{linkedENSDone:true}} })
  }


  setNotificationMessage("Inventory Details Updated Successfully");
  setNotificationMessageType("success");
  setOpenNotification(true)
setTimeout(() => window.location.reload(false), 6000);

}

const unLinkEns = async ()=>{
  console.log(linkedENSName);
  dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:linkedENSName._id, data:{data:{linkedENSDone:false}} })
  dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:itemDetail.id, data:{data:{linkedENSName:{}}} });
  setNotificationMessage("ENS Unlinked Successfully !");
  setNotificationMessageType("success");
  setOpenNotification(true)
  setTimeout(() => window.location.reload(false), 1000);


}

const AddBannerCard = () => {
      
  if(bannerItems.length != 0){

const eBanner = (

 
  <TabsUnstyled defaultValue={0} >
       <MKTypography variant="h5" pl={{xs:'20px',md:'100px',lg:'135px'}} pt={12}>Personalise Store Front(s)</MKTypography>
  <TabsList style={{boxShadow:'none'}}>
  {bannerItems.map(item => {

    let tabLabel;
    let tabValue;
  if(item.id == 0){
    
      tabLabel = "Store Front 1";
      tabValue = 0;
  }
  if(item.id == 1){

   
      tabLabel = "Store Front 2";
      tabValue = 1;
    }
    if(item.id == 2){
    
      tabLabel = "Store Front 3";
      tabValue = 2;
    }

               return (
                  <Tab key ={tabValue} value={tabValue}>{tabLabel}</Tab>
               )})}


  </TabsList>

  <TabPanel key ={0} value={0} >
  <Grid >
                 <MKBox height="100%" >
            
                 <svg viewBox="-796.919 -54.88 2231.75 855.491"  xmlns="http://www.w3.org/2000/svg" >
                 <image width="2112" height="1188" x="-762.036" y="-272.297" href={ShopFImage}/>
<rect x="-466.37" y="-10.298" width="1531.142" height="84.57" fill="transparent" id="rectViewTop">
<title>TopBox</title>
</rect>
<image x="-489.37" y="-26.298" width="1591.542" height="125.957" href={e1BannerTopImage}  preserveAspectRatio="none" clip-path="url(#rectViewTop)"></image>

<rect x="-429.817" y="121.201" width="483.359" height="474.05" fill="transparent" id="rectViewLeft">
<title>LeftBox</title>
</rect>
<image x="-435.817" y="121.201" width="522.750" height="494.5" href={e1BannerLeftImage} preserveAspectRatio="none" clip-path="url(#rectViewLeft)"></image>

<rect x="552.166" y="129.284" width="484.087" height="474.934" fill="transparent" id="rectViewRight">
<title>RightBox</title>
</rect>
<image x="527.166" y="121.284" width="527.087" height="495.934"  href={e1BannerRightImage} preserveAspectRatio="none" clip-path="url(#rectViewRight)"></image>


<text style={{fill: "rgb(51, 51, 51)", fontSize: "56.5063px",whiteSpace:"pre"}} transform="matrix(0.497249, 0, 0, 0.424731, 159.678866, 3.651333)" x="-169.05" y="47.553"><title>TopBoxText</title></text>
<g transform="matrix(1, 0, 0, 1, 256.190067, 224.303287)">
<title></title>
<circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)",  cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="370.2" cy="99" r="16"/>
<text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}}
x="43.61" y="-171.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopup("topBox",0)}>+</text>
</g>



<text style={{fill: "rgb(51, 51, 51)", fontSize: "24px", whiteSpace:"pre"}} x="-356.967" y="356.909"><title>LeftBoxText</title></text>
<g transform="matrix(1, 0, 0, 1, -261.699833, 567.474862)">
<title></title>
<circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)",  cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="410.2" cy="79" r="16"/>
<text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}} 
x="84.61" y="-191.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopup("leftBox",0)} >+</text>
</g>



<text style={{fill: "rgb(51, 51, 51)", fontSize: "24px", whiteSpace:"pre"}}  x="685.756" y="356.863"><title>RightBoxText</title></text>
<g transform="matrix(1, 0, 0, 1, 785.582775, 567.467477)">
<title></title>
<circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)",  cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="370.2" cy="99" r="16"/>
<text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}}
x="43.61" y="-171.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopup("rightBox",0)} >+</text>
</g>


</svg>

</MKBox>
              </Grid>

  </TabPanel>
  <TabPanel key ={1} value={1}>
  <Grid >
                 <MKBox height="100%">
                 <svg viewBox="-796.919 -54.88 2231.75 855.491" xmlns="http://www.w3.org/2000/svg" >
<image width="2112" height="1188" x="-762.036" y="-272.297" href={ShopFImage}/>
<rect x="-466.37" y="-10.298" width="1531.142" height="84.57" fill="transparent" >
<title>TopBox</title>
</rect>
<image x="-489.37" y="-26.298" width="1591.542" height="125.957" href={e2BannerTopImage}  preserveAspectRatio="none" clip-path="url(#rectViewTop)"></image>

<rect x="-429.817" y="121.201" width="483.359" height="474.05" fill="transparent" id="rectViewLeft">
<title>LeftBox</title>
</rect>
<image  x="-435.817" y="121.201" width="522.750" height="494.5" href={e2BannerLeftImage} preserveAspectRatio="none" clip-path="url(#rectViewLeft)"></image>

<rect x="552.166" y="129.284" width="484.087" height="474.934" fill="transparent" id="rectViewRight">
<title>RightBox</title>
</rect>
<image x="527.166" y="121.284" width="527.087" height="495.934"  href={e2BannerRightImage} preserveAspectRatio="none" clip-path="url(#rectViewRight)"></image>


<text style={{fill: "rgb(51, 51, 51)", fontSize: "56.5063px",whiteSpace:"pre"}} transform="matrix(0.497249, 0, 0, 0.424731, 159.678866, 3.651333)" x="-169.05" y="47.553"><title>TopBoxText</title></text>
<g transform="matrix(1, 0, 0, 1, 256.190067, 224.303287)">
<title></title>
<circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)", cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="370.2" cy="99" r="16"/>
<text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}}
x="43.61" y="-171.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopup("topBox",1)}>+</text>
</g>



<text style={{fill: "rgb(51, 51, 51)", fontSize: "24px", whiteSpace:"pre"}} x="-356.967" y="356.909"><title>LeftBoxText</title></text>
<g transform="matrix(1, 0, 0, 1, -261.699833, 567.474862)">
<title></title>
<circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)",  cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="370.2" cy="99" r="16"/>

<text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}} 
x="43.61" y="-171.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopup("leftBox",1)} >+</text>
</g>



<text style={{fill: "rgb(51, 51, 51)", fontSize: "24px", whiteSpace:"pre", }}  x="685.756" y="356.863"><title>RightBoxText</title></text>
<g transform="matrix(1, 0, 0, 1, 785.582775, 567.467477)">
<title></title>
<circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)",  cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="370.2" cy="99" r="16"/>
<text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}}
x="43.61" y="-171.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopup("rightBox",1)} >+</text>
</g>


</svg>

</MKBox>
              </Grid>
  </TabPanel>
  <TabPanel key ={2} value={2}>
  <Grid >
                 <MKBox height="100%">
                 <svg viewBox="-796.919 -54.88 2231.75 855.491" xmlns="http://www.w3.org/2000/svg" >
<image width="2112" height="1188" x="-762.036" y="-272.297" href={ShopFImage}/>
<rect x="-466.37" y="-10.298" width="1531.142" height="84.57" fill="transparent" >
<title>TopBox</title>
</rect>
<image x="-489.37" y="-26.298" width="1591.542" height="125.957" href={e3BannerTopImage}  preserveAspectRatio="none" clip-path="url(#rectViewTop)"></image>

<rect x="-429.817" y="121.201" width="483.359" height="474.05" fill="transparent" id="rectViewLeft">
<title>LeftBox</title>
</rect>
<image  x="-435.817" y="121.201" width="522.750" height="494.5" href={e3BannerLeftImage} preserveAspectRatio="none" clip-path="url(#rectViewLeft)"></image>

<rect x="552.166" y="129.284" width="484.087" height="474.934" fill="transparent" id="rectViewRight">
<title>RightBox</title>
</rect>
<image x="527.166" y="121.284" width="527.087" height="495.934" href={e3BannerRightImage} preserveAspectRatio="none" clip-path="url(#rectViewRight)"></image>


<text style={{fill: "rgb(51, 51, 51)", fontSize: "56.5063px",whiteSpace:"pre"}} transform="matrix(0.497249, 0, 0, 0.424731, 159.678866, 3.651333)" x="-169.05" y="47.553"><title>TopBoxText</title></text>
<g transform="matrix(1, 0, 0, 1, 256.190067, 224.303287)">
<title></title>
<circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)",  cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="370.2" cy="99" r="16"/>
<text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}}
x="43.61" y="-171.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopup("topBox",2)}>+</text>
</g>



<text style={{fill: "rgb(51, 51, 51)", fontSize: "24px", whiteSpace:"pre"}} x="-356.967" y="356.909"><title>LeftBoxText</title></text>
<g transform="matrix(1, 0, 0, 1, -261.699833, 567.474862)">
<title></title>
<circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)",  cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="370.2" cy="99" r="16"/>
<text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}} 
x="43.61" y="-171.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopup("leftBox",2)} >+</text>
</g>



<text style={{fill: "rgb(51, 51, 51)", fontSize: "24px", whiteSpace:"pre"}}  x="685.756" y="356.863"><title>RightBoxText</title></text>
<g transform="matrix(1, 0, 0, 1, 785.582775, 567.467477)">
<title></title>
<circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)",  cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="370.2" cy="99" r="16"/>
<text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}}
x="43.61" y="-171.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopup("rightBox",2)} >+</text>
</g>


</svg>

</MKBox>
              </Grid>
  </TabPanel>
 
</TabsUnstyled>           
);

setBannerCard(eBanner);
  }
}




const AddSideBannerCard = () => {


if(sideBannerItems.length != 0){

  const sideBanner = (

      <TabsUnstyled key={2} defaultValue={0}>
             <MKTypography variant="h5" pl={{xs:'20px',md:'100px',lg:'135px'}}  >Personalise Side Window(s)</MKTypography>
      <TabsList style={{boxShadow:'none'}}>
      {sideBannerItems.map(item => {
   
   let tabLabel;
   let tabValue;
 
 if(item.id == 0){
     tabLabel = "Side Show Window 1";
     tabValue = 0;
 }
 if(item.id == 1){

  
     tabLabel = "Side Show Window 2";
     tabValue = 1;
   }
   if(item.id == 2){
   
     tabLabel = "Side Show Window 3";
     tabValue = 2;
   }

              return (
                 <Tab key ={tabValue} value={tabValue}>{tabLabel}</Tab>
              )})}


      </TabsList>

      <TabPanel key ={0} value={0}>
      <Grid >
<MKBox height="100%">
<svg viewBox="-2732.685 -763.886 2445.721 1025.892" xmlns="http://www.w3.org/2000/svg" >

<image width="2342" height="1388" x="-2705.805" y="-1029.019" href={ShopSideImage}/>
<rect x="-2340.446" y="-710.777" width="1558.185" height="101.129" fill="transparent">
  <title>SideTopBanner</title>
</rect>
<image x="-2399.946" y="-724.977" width="1751.185" height="129.429" href={s1SideTopImage} preserveAspectRatio="none"></image>

<rect x="-2674.498" y="-538.059" width="1506.955" height="552.217" fill="transparent">
  <title>SideCenterBanner</title>
</rect>
<image x="-2393.999" y="-581.259" width="1739.955" height="646.857" href={s1SideCenterImage} preserveAspectRatio="none"></image>

<text style={{fill: "rgb(51, 51, 51)", fontSize: "24px", whiteSpace:"pre"}}  x="-1700.756" y="-680.863"><title>SideTopBox</title></text>
<g transform="matrix(1, 0, 0, 1, -1570.582775, -480.467477)">
  <title></title>
  <circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)",  cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="370.2" cy="99" r="16"/>
  <text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}}
   x="43.61" y="-171.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopupSide("sideTopBox",0)} >+</text>
</g>


<text style={{fill: "rgb(51, 51, 51)", fontSize: "24px", whiteSpace:"pre"}}  x="-1720.756" y="-270.863"><title>SideTopBox</title></text>
<g transform="matrix(1, 0, 0, 1, -1570.582775, -70.467477)">
  <title></title>
  <circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)",  cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="370.2" cy="99" r="16"/>
  <text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}}
   x="43.61" y="-171.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopupSide("sideCenterBox",0)} >+</text>
</g>




</svg>

</MKBox>
                  </Grid>

      </TabPanel>
      <TabPanel key ={1} value={1}>
      <Grid >
                     <MKBox height="100%">
<svg viewBox="-2732.685 -763.886 2445.721 1025.892" xmlns="http://www.w3.org/2000/svg" >

<image width="2342" height="1388" x="-2705.805" y="-1029.019" href={ShopSideImage}/>
<rect x="-2340.446" y="-710.777" width="1558.185" height="101.129" fill="transparent">
  <title>SideTopBanner</title>
</rect>
<image x="-2399.946" y="-724.977" width="1751.185" height="129.429" href={s2SideTopImage} preserveAspectRatio="none"></image>

<rect x="-2314.498" y="-538.059" width="1506.955" height="552.217" fill="transparent">
  <title>SideCenterBanner</title>
</rect>
<image x="-2393.999" y="-581.259" width="1739.955" height="646.857" href={s2SideCenterImage} preserveAspectRatio="none"></image>


<text style={{fill: "rgb(51, 51, 51)", fontSize: "24px", whiteSpace:"pre"}}  x="-1700.756" y="-680.863"><title>SideTopBox</title></text>
<g transform="matrix(1, 0, 0, 1, -1570.582775, -480.467477)">
  <title>ButtonGroupSideTop</title>
  <circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)",  cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="370.2" cy="99" r="16"/>
  <text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}}
   x="43.61" y="-171.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopupSide("sideTopBox",1)} >+</text>
</g>






<text style={{fill: "rgb(51, 51, 51)", fontSize: "24px", whiteSpace:"pre"}}  x="-1720.756" y="-270.863"><title>SideTopBox</title></text>
<g transform="matrix(1, 0, 0, 1, -1570.582775, -70.467477)">
  <title>ButtonGroupSideTop</title>
  <circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)",  cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="370.2" cy="99" r="16"/>
  <text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}}
   x="43.61" y="-171.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopupSide("sideCenterBox",1)} >+</text>
</g>






</svg>

</MKBox>
                  </Grid>
      </TabPanel>
      <TabPanel key ={2} value={2}>
      <Grid >
                     <MKBox height="100%">
<svg viewBox="-2732.685 -763.886 2445.721 1025.892" xmlns="http://www.w3.org/2000/svg" >

<image width="2342" height="1388" x="-2705.805" y="-1029.019" href={ShopSideImage}/>
<rect x="-2340.446" y="-710.777" width="1558.185" height="101.129" fill="transparent">
  <title>SideTopBanner</title>
</rect>
<image x="-2399.946" y="-724.977" width="1751.185" height="129.429" href={s3SideTopImage} preserveAspectRatio="none"></image>

<rect x="-2314.498" y="-538.059" width="1506.955" height="552.217" fill="transparent">
  <title>SideCenterBanner</title>
</rect>
<image x="-2393.999" y="-581.259" width="1739.955" height="646.857" href={s3SideCenterImage} preserveAspectRatio="none"></image>


<text style={{fill: "rgb(51, 51, 51)", fontSize: "24px", whiteSpace:"pre"}}  x="-1700.756" y="-680.863"><title>SideTopBox</title></text>
<g transform="matrix(1, 0, 0, 1, -1570.582775, -480.467477)">
  <title>ButtonGroupSideTop</title>
  <circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)",  cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="370.2" cy="99" r="16"/>
  <text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}}
   x="43.61" y="-171.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopupSide("sideTopBox",2)} >+</text>
</g>




<text style={{fill: "rgb(51, 51, 51)", fontSize: "24px", whiteSpace:"pre"}}  x="-1720.756" y="-270.863"><title>SideTopBox</title></text>
<g transform="matrix(1, 0, 0, 1, -1570.582775, -70.467477)">
  <title>ButtonGroupSideTop</title>
  <circle style={{fill: "rgb(216, 216, 216)", stroke:"rgb(0, 0, 0)",  cursor:'pointer'}} transform="matrix(1, 0, 0, 1.000036, -319.029083, -272.870483)" cx="370.2" cy="99" r="16"/>
  <text  style={{fill: "rgb(51, 51, 51)", fontSize: " 24.6px", whiteSpace:"pre",  cursor:'pointer'}}
   x="43.61" y="-171.582" transform="matrix(0.975496, 0, 0, 1.019701, 1.726469, 9.684077)" onClick={()=>openPopupSide("sideCenterBox",2)} >+</text>
</g>






</svg>

</MKBox>
                  </Grid>
      </TabPanel>
     
    </TabsUnstyled>            
  );
  
  setSideBannerCard(sideBanner);
  
}

}








/*====================== My Code ===================================*/


const NavigateToUpdateFilePage =()=>{
  console.log("Button Click !!!!!!!!!!!!!!!")
  navigate(`/buildingSelection/${itemDetail.id}`)
}



console.log(designType)


/*========================== End =======================================*/


  return (
    <>       
    <Snackbar open={openNotification} autoHideDuration={6000} onClose={()=>setOpenNotification(false)}>
            <Alert onClose={()=>setOpenNotification(false)} severity={notificationMessageType}>{notificationMessage}</Alert>
      </Snackbar>

      <Snackbar open={openNotificationCopy} autoHideDuration={6000} onClose={()=>setOpenNotificationCopy(false)}>
            <Alert onClose={()=>setOpenNotification(false)} severity="success">{notificationMessage}</Alert>
      </Snackbar>

{isLand == true?(
  <>
      <MKBox pl={{ base: '2px', xs:'20px', md: '10px', lg: '135px' }} pr={{ base: '2px',xs:'20px', md: '10px', lg: '135px' }} pt={7}>
                <MKTypography variant="h5" >Land Details</MKTypography>
            </MKBox>
  </>
):(
<>
<MKBox pl={{ base: '2px', xs:'20px', md: '10px', lg: '135px' }} pr={{ base: '2px',xs:'20px', md: '10px', lg: '135px' }} pt={7}>
                <MKTypography variant="h5" >Unit Details</MKTypography>
            </MKBox>
</>
)}



            <Grid container pl={{ base: '2px',xs:'10px', md: '20px', lg: '135px' }} pr={{ base: '2px',xs:'10px', md: '20px', lg: '135px' }}>

          

            <Grid item xs={12} md={6} >

<MKBox

    style={{ borderRadius: "15px" }}
    minHeight="450px"
    width="100%"
    sx={{

        backgroundSize: "cover",
        backgroundPosition: "top",
        display: "grid",
        placeItems: "center",
    }}
>




<iframe src={itemDetail.mapUrl} width="100%" height="470px" scrolling="no" frameBorder="0" style={{marginTop:"5%"}}/>
</MKBox>



{/*
<MKBox  pt={7}>
           
<Link to={`/buildingSelection/${itemDetail.id}`}>
  
  <Button  color="primary" size ="small" variant="outlined"  component="label"  sx={{
      color:'white',
      textTransform:'capitalize',
     '&:hover': {
      backgroundColor: '#26F8FF',
      color:'#000'
    }
  }}>
   Design Building
    </Button>
  </Link>


 </MKBox>
*/}

</Grid>


  <Grid item xs={12} md={6} mt ={4} pl={{xs:'12px',md:'60px'}} pr={{xs:'20px',md:'60px'}}>

                    <MKBox>

                    {isLand == false ? (<>
                                              <MKTypography
                                              variant="subtitle1"
                                              color="white"
                  
                                          >
                                              INFINITY VOID MALL & METAPLEX
                                          </MKTypography>

< Stack direction="row" spacing={2}
pt={2}
> 

<MKTypography
 variant="body"
 color="primary"

>
Unit Number:
</MKTypography>

 <MKTypography
 variant="body"
 color="white"

>
 {itemDetail.name}
</MKTypography>

</Stack>
                                          </>
                    ) : (
                      <>
                      <MKTypography
                      variant="subtitle1"
                      color="white"

                  >
                      INFINITY VOID LAND
                  </MKTypography>

                  
< Stack direction="row" spacing={2}
pt={2}
> 

<MKTypography
 variant="body"
 color="primary"

>
Parcel Number:
</MKTypography>

 <MKTypography
 variant="body"
 color="white"

>
 {itemDetail.name}
</MKTypography>

</Stack>
</>
                    )}
                







                        < Stack direction="row" spacing={2}
                            pt={2}
                        > 
                        
                        <MKTypography
                            variant="body"
                            color="primary"

                        >
                           Area:
                        </MKTypography>

                            <MKTypography
                            variant="body"
                            color="white"

                        >
                           {itemDetail.area} sqm.
                        </MKTypography>

                        </Stack>


                        < Stack direction="row" spacing={2}
 pt={2}
                        > 

{isLand == false ? (<>
                        
                        <MKTypography
                            variant="body"
                            color="primary"

                        >
                           Unit Area Category:
                        </MKTypography>
</>):(<>
  <MKTypography
                            variant="body"
                            color="primary"

                        >
                          Category:
                        </MKTypography>
</>)}
                            <MKTypography
                            variant="body"
                            color="white"

                        >
                           {itemDetail.inventoryCategory}
                        </MKTypography>

                        </Stack>

                    </MKBox>

                    <MKBox pt={2}>
                    <>

                    
 <Grid container>
                    <Grid item xs={12} md={6} >
    <MKTypography
                                variant="body"
                                color="primary"
    
                            >
                                Link ENS Subdomain :
                            </MKTypography>
</Grid>
{showEns == true ? (
 
<Grid item xs={12} md={6} >

<TextField
 id="user-name"
 value={ensNameLink}
 maxRows={1}
 fullWidth
  e  InputProps={{
   endAdornment: 
    
      <InputAdornment sx={{
   
     }} position="end">
       <Stack direction={"row"} spacing={2}>

      {/* <MKButton size="small" variant="outlined" sx={{
        fontSize:'10px'
       }} onClick={()=>setShowEns(false)}>Link New</MKButton> */}

       <MKButton size="small" variant="outlined" sx={{
        fontSize:'10px'
       }} onClick={()=>unLinkEns()} >Remove Selected ENS</MKButton>
      
         </Stack>
      </InputAdornment>,}}/>

</Grid>                  


):(
 <Grid item xs={12} md={6} >

{ensNfts&& ensNfts.length>0 ? (
<FormControl  fullWidth>
<Select 
labelId="select-ens"
id="ens-select"
onChange={handleENSChange}
value={linkedENSName}
style={{height:'45px'}}
MenuProps={MenuProps}
>
{ensNfts.map((item) => (
<MenuItem

key={item.name}
value={item}
>
{item.name}
</MenuItem>
))}
</Select>
</FormControl>

) : (
<FormControl   fullWidth>
<Select
labelId="select-ens"
id="ens-select"
style={{height:'35px'}}
>

<MenuItem


>
No ENS
</MenuItem>

</Select>
</FormControl>
)}
</Grid>


                        )}    
        </Grid>                       
    </>

                        
                    </MKBox>


                    <MKBox pt ={2}>

                    <Grid container>
                    <Grid item xs={12} md={6} >
    
    <MKTypography
                                variant="body"
                                color="primary"
    
                            >
                             Select Scene To Deployed:
                            </MKTypography>
</Grid>

{showScenes== true  ? (

<Grid item xs={12} md={6} >  
                            <FormControl   fullWidth>
                            <Select
    
 
    style={{height:'35px'}}
    id="selectedScene"
    value={selectedScene && selectedScene}
    onChange={handleSelectSceneChange} 
    MenuProps={MenuProps}
  >
     

{deployedScene && deployedScene.map((item, index) => (
      <MenuItem key={index} 
      //onClick={handleClose}
      value={item}
      >
        {item.name}
      </MenuItem>
    ))}


                                     
  </Select>
    </FormControl>
                         
</Grid>

):(

  <Grid item xs={12} md={6} >  
                            <FormControl   fullWidth>
                            <Select
    
    labelId="select-scenes"
    id="scene-select"
    style={{height:'35px'}}
  >
 <MenuItem>No Scene NFT Found</MenuItem> 
                               
  </Select>
    </FormControl>
                         
</Grid>
)}


    </Grid>
    
                    </MKBox>



                    {itemDetail.deployedScene && itemDetail.deployedScene?( 

                    <MKBox pt ={2}>

<Grid container>
<Grid item xs={12} md={6} >

<MKTypography
            variant="body"
            color="primary"

        >
          Deployed Scene:
        </MKTypography>
</Grid>
<Grid item xs={12} md={6} >  
        <FormControl  fullWidth>
        <TextField inputProps={{
      style: {
        height:'35px',
        padding: '0 14px',
      },
  }}  id="selectedScene" value={selectedScene && selectedScene.name}  />
                 
</FormControl>
     
</Grid>
</Grid>

</MKBox>
                    ):(null)
}





                    <MKBox pt ={2}>

<Grid container>
<Grid item xs={12} md={6} >

<MKTypography
            variant="body"
            color="primary"

        >
          URL:
        </MKTypography>
</Grid>
<Grid item xs={12} md={6} >  
        <FormControl  fullWidth>
        <TextField inputProps={{
      style: {
        height:'35px',
        padding: '0 14px',
      },
  }}  id="webUrl" value={webUrl} onChange={handleAddWebUrl}  />
                 
</FormControl>
     
</Grid>
</Grid>

</MKBox>

                    <MKBox pt ={2}>

<Grid container>
<Grid item xs={12} md={6} >

<MKTypography
            variant="body"
            color="primary"

        >
          Description:
        </MKTypography>
</Grid>
<Grid item xs={12} md={6} >  
        <FormControl   fullWidth>
        <TextField
        
         multiline
          rows={5} 
          
          value={unitDescription} onChange={handleAddDescription}
          inputProps={{maxLength: 100}}
          InputProps={{ 
          
        
           
          }}
          fullWidth 
        
         
      />
              <MKTypography
            variant="caption"
            color="primary"
           style={{marginTop:'-10%', marginLeft:'79%'}}
            >
          {disCount}/100
        </MKTypography>

</FormControl>
     
</Grid>
</Grid>

</MKBox>




     




     <MKBox pt={7}>

     <Button startIcon={<ContentCopyIcon />}  onClick={()=>copyGameLink(SharedLink)}  color="primary" size ="small" variant="outlined"  component="label"  sx={{
    color:'white',
    textTransform:'capitalize',
   '&:hover': {
    backgroundColor: '#26F8FF',
    color:'#000'
  }
}}>
 Share Link

      </Button>

     <Stack direction="row" spacing={2}>
     {isLand == false ? (
      <>
     <a href={viewLayoutUrl} target="blank">
     <MKButton  variant="outlined" color="secondary">View Layout</MKButton>
     </a>

     <a href={downloadPlanLayoutUrl} target="blank">
     <MKButton variant="outlined" color="secondary">Download Layout (DWG)</MKButton>
     </a>
     </>
     ):(null)}
   
    
    

     </Stack>
    </MKBox>

       
               



                </Grid>


            </Grid>



          

            {isLand == false ? (
              <>
           <Grid>
           
           {bannerCard}


       </Grid>
             
       <Grid>   
       {sideBannerCard} 
       </Grid>
       </>
            ):(
<>






{/*===============================  My New Code =============================================*/}


<Grid  container pl={{ base: '2px',xs:'10px', md: '20px', lg: '135px' }} pr={{ base: '2px',xs:'10px', md: '20px', lg: '135px' }}>

<MKBox pt={7}>

  
 
<a href={itemDetail.plotModel} target="blank">
<MKButton variant="outlined" color="secondary">DOWNLOAD PLOT REFERENCE MODEL (GLB)</MKButton>
</a>



</MKBox>

{designType == "Custom"?(
<>


<model-viewer   id="NewCanvas2" 
src={ThreeDUrl }
shadow-intensity="1" camera-controls touch-action="pan-y" style={{width: "100%", height:"400px", marginTop:"50px",
border:"2px solid #26F8FF"
}}>    



<MKBox  p={1}>
          
          <Link to={`/buildingSelection/${itemDetail.id}`} >
            
            <Button  color="primary" size ="small" variant="outlined"  component="label"  sx={{
                color:'white',
                textTransform:'capitalize',
               '&:hover': {
                backgroundColor: '#26F8FF',
                color:'#000'
              }
            }}
            onClick={NavigateToUpdateFilePage}
            >
             Change Model
              </Button>
            </Link>
          
          
           </MKBox>
          
          
           </model-viewer>
          






</>):designType === "Preset" ? (<>


              <model-viewer   id="NewCanvas2" 
shadow-intensity="1" camera-controls touch-action="pan-y" 
style={{
 position:"relative",
 width: "100%", height:"400px", marginTop:"50px",border:"2px solid #26F8FF"}}
>    


<MKBox  p={1}>
<Button  color="primary" size ="small" variant="outlined"  component="label"  sx={{
                color:'white',
                textTransform:'capitalize',
               '&:hover': {
                backgroundColor: '#26F8FF',
                color:'#000',
                position: 'absolute',
                zIndex:"99"
              }
            }}
            onClick={NavigateToUpdateFilePage}
            >
          Upload New Model
              </Button>
</MKBox>
           


<Canvas dpr={[1, 2]}  style={{position:"fixed",  zIndex:"-10", top:"0"}} >
<Suspense fallback={<Loader />}>

<CameraControls />
         <Experience dataNew={propsSend} baseId={baseId} topId={topId} buildingId={buildingId}/>
         <Sky
            distance={450000}
            sunPosition={[5, 1, 8]}
            inclination={0}
            azimuth={0.25}
          
        />
         </Suspense>

</Canvas>
 



</model-viewer>


</>): (
<>

<model-viewer   
shadow-intensity="1" camera-controls touch-action="pan-y" style={{width: "100%", height:"400px", marginTop:"50px",
border:"2px solid #26F8FF",
 
   position:"relative"

 
 }}>    



<MKBox    style={{width:"100%", height:"100%", 
display: 'flex',
justifyContent: 'center',
alignItems: 'center',

}}>
            
            <Button  color="primary" size ="small" variant="outlined"  component="label"  sx={{
                color:'white',
                textTransform:'capitalize',
               '&:hover': {
                backgroundColor: '#26F8FF',
                color:'#000'
              }
         
            }}
            onClick={NavigateToUpdateFilePage}
            >
             Upload Model
              </Button>
        
          
           </MKBox>
          
          
           </model-viewer>
          



</>

)}




</Grid>

</>
            )}     
 
               
                  
          






 
            <Grid container
           
               pt={'4%'}
            pl={{xs:'20px', md:'100px', lg:'135px'}}  >
               <MKBox  >
                  <MKTypography variant="h5">
                   Privacy Settings
                  </MKTypography>
                  <FormControlLabel control={ 
                  <ThemeSwitch 
                
      checked={eventSetting}
      onChange={handleEventSetting}
    
    />} 
    label={eventSettingLabel} />
    
               </MKBox>  
             
               </Grid>



      

{eventSetting == false ? (
    <>
   <Grid  container pl={{xs:'15px',md:'100px', lg:'125px'}}  >
   <Grid container  m={1}   >
                  <MKTypography>Everyone allowed</MKTypography>
                   
            </Grid>
     </Grid>
</>
):(   <Grid  container pl={{xs:'15px',md:'100px', lg:'125px'}}  >  
              <Grid container >
                  <Grid item xs={12} md={6} lg={10.7}>
                  <MKBox  p={{xs:'12px' ,md:'9px'}}>
                  <MKInput  label="Contract address" value={allowedContractAddress} onChange={handleContractInput} fullWidth />
                  <MKTypography variant="caption">To add multiple values separate each value using a comma. Max limit 5</MKTypography>
                 
                  </MKBox>
                   </Grid>
                  
            </Grid>


            <Grid container >
                  <Grid item xs={12} md={6} lg={10.7}>
                  <MKBox  p={{xs:'12px' ,md:'9px'}}>
                  <MKInput  label="Wallet address" value={allowedWalletAddress} onChange={handleAllowedWallet} fullWidth />
                  <MKTypography variant="caption">To add multiple values separate each value using a comma. Max limit 10</MKTypography>
                  </MKBox>
                   </Grid>
                  
            </Grid>
        
           

       </Grid> )}



     
       <Grid  container pl={{xs:'15px',md:'105px', lg:'125px'}}    >  
           <Grid container  m={1}   >
           <MKButton onClick={onUpdateInventoryDetails}  color="primary" style={{color:'black'}} >
                          Save
                </MKButton>

            </Grid>
    
       </Grid> 

    
        <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
    <Slide direction="down" in={show} timeout={500}>
      <MKBox
        position="relative"
        width="300px"
        display="flex"
        flexDirection="column"
        borderRadius="xl"
        bgColor="black"
        shadow="xl"
      >
               <MKBox display="flex" alginItems="center" justifyContent="space-between" p={1}>
                <MKTypography variant="h5">Upload File
                </MKTypography>

                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
              </MKBox>

 
              <hr style={{color:'white'}} />
        <MKBox p={1}>
         {modalCard}
         

        </MKBox>
        <hr style={{color:'white'}} />
        <MKBox p={1}>

<p  style={{lineHeight:1.2, fontSize:'11px', color:'#f8f9fa'}}>Discalimer: Images here may not display correctly, however it will be displayed properly inside the platform subject to the image dimensions stated here.</p>


</MKBox>
      </MKBox>
    </Slide>
  </Modal>



  <Modal open={showSide} onClose={toggleModalSide} sx={{ display: "grid", placeItems: "center" }}>
    <Slide direction="down" in={showSide} timeout={500}>
      <MKBox
        position="relative"
        width="300px"
        display="flex"
        flexDirection="column"
        borderRadius="xl"
        bgColor="black"
        shadow="xl"
      >
               <MKBox display="flex" alginItems="center" justifyContent="space-between" p={1}>
                <MKTypography variant="h5">Upload File
</MKTypography>
                <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModalSide} />
              </MKBox>

              <hr style={{color:'white'}} />

       
        <MKBox p={1}>
         {sideModalCard}
         

        </MKBox>
        <hr style={{color:'white'}} />
        <MKBox p={1}>

        <p  style={{lineHeight:1.2, fontSize:'11px', color:'#f8f9fa'}}>Discalimer: Images here may not display correctly, however it will be displayed properly inside the platform subject to the image dimensions stated here.</p>


        </MKBox>
      </MKBox>
    </Slide>
  </Modal>



    </>
  );
}

export default InventoryDetail;
