
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building502(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building502.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_002_1.geometry}
            material={materials["MI_Color_01.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_002_2.geometry}
            material={materials["MI_Marble_01_SM_Building_05_01.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_002_3.geometry}
            material={materials["MI_Marble_06_SM_Building_05_01.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_002_4.geometry}
            material={materials["MI_Metal_15_SM_Building_05_01.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_002_5.geometry}
            material={materials.Material__307}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_002_6.geometry}
            material={materials["NewMaterial_Inst3.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_002_7.geometry}
            material={materials["NewMaterial_Inst4.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_002_8.geometry}
            material={materials.adskMatMI_Marble_01_SM_Building_05_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_002_9.geometry}
            material={materials.adskMatMI_Marble_01_SM_Building_05_02}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_002_10.geometry}
            material={materials.adskMatMI_Marble_06_SM_Building_05_01}
          />
        </group>
      </group>
    </group>
  );
}
