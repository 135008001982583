import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building702(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building702.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[0, -1.571, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_07_02_1.geometry}
          material={materials.MI_Metal_18_SM_Building_07_02}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_07_02_2.geometry}
          material={materials.MI_Marble_21_SM_Building_07_02}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_07_02_3.geometry}
          material={materials["NewMaterial1.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_07_02_4.geometry}
          material={materials["MI_Color_04.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_07_02_5.geometry}
          material={materials.NewMaterial_Inst3}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_07_02_6.geometry}
          material={materials.Object_Formica_Speckled_Grey_1_Inst1}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_07_02_7.geometry}
          material={materials.NewMaterial2_Inst5_SM_Building_07_02}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_07_02_8.geometry}
          material={materials["NewMaterial_Inst4.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_07_02_9.geometry}
          material={materials.MI_Veneer_08_SM_Building_07_02}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_07_02_10.geometry}
          material={materials["WorldGridMaterial.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.SM_Building_07_02_11.geometry}
          material={materials["Object_Formica_Speckled_Grey_1_Inst.001"]}
        />
      </group>
    </group>
  );
}

