import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building1401(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building1401.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_14_01_1.geometry}
            material={materials["NewMaterial1.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_14_01_2.geometry}
            material={materials["Object_Formica_Speckled_Grey_1_Inst.002"]}
          />
        </group>
      </group>
    </group>
  );
}
