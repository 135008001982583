import { configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from "@redux-saga/core"
import {rootSaga} from './redux/sagas'
import users from "./redux/slice/usersSlice"
import user from "./redux/slice/user"
import masterInventoryDetail from "./redux/slice/masterInventoryDetail"
import masterInventory from "./redux/slice/masterInventorySlice"
import masterInventoryEns from "./redux/slice/masterInventoryENSSlice"
import builderScene from "./redux/slice/builderSceneSlice"
import buildingSelectSlice from "./redux/slice/buildingSelectSlice"
import buildingSelectDetail from "./redux/slice/buildingSelectDetail"

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
    reducer: {
        users,
        user,
        masterInventory,
        masterInventoryEns,
        masterInventoryDetail,
        builderScene,
        buildingSelectSlice,
        buildingSelectDetail,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware)
})

sagaMiddleware.run(rootSaga)

export default store