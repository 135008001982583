import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building1501(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building1501.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_15_01_1.geometry}
            material={materials["MI_Color_01.003"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_15_01_2.geometry}
            material={materials["MI_Color_04.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_15_01_3.geometry}
            material={materials.MI_Metal_03_SM_Building_15_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_15_01_4.geometry}
            material={materials.Material__328}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_15_01_5.geometry}
            material={materials.NewMaterial_Inst1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_15_01_6.geometry}
            material={materials["Object_Formica_Speckled_Grey_1.004"]}
          />
        </group>
      </group>
    </group>
  );
}

