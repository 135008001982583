import { createSlice } from "@reduxjs/toolkit"

const builderScene = createSlice({
    name: 'BuilderSceneSlice',
    initialState: [{
        rows: [],
        count: 0,
        loading: false,
        filter: {},
        selectedKeys: [],
    }],
    reducers: {
        getBuilderSceneSlice: (state, action) => {
          
            state = action.payload
            return state
        },

        editBuilderSceneSlice: (state, action) => {
            state = state.map(i => i.id == action.payload.id ? action.payload : i)
            return state
        },
       addBuilderSceneSlice: (state, action) => {
            state.push(action.payload)
            return state
        },
        
       
    }
})

export const { getBuilderSceneSlice, editBuilderSceneSlice,addBuilderSceneSlice } = builderScene.actions

export default builderScene.reducer 