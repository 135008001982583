import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building1203(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building1203.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_12_01002.geometry}
            material={materials["MI_Color_01.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_12_01002_1.geometry}
            material={materials["MI_Metal_01_SM_Building_12_01.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_12_01002_2.geometry}
            material={materials["MI_Veneer_08_SM_Building_12_01.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_12_01002_3.geometry}
            material={materials["Material__690.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_12_01002_4.geometry}
            material={materials["NewMaterial_Inst3.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_12_01002_5.geometry}
            material={materials["Object_Formica_Speckled_Grey_1.003"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_12_01002_6.geometry}
            material={materials["WorldGridMaterial.005"]}
          />
        </group>
      </group>
    </group>
  );
}

