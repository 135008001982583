import Grid from "@mui/material/Grid";
import MKButton from "components/MKButton";
import { Link } from 'react-router-dom';
import MKAvatar from "components/MKAvatar";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useDispatch , useSelector} from 'react-redux'
import TYPES from "../redux/types"
import { useAccount, useConnect, useDisconnect, useEnsName } from 'wagmi';
import axios from 'axios'
import { useForm } from "react-hook-form";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MKInput from "components/MKInput";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";


const stripePromise = loadStripe("pk_test_51N5iSiSHwtUZ4n6kXnQfhumkWWmE4GzdQ052MM0WgZUavKNbYjzrhrg5GlSxmeXPOBKC4NpRrKjgQwiLOxnhlY4F003oicuCYi");

var a = window.location.search;

var b = a.split("?");


let unitId;
if(b.length>1){
  var c = b[1].split("@");
   unitId = c[1];
}
console.log(unitId);

function CheckoutForm({currUser,unitIdNew,subscriptionId,paymentClientSecret}) {

  var clientSecret = paymentClientSecret;

  const [isLoading, setIsLoading] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationMessageType, setNotificationMessageType] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
 const [message,setMessage] = useState(); 
 
 useEffect(() => {
    if (!stripe) {
      return;
    }
    })


    

    if (!clientSecret) {
      return;
    }


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create and mount the Payment Element
  
console.log(clientSecret);

  elements.submit();
  
    const confirmPayment = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: "https://staging.dashboard.infinityvoid.io/manageSubscribe",
      },
      redirect: 'if_required' 
        })
  
    if (confirmPayment.error) {
      //alert(confirmPayment.error.message);

      setNotificationMessage(confirmPayment.error.message);
      setNotificationMessageType("error");
      setOpenNotification(true)
    } else {
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      
        console.log(paymentIntent.status);

        if(paymentIntent.status == "succeeded"){

          setNotificationMessage("succeeded");
          setNotificationMessageType("success");
          setOpenNotification(true)
          
          trySwitch();
        }
    
      });
    }
  
  
  }; 


  async function trySwitch() {

   
var data = {
  subscribeId:subscriptionId,
  walletAddress:currUser.walletAddress,
  status:"active",
  unitId:unitIdNew,
  startDate:"",
  endDate:"",
  customerId:currUser.stripeId
}
      console.log(data);

          setMessage("Payment succeeded!");

          await axios.post("https://mint.infinityvoid.io/gameApi/stripeOrder/saveSubscription", data).then(async(response)=>{
console.log(response.status);

if(response.status == "200"){
  window.open("https://staging.dashboard.infinityvoid.io//manageSubscribe","_self")
}
          })
        
        
        
  }
         
     


  return(
    <>

<Snackbar open={openNotification} autoHideDuration={6000} onClose={()=>setOpenNotification(false)}>
            <Alert onClose={()=>setOpenNotification(false)} severity={notificationMessageType}>{notificationMessage}</Alert>
</Snackbar>

      {paymentClientSecret !== undefined?(<>
       
      
         
         <MKBox>
         <form id="payment-form" onSubmit={handleSubmit}>
   
         <PaymentElement id="payment-element"/>

<br/>
         <MKButton type="submit"fullWidth color="primary"  sx={{


color:'#000',
'&:hover': {
backgroundColor: '#26F8FF',
color:'#000'
}
}}>
                           Pay Now 
                         </MKButton>


       
        
        
       </form> 
           </MKBox>
           
     
     </>):(null)}
    </>
  )
}



function Subscribe() {
  const currUser = useSelector(state => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { address, isConnected } = useAccount();
  
  const { register, handleSubmit, setValue,  formState: { errors }   } = useForm({ mode: 'onBlur' });
  const [userName, setUserName] = useState('');
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationMessageType, setNotificationMessageType] = useState("");
  const [openNotification, setOpenNotification] = useState(false);

  const [clientSecret, setClientSecret] = useState();
  const [clientSecretPayment, setClientSecretPayment] = useState();
  const [subscriptionId, setSubscriptionId] = useState();
  const [showStripeUi, setShowStripeUi] = useState(false);
  const [unitDetail,setUnitDetail] = useState();


  useEffect(()=>{

    console.log(unitId);

    const fetchUnitDetails = async()=>{
      if(unitId == undefined){

        console.log("CALLING");
        var units = await axios.get("https://mint.infinityvoid.io/gameApi/masterInventory/?filter[availableForRent]=Yes");
      var a = units.data.rows;
      console.log(a);
        var array = a.filter(e => e.currentOwner == null);
        console.log(array);
        setUnitDetail(array[0]);
  
      }else{
        var unitData = await axios.get(`https://mint.infinityvoid.io/gameApi/masterinventory/${unitId}`);
        console.log(unitData.data);
       setUnitDetail(unitData.data);
  
      }
    }
  
  fetchUnitDetails()
  },[unitId])

  useEffect(() => {

    console.log("call");
    // Create PaymentIntent as soon as the page loads
    fetch("https://mint.infinityvoid.io/gameApi/stripeOrder/createPaymentIntent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "prod_P0GY7FlLvjJYlC" }] }),
    })
      .then((res) =>
      res.json())
      .then((data) => {

        var clientSecretIntent = data.clientSecret;

        console.log(clientSecretIntent);
      setClientSecret(clientSecretIntent);
    
    
    });

  }, []);
  
  const appearance = {
    theme: 'night',
    labels: 'floating'
  };
  const StripeOptions = {
    clientSecret,
    appearance,
  };


console.log(StripeOptions);

  


  useEffect(() => {
    setValue("email", currUser.email);
    setValue("name", currUser.name);
    setValue("city", currUser.address && currUser.address.city);
    setValue("state", currUser.address && currUser.address.state);
    setValue("country", currUser.address && currUser.address.country);
    setValue("postalCode", currUser.address && currUser.address.postal_code);
    setValue("line1", currUser.address && currUser.address.line1);


  }, [currUser])

  useEffect(()=>{
    if(isConnected){
    getLink();
     
    }
  },[address])


  const getLink = async()=>{
    const response = await axios.get(`https://mint.infinityvoid.io/gameApi/gameUser/byWallet/${address.toLowerCase()}`);
    
    console.log(response.data);
  
}

const onCreateUser =async(data)=>{

  console.log(data);

  var newAddress = {
    city:data.city,
    country:data.country,
    line1:data.line1,
    postalCode:data.postalCode,
    state:data.state
  }

  console.log(newAddress);


  if(currUser.name == undefined){

   
    Object.assign(data, {name:data.name.replace(/\s/g, "").toLowerCase()});

    Object.assign(data, {walletAddress:address.toLowerCase()  });
    Object.assign(data, {type:"create"});
fetchCode(data);
 }else{

  if(currUser.stripeId == undefined){
    Object.assign(data, {type:"update"});
    Object.assign(data, {name:data.name});
    Object.assign(data, {email:data.email});
    Object.assign(data, {id:currUser.id});
    fetchCode(data);
  }else{

    console.log(currUser.stripeId);


    Object.assign(data, {type:"noAction"});
    Object.assign(data, {name:data.name});
    Object.assign(data, {email:data.email});
    Object.assign(data, {id:currUser.id});
    Object.assign(data, {custId:currUser.stripeId});
    fetchCode(data);
  }
 }

}




const fetchCode = async(data)=>{

  console.log("call");
  const responseStripe = await fetch("https://mint.infinityvoid.io/gameApi/stripeOrder/createSubscription", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) =>
  res.json())
  .then((data) => {
    console.log(data);
   setClientSecretPayment(data.clientSecret)
   setSubscriptionId(data.subscriptionId)
   setShowStripeUi(true);
  }
   );
}

  return (
    <>  
    {isConnected?(
    <>     
   <Snackbar open={openNotification} autoHideDuration={6000} onClose={()=>setOpenNotification(false)}>
            <Alert onClose={()=>setOpenNotification(false)} severity={notificationMessageType}>{notificationMessage}</Alert>
      </Snackbar>
      <Grid container item xs={12} lg={12} spacing={12} mt={1} mb={4}>
      <Grid container item xs={12} lg={8}  >
    <MKBox width="100%" >
    </MKBox>
{showStripeUi == false?(
    <MKBox  mb={"120px"} width="100%" >
    <MKBox >
    <form onSubmit={handleSubmit(onCreateUser)}>
    <Grid container item spacing={3} pl={{ base: '2px', md: '10px', lg: '175px' }} pr={{ base: '2px', md: '10px', lg: '135px' }}>
    {!currUser?(<>
      <Grid item xs={12} md={4}>
                             <InputLabel required shrink htmlFor="bootstrap-input">
                             User Name
        </InputLabel>
                               <TextField
                               id="user-name"
                                required  {...register("name", {required: true})}
                               maxRows={1}
                              
                               onChange={(event) => {

                                var spaceRemove = event.target.value.trim().split(/ +/).join(' ');
                                event.target.value = spaceRemove.toLowerCase();
                              }}
                              fullWidth
                               e  InputProps={{
                                endAdornment: 
                                 
                                   <InputAdornment  position="end">
                                    
                                    <MKTypography color="primary" variant="body">#{address&& address.slice(38)}</MKTypography>
                              
                                   </InputAdornment>,
                                 

                               
                              }}
                             />
                        </Grid>

                        <Grid item xs={12} md={4}>
    <InputLabel required shrink htmlFor="bootstrap-input">
          Email
        </InputLabel>
       <MKInput required type="email"fullWidth {...register("email", {required: true})} />
      
      </Grid>

    

<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
City
        </InputLabel>
<MKInput placeholder={'Delhi'}  fullWidth {...register("city")}/>
</Grid>

<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
Country
        </InputLabel>
<MKInput placeholder={'India'}  fullWidth {...register("country")}/>
</Grid>


<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
Line1
        </InputLabel>
<MKInput placeholder={'Infinityvoid'}  fullWidth {...register("line1")} />
</Grid>

<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
PostalCode
        </InputLabel>
<MKInput  placeholder={'110044'}  fullWidth {...register("postalCode")} />
</Grid>

<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
State
        </InputLabel>
<MKInput  placeholder={'Delhi'}  fullWidth {...register("state")} />
</Grid>




     </>):(

<MKBox width="100%"   mb={2} >

<MKTypography color="primary" variant="subtitle1">
Review Customer Detail
</MKTypography>

<br/>

<Grid container spacing={3} >

<Grid item xs={12} md={4}>
                             <InputLabel required shrink htmlFor="bootstrap-input">
                             User Name
        </InputLabel>
                               <TextField
                               id="user-name"
                                required  {...register("name", {required: true})}
                               maxRows={1}
                              
                               onChange={(event) => {

                                var spaceRemove = event.target.value.trim().split(/ +/).join(' ');
                                event.target.value = spaceRemove.toLowerCase();
                              }}
                              fullWidth
                               e  InputProps={{
                                endAdornment: 
                                 
                                   <InputAdornment  position="end">
                                    
                                    <MKTypography color="primary" variant="body">#{address&& address.slice(38)}</MKTypography>
                              
                                   </InputAdornment>,
                                 

                               
                              }}
                             />
                        </Grid>

                        <Grid item xs={12} md={4}>
    <InputLabel required shrink htmlFor="bootstrap-input">
          Email
        </InputLabel>
       <MKInput required type="email"fullWidth {...register("email", {required: true})} />
      
      </Grid>

<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
City
        </InputLabel>
<MKInput placeholder={'Delhi'}  fullWidth {...register("city")}/>
</Grid>

<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
Country
        </InputLabel>
<MKInput placeholder={'India'}  fullWidth {...register("country")}/>
</Grid>


<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
Line1
        </InputLabel>
<MKInput placeholder={'Infinityvoid'}  fullWidth {...register("line1")} />
</Grid>

<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
PostalCode
        </InputLabel>
<MKInput  placeholder={'110044'}  fullWidth {...register("postalCode")} />
</Grid>

<Grid item xs={12} md={4}>
<InputLabel  shrink htmlFor="bootstrap-input">
State
        </InputLabel>
<MKInput  placeholder={'Delhi'}  fullWidth {...register("state")} />
</Grid>
  </Grid>

</MKBox>



     )}
   



   
  

  <MKButton type="submit"fullWidth color="primary"  sx={{


    color:'#000',
   '&:hover': {
    backgroundColor: '#26F8FF',
    color:'#000'
  }
}}>
                               Save 
                             </MKButton>
 
                            
    

    </Grid>
      </form>
      </MKBox>
      </MKBox>
):(<>
{clientSecretPayment &&(

<MKBox width="100%"  pl={{ base: '2px', md: '10px', lg: '175px' }} >

<MKTypography color="primary" variant="subtitle1">
Card Details
</MKTypography>

<br/>

{clientSecret && (
 <Elements stripe={stripePromise} options={StripeOptions}>

<CheckoutForm currUser={currUser} unitIdNew={unitDetail.id} subscriptionId={subscriptionId} paymentClientSecret={clientSecretPayment}/>
</Elements>
 )}

</MKBox>

)}
</>
)}

   

</Grid> 

<Grid container item xs={12} lg={3} >



<MKBox 
    width="100%"
    height="350px"
   sx={{
           
             backgroundColor: "#212529",
             borderRadius:"12px",
              
           }} >


<MKBox textAlign="center" p={1}  >
     
         <MKBox textAlign="center"  >
         <MKTypography color="white" variant="footerTextCopy"  sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{unitDetail && unitDetail.name}</MKTypography>
           </MKBox>
    
</MKBox>


<MKBox
    minHeight="34vh"
    width="100%"
    sx={{
      backgroundImage: `url("https://dashboard.infinityvoid.io/Nftplaceholder.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
     
    }}
   
/> 


           </MKBox>
           </Grid>
  </Grid>

   
    </>
     ):(null)}  
     </>
  );
}

export default Subscribe;
