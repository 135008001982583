

import { createSlice } from "@reduxjs/toolkit"

const BuildingSelect= createSlice({
    name: 'BuildingSelectSlice',
    initialState: [{
        rows: [],
        count: 0,
        loading: false,
        filter: {},
        selectedKeys: [],
    }],
    reducers: {
        getBuildingSelectSlice: (state, action) => {
            state = action.payload
            return state
        },
        
        addBuildingSelectSlice: (state, action) => {
            state.push(action.payload)
            return state
        },
        editBuildingSelectSlice: (state, action) => {
            state = state.map(i => i.id == action.payload.id ? action.payload : i)
            return state
        }
    }
})



export const { getBuildingSelectSlice, addBuildingSelectSlice, editBuildingSelectSlice } = BuildingSelect.actions

export default BuildingSelect.reducer 













