import React, {Fragment,Suspense, useState, useEffect, useRef } from "react";
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import { Canvas, extend, useThree, useFrame } from "@react-three/fiber";
import Experience from "./Experience";
import "./builderSelection.css"
import MKTypography from "components/MKTypography";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TYPES from "../../src/redux/types"
import { useDispatch, useSelector } from 'react-redux';
import AWS from 'aws-sdk';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { useAccount, useConnect, useDisconnect, useEnsName } from 'wagmi';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import MKBox from "components/MKBox";
import { Typography } from "@mui/material";
import ProgressBar from "@ramonak/react-progress-bar";
import PropTypes from 'prop-types';
import MKButton from "components/MKButton";
import buildImage from '../assets/images/newimg.jpg';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Html, useProgress } from '@react-three/drei'
import {
  CubeTextureLoader,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Sky } from "@react-three/drei";

extend({ OrbitControls });

let uploadRequest;


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  {value:"Ground", text:'Ground'},
  {value:"Upper", text:'Upper'},
  {value:"Building", text:'Building'},
];


console.log(names)



let unitId;

let unitDetail;

console.log(unitDetail);

function BuildingSelection() {
  const { address, isConnected } = useAccount();
  const navigate = useNavigate();

  var dataItems = useSelector(state => state.buildingSelectSlice);
  const [gltfFileView, setGltfFileView] = useState();

  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progress, setProgress] = useState(0);
  const[fileUrl, setFileUrl]= useState();
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessageType, setNotificationMessageType] =useState();
  const [notificationMessage, setNotificationMessage] = useState("");
  const [preset, setShowPreset] = useState(true);
  const[showSubmitButton,setShowSubmitButton] = useState(false);

    const [category, setCategory] = useState("Ground")

    const [baseId, setBaseId] = useState()

    const [topId, setTopId] = useState()

    const [buildingId, setBuildingId] = useState()

    const [baseIdData, setBaseIdData] = useState()

    const [topIdData, setTopIdData] = useState()

    const [buildingIdData, setBuildingIdData] = useState()


    const [buildingType, setBuildingType] = useState(false)

    const dispatch = useDispatch()
    const gltfInput = useRef();


    const [showGlbFromData, setShowGlbFromData] = useState(true);
    const [showParent, setShowParent] = useState(true)

     const [parentId, setParentId] = useState();
     const [parentName, setParentName] = useState();

   const[childData,setChildData] = useState();

const[propsSend, setPropsSend] = useState();
  
const[designType,setDesignType] = useState('Preset');
     const [showBox, setShowBox] = useState(false)
     const[showPublishButton,setShowPublishButton] = useState(false);

     
  
     const [fileImgUrl, setFileImgUrl] = useState()
     
     

var data = dataItems.filter(e => e.types == category && e.category == "Parent")
console.log(data);



const CameraControls = () => {
  
  const {
    camera,
    gl: { domElement }
  } = useThree();

  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef();
  useFrame(() => controls.current.update());
  return (
    <orbitControls
      ref={controls}
      args={[camera, domElement]}
      autoRotate={true}
      enableZoom={false}
    />
  );
};

function SkyBox() {
  const { scene } = useThree();
  const loader = new CubeTextureLoader();
  const texture = loader.load([
    "/nx.png",
    "/px.png",
    "/ny.png",
    "/py.png",
    "/nz.png",
    "/pz.png"
  ]);

  scene.background = texture;
  return null;
}



function Loader() {

  const { active, progress, errors, item, loaded, total } = useProgress()

  return (<Html center >
   

 <h3>Loading... {progress.toFixed(0)} %</h3>

  </Html>)
}


useEffect(()=>{
  if(isConnected == true){
    var a = window.location.pathname;
    var b = a.split("/");

    unitId = b[b.length - 1];

   async function fetchdata(){
    const response = await axios.get(`https://mint.infinityvoid.io/gameApi/masterInventory/${b[b.length - 1]}`);
    console.log(address);

    console.log(response.data);

    unitDetail = response.data;
    if(response.data.currentOwner !== address.toLowerCase()){
      console.log(response.data.currentOwner);
    navigate("/");
     }
      

   if(unitDetail.plotDesign && unitDetail.plotDesign.type == "Custom"){
        setShowGlbFromData(true)
        setDesignType("Custom")
      }else{

        const data={
          baseId:unitDetail.plotDesign.baseId,
          topId:unitDetail.plotDesign.topId,
          buildingId:unitDetail.plotDesign.buildingId
        }

        const grounddata = await axios.get(`https://mint.infinityvoid.io/gameApi/plotBuilding?filter[meshId]?q=${unitDetail.plotDesign.baseId}`);
        const upperdata = await axios.get(`https://mint.infinityvoid.io/gameApi/plotBuilding?filter[meshId]?q=${unitDetail.plotDesign.topId}`);
        const buildingdata = await axios.get(`https://mint.infinityvoid.io/gameApi/plotBuilding?filter[meshId]?q=${unitDetail.plotDesign.buildingId}`);

console.log(grounddata.data.rows[0].name);

       setBaseId(grounddata.data && grounddata.data.rows[0].name);
       setBaseIdData(grounddata.data && grounddata.data.rows[0].meshId);
       setTopId(upperdata.data && upperdata.data.rows[0].name);
       setTopIdData(upperdata.data && upperdata.data.rows[0].meshId);
       setBuildingId(buildingdata.data && buildingdata.data.rows[0].name);
       setBuildingIdData(buildingdata.data && buildingdata.data.rows[0].meshId);

      }

    if(response.data.currentOwner !== address.toLowerCase()){
       console.log(response.data.currentOwner);
     navigate("/");
      }
   }

  fetchdata();
  }
  else{
    navigate("/");
  }
},[]);




const handleClickParent = (id, PName, imgUrl) => {
      
 
    setParentId(PName)  

     setShowParent(false)


     setParentName(PName)

var cdata = dataItems.filter(e=> e.parentId == id);
console.log(cdata);
setChildData(cdata)
};




const backButton =()=>{

  setShowParent(true)

}



    const handleChange = (event) => {


   setCategory(event)
   
   setShowBox(true)
   dataSearch(event);

   var data = dataItems.filter(e => e.types == event && e.category == "Parent")

 };
  




 
useEffect(()=>{
  
 dispatch({ type: TYPES.GET_BUILDING_SELECT })


},[category ])





const selectChild = async(selectedChildData)=>{
console.log(selectedChildData);

if(selectedChildData.types == "Ground"){
  setBaseId(selectedChildData.name)
setBaseIdData(selectedChildData.meshId)
setBuildingType(false);

setShowPublishButton(true);
}

if(selectedChildData.types == "Upper"){
  setTopId(selectedChildData.name)

  setTopIdData(selectedChildData.meshId)
  setBuildingType(false);
  setShowPublishButton(true);

}

if(selectedChildData.types == "Building"){
  setBuildingId(selectedChildData.name)

  setBuildingIdData(selectedChildData.meshId)
  setBuildingType(true);
  setShowPublishButton(true);

}

setPropsSend(selectedChildData);

}



const dataSearch =  async(data) => {
  console.log(data)

      dispatch({ type: TYPES.GET_BUILDING_SELECT_SEARCH, searchQuerry: data })
   
}  

const s3FileUpload = new AWS.S3({
    
  correctClockSkew:true,
  
  endpoint:'fra1.digitaloceanspaces.com', 
  
  accessKeyId:process.env.REACT_APP_ACCES_KEY_ID,
  
  secretAccessKey:process.env.REACT_APP_SECRET_ACCESS_KEY,
  
  region:'fra1'
  
  ,logger: console
  
  });

  const handleFileUploadNew =async(event)=>{
    setShowGlbFromData(false);

    const file = gltfInput.current.files[0];
    if(file.size > 10485760){
      setNotificationMessageType("error");
      setOpenNotification(true);
      setNotificationMessage("Invalid file size");
      setShowGlbFromData(true);

     }else{
      handleFileUpload(event);
     }

   

  }







  const handleFileUpload = async(event) => {

 
    const file = gltfInput.current.files[0];

/*
    const loader = new GLTFLoader();
  
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('/draco/');
      loader.setDRACOLoader(dracoLoader);

//var boundMin = {x: -0.016250134999999943, y: 6.1906253284499995, z: 2.1725001349999995};
//var boundMax = {x: 1.016250134999999943, y: 9.1906253284499995, z: 4.1725001349999995}
      const Mainbox = new THREE.Box3();

const mesh = new THREE.Mesh(
	new THREE.BoxGeometry(24,48,16),
	new THREE.MeshBasicMaterial()
);

mesh.geometry.computeBoundingBox();

Mainbox.copy( mesh.geometry.boundingBox ).applyMatrix4( mesh.matrixWorld );


    loader.load(URL.createObjectURL(file), (gltf) => {

      gltf.scene.traverse( function ( child )
      {
          if ( child.isMesh )
          {
              const box = new THREE.Box3().setFromObject(child);
 
              const boxSize = box.getSize(new THREE.Vector3());
      
              }
           

              
      } );
 
               var root = gltf.scene;
   
               

               const newScene = new THREE.Scene();


              const box = new THREE.Box3().setFromObject(root);

              const boxSize = box.getSize(new THREE.Vector3());
              const boxCenter = box.getCenter(new THREE.Vector3());
              root.position.set(-boxCenter.x,-boxCenter.y-6,-boxCenter.z);
              const box1 = new THREE.Box3().setFromObject(root);
              const boxCenterUp = box1.getCenter(new THREE.Vector3());
  var testBox = Mainbox.containsBox(box1);
             
    });
    
    */
    if(file.size > 10485760){
      setNotificationMessageType("error");
      setOpenNotification(true);
      setNotificationMessage("Invalid file size");

     }else{
 
    setGltfFileView(URL.createObjectURL(file));



 
   var extArray=['.glb'];

   const fileValid = extArray.some(el => file.name.includes(el));


      
      if(fileValid == true){
      setShowProgressBar(true);
 
      const currDate = Date.now();

      let newName = currDate+file.name.replace(/\s/g, "");
      const newGlbFile = new File([file], newName);
      const newImageFileName = newName.replace(".glb", ".png");


      uploadRequest = new AWS.S3.ManagedUpload({
        params: { Bucket:'3d-files', Key:newName, Body:newGlbFile, ACL: 'public-read' },  
        service:s3FileUpload               
    });

    

uploadRequest.on("httpUploadProgress", function(evt) {
var perc = parseInt((evt.loaded * 100) / evt.total);
console.log(perc);

setProgress(parseInt((evt.loaded * 100) / evt.total));

if(perc == 100){
  setShowSubmitButton(true);
  }
})
  

 uploadRequest.send(function(err,response) {
            if (err) {
            console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));                  
            } else {                  
            

              var a = response.Location;
              var b = a.replace("fra1.digitaloceanspaces.com/3d-files/","https://3d-files.fra1.cdn.digitaloceanspaces.com/")
console.log(b);
                

              setFileUrl(b);            
            }
            
      });
            
          let  dataURL
          setTimeout(function() {
            var modelViewer2  =  document.getElementById("NewCanvas2");

            dataURL = modelViewer2.toDataURL();
            console.log(dataURL);



            fetch(dataURL)
            .then(res => res.blob())
            .then(async(blob) => {
               let file = new File([blob], newImageFileName, { type: "image/png" })
                uploadRequest = new AWS.S3.ManagedUpload({
                  params: { Bucket:'3d-files', Key:newImageFileName, Body:file, ACL: 'public-read' },  
                  service:s3FileUpload               
              });
        
              
              uploadRequest.send(function(err,response) {
                if (err) {
                console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));                  
                } else {                  

                  setFileImgUrl(response.Location);   
           
                }})
            
            
            })
           }, 1000);
        
         

    


     }else{
      setNotificationMessageType("error");
      setOpenNotification(true);
      setNotificationMessage("Invalid file type");
     }
    }





    
};







const handleChangeDesignType =(event,value)=>{
  console.log(value);
setDesignType(value);
}






const handleDesignPublish = async()=>{
  

  console.log(fileUrl); 
  console.log("ImgUrl");
console.log(fileImgUrl)

console.log(topIdData);

    let updatedData={};
    let plotDesign;
  if(designType == "Custom"){

    plotDesign={
      status:"Pending",
      message:"Pending",  
      type: designType,
      glbUrl:fileUrl,
      thumbnailUrl:fileImgUrl

   }


  }else{
    plotDesign={
    status:"Approved",
    message:"",  
    type: designType,
    glbUrl:fileUrl,
    baseId:baseIdData,
    topId:topIdData,
    buildingId: buildingIdData,
 }

 await axios.put(`/inventoryversion/653f7d134f02b9f59bc24dc7`);

  }
        
            
           Object.assign(updatedData, {
            plotDesign:plotDesign,
           });
  
 
  
  
  
  
      
    dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:unitId, data:{data:updatedData} });
   
    setNotificationMessage("Updated Successfully");
    setNotificationMessageType("success");
    setOpenNotification(true)
 
 setTimeout(() => window.location.reload(false), 2000);
 

  }
  
  

function LinearProgressWithLabel(props) {
  return (

   
        <ProgressBar completed={props.value} maxCompleted={100} bgColor="#26F8FF"/>

  );
}

LinearProgressWithLabel.propTypes = {

value: PropTypes.number.isRequired,
};









    return (
        <div >



<Snackbar open={openNotification} autoHideDuration={6000} onClose={()=>setOpenNotification(false)}>
            <Alert onClose={()=>setOpenNotification(false)} severity={notificationMessageType}>{notificationMessage}</Alert>
      </Snackbar>





                                   


    <Grid  container  item  xs={12} sm={12} md={12} lg={12} >
  




     <Grid   item  xs={6} sm={5} md={5} lg={8}  style={{backgroundColor:"#ececec"}}>
{designType == "Custom"?(
<>

{unitDetail.plotDesign && unitDetail.plotDesign.type == "Custom"?(

<>


{showGlbFromData == true?(<>

  <model-viewer  id="NewCanvas"   src={unitDetail.plotDesign.glbUrl} shadow-intensity="1" camera-controls touch-action="pan-y" style={{width: "100%", height:"100vh"}}>    


<MKButton 
 
      size ="small" variant="outlined"  component="label"  sx={{
          color:'white',
          backgroundColor: '#26F8FF',
          textTransform:'capitalize',
        '&:hover': {
         backgroundColor: '#26F8FF',
         color:'#000'
        },
        '&:active': {
          backgroundColor: '#26F8FF',
          color:'#000'
         },   
        '&:focus': {
          backgroundColor: '#26F8FF',
          color:'#000'
         }, 
        margin:"10px"
      }}
      
    
      
      >  <input hidden type="file"  accept=".glb" onChange={handleFileUploadNew} ref={gltfInput}
      />

          Upload New Model  
    </MKButton>
        
        
</model-viewer>

</>):(<>

   <model-viewer   id="NewCanvas2"    src={gltfFileView} shadow-intensity="1" camera-controls touch-action="pan-y" style={{width: "100%", height:"100vh"}}>    
 
{showSubmitButton == true?(
  <>
     <Box style={{position: "absolute",bottom:20,  width:"100%", }} >
   

   <MKButton onClick={()=>handleDesignPublish()} size ="small" variant="outlined"  component="label" sx={{
          color:'white',
          backgroundColor: '#26F8FF',
          textTransform:'capitalize',
        '&:hover': {
         backgroundColor: '#26F8FF',
         color:'#000'
        },    
        margin:"10px"
      }}
      
   >
   Submit for Review
 </MKButton>
 
 </Box>
  </>
):(null)}
 
     </model-viewer>


  

</>)}



</>):(

<>

   
    <model-viewer id="NewCanvas2"    src={gltfFileView} shadow-intensity="1" camera-controls touch-action="pan-y" style={{width: "100%", height:"100vh"}}>    
   
    {showSubmitButton == false?(
    <Grid style={{ paddingTop: "180px", textAlign: "center" }} >
                                          
                                  
                                              <Box>
                                                  <IconButton color="primary" style={{ fontSize: "8vw" }} aria-label="upload picture" component="label">
                                                      <input hidden type="file" accept=".glb" onChange={handleFileUpload} ref={gltfInput}
                                                      />
                                      
                                                      <CloudUploadIcon />
                                             
                                      
                                                  </IconButton>
                                              </Box>

                                              <Box style={{ textAlign: "center" }}>
                                                  <Box>
                                                      <Typography style={{ fontSize: "13px", color: "black" }}>
                                                          Drag + drop or browse your package
                                                      </Typography>
                                                  </Box>
                                              </Box>

                                              {showProgressBar == true?(
                                                <>
                                            <Box  sx={{ width: '100%' }}>
                                            <LinearProgressWithLabel variant="determinate"
                                            value={progress} 
                                            />
                                            <br/>
                                            {progress == 100?(
                                            <Box  style={{  padding:"10px"}}>
                                            <Typography variant="body2" color="text.secondary"  style={{color:"black"}}>File Uploaded Successfully</Typography>
                                        </Box>

                                        ):(null)}
                                             
                                            
                                        </Box>
                                        </>
                                        ):(

                                          <Box style={{ paddingTop: "20px", textAlign: "center" }}>
                                          <Box>
                                              <Typography style={{ fontSize: "13px", color: "black" }}>
                                                  Please browse and select the .glb file from your
                                                  computer. You can also drag and drop the file here to start the automatic process. You can check the upload
                                                  status using the progress bar. Max file size is 10 MB
                                              </Typography>

                                          </Box>
                                      </Box>
                                        )}

                                            
                                           
                                </Grid>
    ):(
<>
<Box style={{position: "absolute",bottom:20,  width:"100%", }} >
   

   <MKButton onClick={()=>handleDesignPublish()} size ="small" variant="outlined"  component="label" sx={{
    color:'white',
    backgroundColor: '#26F8FF',
    textTransform:'capitalize',
  '&:hover': {
   backgroundColor: '#26F8FF',
   color:'#000'
  },    
  margin:"10px"
}}
  >
   Submit for Review
 </MKButton>
 
 </Box>
</>
    )}        

     </model-viewer>
</>)}

</>):(
<>


<Canvas dpr={[1, 2]} >
     <Suspense fallback={<Loader />}>
     <CameraControls />
    
         
         <Experience dataNew={propsSend} baseId={baseId} topId={topId} buildingId={buildingId}/>
         <Sky
             distance={450000}
             sunPosition={[5, 1, 8]}
             inclination={0}
             azimuth={0.25}
           
         />
          </Suspense>

</Canvas>

</>)}



     </Grid>



      <Grid   item xs={6} sm={7} md={7} lg={4} style={{  padding:"12px", height:"100vh"}}>


      <ToggleButtonGroup
      color="primary"
      sx={{background:"transparent", borderRadius:"10px", border:"2px solid #26F8FF"}}
      value={designType}
      exclusive
      fullWidth
      size="small"
      onChange={handleChangeDesignType}
    
    >
      <ToggleButton  value="Preset"
   
   style={{
     backgroundColor: designType === "Preset" ? "#26F8FF" : "transparent",
   color: designType === "Preset" ? "black" : "white",
     opacity: designType === "Preset" ? "0.8" : "1",
     fontWeight:"bold"
   }}

   >Preset</ToggleButton>
   
   <ToggleButton  value="Custom" 

   style={{
  //   borderRadius: designType === "Custom" ? "0px 10px 10px 0px":"0px 10px 10px 0px",
     backgroundColor: designType === "Custom" ? "#26F8FF" : "transparent",
     color: designType === "Custom" ? "black" : "white",
     opacity: designType === "Custom" ? "0.8" : "1",
     fontWeight:"bold"
   }}


   >Custom</ToggleButton>


    </ToggleButtonGroup>


    {designType == "Custom"?(
<>


{unitDetail.plotDesign && unitDetail.plotDesign.type == "Custom"?(

<>

{showGlbFromData == true?(<>
  <Grid  item sm={4} md={4} lg={6} pt={4}>
  <Box style={{ position: "relative",   backgroundColor:"gray",borderRadius:"12px",height:"192px",width:"192px"}} >
          <div style={{ position: "absolute", top: 0, left: 0,width: "100%", zIndex:"2", backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "12px 12px 0px 0px", display: "flex", alignItems: "center", justifyContent: "center" }}>
             <span style={{ color: "white", fontSize: "14px" }}> {unitDetail.plotDesign.status}</span>
            </div>

        
         <img  alt="notfound"
          src={unitDetail.plotDesign.thumbnailUrl} 
          style={{position: "absolute", top: 0, left: 0,height:"100%" ,borderRadius:"12px", width:"100%"}} />
       
        

        </Box>
<br/>
</Grid>



</>):(<>
   <Grid  item sm={12} md={12} lg={12}    style={{position: "relative", height:"95%",width:"100%", bottom:"0px"}}   >


</Grid>
</>)}



</>):(
null) }




</>):(
<>


{showParent == true ? (<>  

<Box   mt={4}  >


<Select


fullWidth
displayEmpty
value={category}
onChange={(e)=>handleChange(e.target.value)}

style={{ height:"35px"}}

>

{names.map((option) => (
  <MenuItem
    key={option.value}
    value={option.value}
  >
    {option.text}
  </MenuItem>
))}
</Select>


</Box>




<Box mt={4}   className="box"   style={{ overflowY:"auto", width:"100%", height: "60vh",}}           >

     <Grid container  sm={12} md={12} lg={12} spacing={3} >




{data  && data.map(e => (<>


{category == e.types  &&   (<>

{showBox == true ?(<>

<Box style={{height:"60px"}}></Box>
</>):(<></>)} 





<Grid  item sm={4} md={4} lg={4} >

          <Box key={e.id}

onClick={() => handleClickParent(e.id, e.name, e.thumbnailUrl)} 

style={{borderRadius:"12px",height:"128px",width:"128px"}} >
       
         <img  src={e.thumbnailUrl} alt={e.name} style={{height:"100%" ,borderRadius:"12px", width:"100%"}} />
       
        

        </Box>
          </Grid>
       
</>   )}        
     
</> ))
}





     </Grid>

  </Box>  

</>):(<>


<Grid container item  mt={4}  sm={12} md={12} lg={12} >
             
             
           <Grid  p={0.5} 
            item sm={2} md={2} lg={1} style={{textAlign:"left", left:"0px",  }} >
             
             <IconButton onClick={backButton} size="small"   >
             <ArrowBackIcon   style={{color:"white"}} />
             </IconButton>
             </Grid>



              <Grid   p={1}    item sm={10} md={10} lg={11}   >
            
                <MKTypography  variant="body2"  style={{colour:"white" ,margin: "0 10px"}}>{category}  {"   >>   "} {parentName}</MKTypography>
               
              </Grid>


              
          </Grid>

<hr />


      <Box
       mt={3} 
       className="box"   style={{ overflowY:"auto", width:"100%",
     height: "70vh", 
    }}   >



{childData && (
<>
<Grid container spacing={3}  sm={12} md={12} lg={12} >
{childData.map((e) => (
  <Grid key={e.id} item lg={4}  sm={4} md={4} 
    
     >
    <Box
    onClick={()=>selectChild(e)}
     component="img"
      src={e.thumbnailUrl}
      alt={e.name}

      style={{backgroundColor:"gray",borderRadius:"12px", height:"148px",width:"148px", color: 'white', border: '2px solid black' }}
    >

      </Box>

  </Grid>
))}





</Grid>
<br/>
<MKBox >
{showPublishButton == true?(

  <>
  <Button onClick={()=>handleDesignPublish()}  color="primary" size ="small" variant="outlined"  component="label"  sx={{
      color:'white',
      textTransform:'capitalize',
     '&:hover': {
      backgroundColor: '#26F8FF',
      color:'#000'
    }
  }}       >
   Publish
    </Button>
  </>
):(null)}




</MKBox>
</>
)}

</Box>

</>)}



</>)

}


      </Grid>



    </Grid>y



        </div>
    );
}

export default BuildingSelection;
