
import * as api from '../../apis/index'
import * as buildingSelectSlice from '../slice/buildingSelectSlice'
import { put, takeEvery } from 'redux-saga/effects'
import * as buildingSelectDetail from '../slice/buildingSelectDetail'
import TYPES from "../types"


export function* getBuildingSelectSaga() {
    const Building = yield api.getBuildingSelectAPI()
    yield put(buildingSelectSlice.getBuildingSelectSlice(Building.data.rows))
}

export function* getBuildingSelectByIdSaga(action) {
    yield api.getBuildingSelectByIdAPI(action.id)
    yield put(buildingSelectDetail.getBuildingSelectSlice(action.id))
}



export function* getBuildingSelectSearchSaga(action) {
    const BuildingSearch = yield api.getBuildingSelectSearchAPI(action.searchQuerry)
    yield put(buildingSelectSlice.getBuildingSelectSlice(BuildingSearch.rows))
}




export function* watchBuildingSelectAsync() {
    yield takeEvery(TYPES.GET_BUILDING_SELECT,         getBuildingSelectSaga)
    yield takeEvery(TYPES.GET_BUILDING_SELECT_BY_ID, getBuildingSelectByIdSaga)
    yield takeEvery(TYPES.GET_BUILDING_SELECT_SEARCH, getBuildingSelectSearchSaga)

}