import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building503(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building503.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_003_1.geometry}
            material={materials.Material__275}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_003_2.geometry}
            material={materials.Material__276}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_003_3.geometry}
            material={materials.Material__277}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_003_4.geometry}
            material={materials.Material__278}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_003_5.geometry}
            material={materials.Material__279}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_003_6.geometry}
            material={materials.Material__280}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_003_7.geometry}
            material={materials.Material__281}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_003_8.geometry}
            material={materials.Material__305}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_003_9.geometry}
            material={materials.Material__307}
          />
        </group>
      </group>
    </group>
  );
}

