import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building103(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building103.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_1.geometry}
            material={materials.Blacktop_Old_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_2.geometry}
            material={materials.Formica_Graystone}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_3.geometry}
            material={materials.Formica_Graystone_1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_4.geometry}
            material={materials.Formica_Graystone_2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_5.geometry}
            material={materials.Formica_Graystone_3}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_6.geometry}
            material={materials.Granite_Light_Gray_1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_7.geometry}
            material={materials.Granite_Light_Gray_2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_8.geometry}
            material={materials.M_0014_DarkSalmon}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_9.geometry}
            material={materials.M_0035_Tan}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_10.geometry}
            material={materials.M_0045_Goldenrod}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_11.geometry}
            material={materials.M_0045_Goldenrod_2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_12.geometry}
            material={materials.M_0045_Goldenrod_3}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_13.geometry}
            material={materials.M_0045_Goldenrod_4}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_14.geometry}
            material={materials.M_0045_Goldenrod_5}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_15.geometry}
            material={materials.M_0096_SkyBlue}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_16.geometry}
            material={materials.M_0135_DarkGray}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_17.geometry}
            material={materials.Material__489}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_18.geometry}
            material={materials.Quartz_Light_Brown}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_19.geometry}
            material={materials.Translucent_Glass_Blue}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_20.geometry}
            material={materials.Translucent_Glass_Gray}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_21.geometry}
            material={materials.Wood_Lumber_ButtJoined}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_22.geometry}
            material={materials.Wood_Veneer_02}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_23.geometry}
            material={materials.Wood_Veneer_03}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_24.geometry}
            material={materials.adskMatTranslucent_Glass_Gray_1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_25.geometry}
            material={materials.adskMatTranslucent_Glass_Gray_2}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_003_26.geometry}
            material={materials.simple_light}
          />
        </group>
      </group>
    </group>
  );
}

