import {
  Backdrop,
  BakeShadows,
  Float,
  MeshReflectorMaterial,
  OrbitControls,
  PivotControls,
  PresentationControls,
  Reflector,
  Stage,
  Environment,
  useGLTF,
} from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import BuildingLayout from "./BuildingLayout";
import React, {Fragment,Suspense, useState, useEffect, useRef } from "react";
import { suspend } from 'suspend-react';
const city = import('@pmndrs/assets/hdri/city.exr').then((module) => module.default);

const Experience = (dataNew,baseId,topId,buildingId) => {


  console.log(baseId);


  return (




    <PresentationControls
      speed={1}
      global={false}
      polar={[-1, Math.PI / 2]}
      rotation={[0, 0, 0]}

      style={{border:"2px solid red"}}
    >

     

      <Stage adjustCamera intensity={0.5} shadows="contact" environment={null}>
      <Environment files={suspend(city)}></Environment>
        <BuildingLayout props={dataNew} baseId={baseId} topId={topId} buildingId={buildingId}/>

      </Stage>



      <mesh      rotation={[-Math.PI / 2, 0, 0]}>
        <MeshReflectorMaterial
          blur={[300, 100]}
          resolution={2048}
          mixBlur={1}
          mixStrength={40}
          roughness={1}
          depthScale={1.2}
          minDepthThreshold={0.4}
          maxDepthThreshold={1.4}
          color="#101010"
          metalness={0.5}
        />



        <OrbitControls enableZoom={true} enableDamping={false} enablePan={false} enableRotate={true} />

      </mesh>
    </PresentationControls>

  );
};

export default Experience;
