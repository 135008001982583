
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building2001(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building2001.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_20_01_1.geometry}
            material={materials.MI_Concrete_Floor_ufulajefw_2K_Inst}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_20_01_2.geometry}
            material={materials.MI_Metal_03_SM_Building_20_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_20_01_3.geometry}
            material={materials.MI_Metal_15_SM_Building_20_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_20_01_4.geometry}
            material={materials.MI_Metal_18_SM_Building_20_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_20_01_5.geometry}
            material={materials["NewMaterial1.005"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_20_01_6.geometry}
            material={materials["NewMaterial_Inst3.009"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_20_01_7.geometry}
            material={materials["Object_Formica_Speckled_Grey_1.007"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_20_01_8.geometry}
            material={materials.adskMatNewMaterial1}
          />
        </group>
      </group>
    </group>
  );
}


