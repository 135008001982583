
import { Provider } from 'react-redux';
import { Routes, Route} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Home from "./pages/home";
import theme from "assets/theme"
import { Layout } from "layout";
import Summary from "pages/summary";
import Inventory from "pages/inventory";
import Account from "pages/account";
import store from './store'
import InventoryDetail from 'pages/inventoryDetail';
import FileUpload from 'pages/fileUpload';
import MintAsset from 'pages/mintAssets';
import PlayAsGuestPage from 'pages/playAsGuest';
import UploadPackage from 'pages/uploadPackage';
import { useAccount, useConnect, useDisconnect, useEnsName } from 'wagmi';
import BuildingSelection from 'pages/BuildingSelection';
import Subscribe from 'pages/subcribe';
import ManageSubscribe from 'pages/manageSubscribe';




function App() {

  const { address, isConnected } = useAccount();






  return (  
   
    <Provider store={store}>
   
    <ThemeProvider theme={theme}>
      <CssBaseline />
    
      <Layout/>
      <Routes>
     
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
		 <Route path="/playAsGuest" element={<PlayAsGuestPage />} />
     <Route path="/subscribe/" element={<Subscribe />} />
     <Route path="/subscribe/:id" element={<Subscribe />} />

        {isConnected == true?(
<>
        <Route path="/summary" element={<Summary />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/inventory/:collection" element={<Inventory />} />
        <Route path="/inventory/:collection/:name" element={<InventoryDetail />} />
        <Route path="/uploadFile" element={<FileUpload />} />
        <Route path="/account" element={<Account />} />
        <Route path="/mintAsset" element={<MintAsset />} />
        <Route path="/uploadUnrealPackage" element={<UploadPackage />} />
        <Route path="/uploadUnrealPackage/:id" element={<UploadPackage />} />
        <Route path="/buildingSelection/:id" element={<BuildingSelection />} />
    {/*    
    <Route path="/manageSubscribe" element={<ManageSubscribe />} />
*/}
        </>
        ):(
<></>
        )}
        
       
      </Routes>
     
    </ThemeProvider>
   
    </Provider>
 
  )
}

export default App;