
import * as api from '../../apis/index'
import * as masterInventorySlice from '../slice/masterInventorySlice'
import * as masterInventoryENSSlice from '../slice/masterInventoryENSSlice'
import { put, takeEvery } from 'redux-saga/effects'
import * as masterInventoryDetail from '../slice/masterInventoryDetail'
import TYPES from "../types"



export function* getMasterInventorySaga(action) {
    const inventory = yield api.getMasterInventoryAPI(action.walletAddress)
    yield put(masterInventorySlice.getMasterInventorySlice(inventory.rows))
}


export function* getMasterInventorySearchSaga(action) {
    const inventorySearch = yield api.getMasterInventorySearchAPI(action.walletAddress,action.searchQuerry)
 
    yield put(masterInventorySlice.getMasterInventorySlice(inventorySearch.rows))
}

export function* getMasterInventoryByIdSaga(action) {
    const invDetail = yield api.getMasterInventoryByIdAPI(action.id)

    yield put(masterInventoryDetail.getMasterInventoryDetailSlice(invDetail))
}

export function* getMasterInventoryByCategorySaga(action) {

    const currentCategoryItem = yield api.getMasterInventoryByCategoryAPI(action.category, action.walletAddress)

    yield put(masterInventorySlice.getMasterInventorySlice(currentCategoryItem))

}

export function* getENSSaga(action) {

    const currentENSItem = yield api.getENSAPI(action.walletAddress)

    yield put(masterInventoryENSSlice.getMasterInventoryENSSlice(currentENSItem))

}


export function* updateMasterInventorySaga(action) {
    yield api.updateMasterInventoryAPI(action.id,action.data)
    yield put(masterInventorySlice.editMasterInventorySlice(action.id,action.data))
}



export function* watchInventoryAsync() {
    yield takeEvery(TYPES.GET_INVENTORY,         getMasterInventorySaga)
    yield takeEvery(TYPES.GET_INVENTORY_SEARCH,         getMasterInventorySearchSaga)
    yield takeEvery(TYPES.GET_ENS,         getENSSaga)
    yield takeEvery(TYPES.GET_INVENTORY_BY_ID, getMasterInventoryByIdSaga)
    yield takeEvery(TYPES.GET_INVENTORY_BY_CATEGORY, getMasterInventoryByCategorySaga)
    yield takeEvery(TYPES.UPDATE_INVENTORY_BY_ID, updateMasterInventorySaga)


}