import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building202(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building202.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI, -1.571, 0]}>
        <group rotation={[0, 0, -Math.PI]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001.geometry}
            material={materials["M_0135_DarkGray.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_1.geometry}
            material={materials["Material__2.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_2.geometry}
            material={materials.Material__412}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_3.geometry}
            material={materials["Material__414.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_4.geometry}
            material={materials.Material__45}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_5.geometry}
            material={materials.Material__48}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_6.geometry}
            material={materials["Suede_Dark_Gray.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_7.geometry}
            material={materials["Suede_Dark_Gray_1.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation001_8.geometry}
            material={materials["Terrazzo_Tile_Light.001"]}
          />
        </group>
      </group>
    </group>
  );
}

