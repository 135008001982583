

import { createSlice } from "@reduxjs/toolkit"

const BuildingSelectDetail = createSlice({
    name: 'uildingSelectDetail',
    initialState: {
       
        inventorySetting: {
            name:'',
            meshUrl: '',
            height:'',
            types: '',
            thumbnailUrl: '',
         //   meshId:''
           
        }
    },
    reducers: {
 

        getBuildingSelectSlice: (state, action) => {
            state = action.payload
            return state
        },

    }
})

export const { getBuildingSelectSlice } = BuildingSelectDetail.actions

export default BuildingSelectDetail.reducer















