
import { Grid } from '@mui/material';
import React ,{ useState,useEffect, useRef, } from 'react';
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from 'components/MKButton';
import Button from '@mui/material/Button';
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import { Box } from "@mui/material";
import { useAccount,useNetwork} from 'wagmi'
import { useWalletClient } from 'wagmi'
import { getContract } from '@wagmi/core'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AWS from 'aws-sdk';
import { Stack } from '@mui/system';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { useForm } from "react-hook-form";
import { Typography } from '@mui/material';
import Web3 from "web3";
import { ethers, ContractInterface } from "ethers";
import { signMessage } from '@wagmi/core'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { Alchemy, Network } from "alchemy-sdk";
import { NFTStorage,  Blob } from "nft.storage"
import { Buffer } from "buffer";
import { Biconomy } from "@biconomy/mexa";
import {
  getConfig,
  getSignatureParametersWeb3
} from "../utils";



let biconomy, web3, contractInstance, tokenMetaUrl, contractTokenId, tokenSupply

const client = new NFTStorage({ token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGZCZTI1MjAyM0VCOTFhOTJiNTE4ZkY5NDEyNDFjRWUzZTkwODlFQjQiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3ODUxNTQ1Mzk2MywibmFtZSI6IkluZmluaXR5Vm9pZE1hcCJ9.mj7AFDSMYowKjKoMpPyTjeZfSUbJ_qLgBgMfI2dgDm4" })

const config = {
  apiKey: 'r2J0yAXJyJ4Vsh-fSm4oWIjOYlMJJ5-f',
  network: Network.MATIC_MAINNET,
};
const alchemy = new Alchemy(config);



const s3FileUpload = new AWS.S3({
    
  correctClockSkew:true,
  
  endpoint:'fra1.digitaloceanspaces.com', //Specify the correct endpoint based on where your bucket is
  
  accessKeyId:process.env.REACT_APP_ACCES_KEY_ID,
  
  secretAccessKey:process.env.REACT_APP_SECRET_ACCESS_KEY,
  
  region:'fra1'//Specify the correct region name based on where your bucket is
  
  ,logger: console
  
  });


function FileUpload() {
  const { register, handleSubmit, setValue,  formState: { errors }   } = useForm({ mode: 'onBlur' });

  const { chain } = useNetwork()

  console.log(chain);
  
const { data: walletClient } = useWalletClient({chainId:chain.id})

console.log(walletClient);

  const [data, setData] = useState([]);

    const [hover, setHover] = useState({ display: 'none' })

    const timer = useRef();
  const gltfInput = useRef();
  const [gltfFileView, setGltfFileView] = useState();
    const [showProfile, setShowProfile] = useState(false);
    const [gltfImages, setGLTFImages] = useState("");
const [showMintModal, setShowMintModal] = useState(false);
    const [upload, setUpload] = useState(false)
    const [circles, setCircles] = useState(false)
    const [fileUpStart, setFileUpStart] = useState(false);
    const [activeItem, setActiveItem] = useState();
    var [error, setError] = useState(null)
    const { address, isConnected, connector: activeConnector } = useAccount();
    const [gltfCID, setGltfCID] = useState();
    const [gltfIpfsCID, setGltIpfsCID] = useState();
    const [thumbCID, setThumbCID] = useState();
    const [polygonCount, setPolygonCount] = useState();
    const [config, setConfig] = useState(getConfig("").assetMint)
    const [formData, setformData] = useState();
    const [gltfFileName, setGltfFileName] = useState();
    const [ipfsUploadDone, setIpfsUploadDone] = useState(false);
    const [loading, setLoading] = useState(false);
      
const [openNotification, setOpenNotification] = useState(false);
const [notificationMessageType, setNotificationMessageType] =useState();
const [openNotificationCopy, setOpenNotificationCopy] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");



  
  useEffect(() => {
    const conf = getConfig(chain?.id.toString() || "").assetMint
    setConfig(conf)
  }, [chain?.id])

  useEffect(() => {
    const initBiconomy = async () => {
     
    
      biconomy = new Biconomy((walletClient), {
        apiKey: config.apiKey.prod,
        debug: true,
        contractAddresses: [config.contract.address]
      })

      await biconomy.init()
      web3 = new Web3(window.ethereum)


    contractInstance =  new web3.eth.Contract(
        config.contract.abi,
        config.contract.address
      )

    /*  contractInstance =   getContract({
        address: config.contract.address,
        abi:  config.contract.abi,
      })
  */

    }
    initBiconomy()
  }, [address, chain, config, walletClient?.PublicClient])



   
      const closeProfile = (e,reason)=>{
        console.log(reason);

        if(reason !== "backdropClick" && fileUpStart == false){
          setShowProfile(false);
          
        }
         
      }

      const closeMint = (e,reason)=>{
       
      console.log(reason);
        if(reason !== "backdropClick" && fileUpStart == false){
          setShowMintModal(false);
          
        }
         
      }
    
    
  
      const toogleMint = (item)=>{
       setActiveItem(item);
        showMintModalFun();         
      }

      const showMintModalFun =()=>{
        setShowMintModal(!showMintModal);
      
      }
    



const handleGltfChange = async() => {

  const file = gltfInput.current.files[0];

  console.log(file);
  setFileUpStart(true);

  setGltfFileView(URL.createObjectURL(file));
  setCircles(true);
  setOpenNotification(true);
  setNotificationMessage("please wait");
  setNotificationMessageType('warning');
 const cid = await client.storeBlob(file);
 console.log(cid);
  setGltIpfsCID(cid);
 var tokenUrlThree = "https://ipfs.io/ipfs/"+cid;

 console.log(tokenUrlThree);

  setGltfCID(tokenUrlThree);
  setGltfFileName(file.name);

  timer.current = window.setTimeout(() => {
    
    if (file.length != 0) {
      setUpload(true)
     }

    setCircles(false)
  }, 6000);

  setOpenNotification(true);
  setNotificationMessage("File uploade complete");
  setNotificationMessageType('success');


var glbFileName = file.name;
let numFaces = 0;
  const extension = file.name.split('.').pop();
  const loader = new GLTFLoader();
  if (extension === 'glb') {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco/');
    loader.setDRACOLoader(dracoLoader);
  }
  loader.load(URL.createObjectURL(file), (gltf) => {
 
    gltf.scene.traverse((node) => {
      if (node.isMesh) {
        numFaces += node.geometry.index.count / 3; 
      }
    });
    console.log(numFaces.toString());

    setPolygonCount(numFaces.toString());
 
  });

  setTimeout(function() {
          
    canvasCheck(glbFileName, tokenUrlThree, numFaces);
    }, 10000);

}



const canvasCheck = async(glbFileName,tokenUrlThree, numFaces) => {
 

  setOpenNotification(true);
  setNotificationMessage("thumbnail processing");
  setNotificationMessageType('warning');

  const canvas = document.getElementById("canvas");
  const dataURL = canvas.toDataURL();

fetch(dataURL)
.then(res => res.blob())
.then(async(blob) => {
    const file = new File([blob], glbFileName, { type: "image/png" })
    const cid = await client.storeBlob(file)
   var tokenUrlImage = "https://ipfs.io/ipfs/"+cid;
   console.log(tokenUrlImage);
   setThumbCID(tokenUrlImage);
   setIpfsUploadDone(true);

   setOpenNotification(true);
   setNotificationMessage("thumbnail generate succesfully");
   setNotificationMessageType('success');
   backendUpload(glbFileName,tokenUrlThree, numFaces, tokenUrlImage);
  
})


 
}



  const backendUpload =(glbFileName,tokenUrlThree, numFaces, tokenUrlImage)=>{

var poly = numFaces && numFaces.toString();
    
      const myAddress = address.toLowerCase();
fetch('https://mint.infinityvoid.io/gameApi/uploadGltf', {
  method: 'POST',
  headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
  },
body: JSON.stringify({

  fileUrl: tokenUrlThree,
  name: glbFileName,
  imageUrl: tokenUrlImage,
  ownerAddress:myAddress,
  polyCount:poly

  })
}).then(()=>{
  window.location.reload(true); 

}).catch(error => {
if (error.response.status == 401) setError(error.response.data.message);
else setError("something went wrong")

})


    }

const Success = ()=>{
 
  setOpenNotificationCopy(true);
  setNotificationMessage(" Successfully Copied");
}

    const Popup = () => {
       
        setShowProfile(true);
     }

    

    
      const CopyLink = (file) => {
        console.log('Copy Link Card');
        Success()
          
            navigator.clipboard.writeText(file);
 
    }


    const deleteFile = (fileData)=>{
 

      const deleteData={

        data:{
          id:fileData.id,
         ownerAddress:address.toLowerCase()
        }
     }

      
axios.delete(`/uploadGltf`, deleteData).then((response) => {
  
 if(response.status == 200){
window.location.reload();
 }


});

 }

  
    const onSubmit = async(data) => {
      setformData(data);
       
        if (!address) {
          return
        }
   setFileUpStart(true);    
   setCircles(true);
      
          const response = await alchemy.nft.getNftsForContract('0x4577006d28f0bd911bB682b3dd48bE88396BCC95', {omitMetadata: false});
          contractTokenId = response.nfts.length+1;
          tokenSupply = data.supply;
        

          var itemFile = activeItem.fileUrl+"/?filename="+activeItem.name;

      
        var  metadata = {
            "name": data.fullName,
            "description": data.description,
            "image": activeItem.imageUrl,
            "externalUrl":itemFile,
            "animation_url":activeItem.fileUrl,
          
            "attributes": [
              {
                "trait_type": "Polygons", 
                "value": activeItem.polyCount
              }
            ]
          }
       
              const json = JSON.stringify(metadata, null, 3);
              console.log(json);
              
              const file = new Blob([json], { type: "application/json" });
              
              
              
              const cid = await client.storeBlob(file)
               tokenMetaUrl = "https://ipfs.io/ipfs/"+cid;
              
               console.log(tokenMetaUrl);

console.log(contractInstance);
      
          let functionSignature = await contractInstance.methods
            .mint(address,contractTokenId,tokenSupply,tokenMetaUrl,"0x")
            .encodeABI()
          let message = {
       
            from: address.toLowerCase(),
            functionSignature: functionSignature
          }

          const domainType = [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "verifyingContract", type: "address" },
            { name: "salt", type: "bytes32" }
          ]
          const metaTransactionType = [
            //  { name: "nonce", type: "uint256" },
            { name: "from", type: "address" },
            { name: "functionSignature", type: "bytes" }
          ]
          let domainData = {
            name: "InfinityAsset",
            version: "1",
            verifyingContract: config.contract.address,
            salt: "0x" + (chain?.id || 137).toString(16).padStart(64, "0")
          }
          const dataToSign = JSON.stringify({
            types: {
              EIP712Domain: domainType,
              MetaTransaction: metaTransactionType
            },
            domain: domainData,
            primaryType: "MetaTransaction",
            message: message
          })

 


          var abc = await activeConnector.getWalletClient();

console.log(abc);

   /*      const signRes =  await abc.signMessage(
            'personal_sign',
            [ ethers.utils.hexlify(ethers.utils.toUtf8Bytes(dataToSign)), address.toLowerCase() ]
        );
     */  
        const signRes = await signMessage({
          message: dataToSign,
        })


  
          let { r, s, v } = getSignatureParametersWeb3(signRes)
          sendSignedTransaction(address, functionSignature, r, s, v)
        
    
                  
       /* await abc.provider.send( 
          {
           
          params: [ ethers.utils.hexlify(ethers.utils.toUtf8Bytes(dataToSign)), address.toLowerCase()]},
                   function(error, response) {
              console.info(`User signature is ${response.result}`)
              if (error || (response && response.error)) {
              
              } else if (response && response.result) {
                let { r, s, v } = getSignatureParametersWeb3(response.result)
                sendSignedTransaction(address, functionSignature, r, s, v)
              }
            }
        );

*/
          //abc.provider.provider.send();

      //    await abc.provider.provider.signMessage(message);
   /*

 

          await abc.provider.send(
            {
              jsonrpc: "2.0",
              id: 1,
              method: "eth_signTypedData_v3",
              params: [address, dataToSign]
            },
            function(error, response) {
              console.info(`User signature is ${response.result}`)
              if (error || (response && response.error)) {
              
              } else if (response && response.result) {
                let { r, s, v } = getSignatureParametersWeb3(response.result)
                sendSignedTransaction(address, functionSignature, r, s, v)
              }
            }
          )
*/
       
      }
      
      
      const sendSignedTransaction = async (userAddress, functionData, r, s, v) => {
        try {
          const web3 = new Web3(biconomy.provider)
          const contractInstance = new web3.eth.Contract(
            config.contract.abi,
            config.contract.address
          )
          biconomy.on("txHashGenerated", data => {
       
          
          })
          biconomy.on("txMined", data => {
          
     updateDataBase(data)
    
          
          })
          const tx = await contractInstance.methods
            .mint(address,contractTokenId,tokenSupply,tokenMetaUrl,"0x")
            .send({
              from: userAddress
            })

          

        } catch (error) {
          console.log(error)
      

        

        }
      }

const updateDataBase =async(data)=>{
  console.log(data);
  if(data.receipt.status ==1){
    console.log(data);
          const updateData={
            id:activeItem.id,
            tokenId:contractTokenId
           }
    
          await axios.put(`https://mint.infinityvoid.io/gameApi/uploadGltf/${activeItem.id}`, updateData).then( (response) =>{

if(response.status == 200){
  setCircles(false);
  window.location.reload()
}
          
  }
  );
    
        }

}


useEffect(() => {

  axios.get('https://mint.infinityvoid.io/gameApi/uploadGltf')
      .then(res => {
             var alldata =res.data.rows;
             var newdata = alldata.filter(e => e.ownerAddress == address.toLowerCase());
             console.log(newdata);
             setData(newdata);
          })
          .catch(() => {
           
          })


}, [address])



    const collectionItemCardCol4 = (
    <>
       {/* data */}   
    
       {data.map(item => {
        var itemFile = item.fileUrl+"/?filename="+item.name;
        var openseaUrl = "https://opensea.io/assets/matic/0x4577006d28f0bd911bb682b3dd48be88396bcc95/"+item.tokenId;

//var iframeUrl = "https://ipfs.io/ipfs/QmVAWj6dsYdccjCcx2zassNxiD9cEoBTbUss8AvxZmj62s/index.html?glb="+cidfile+"&id="+item.name;
      
      
  
         return (
         
           <Grid item xs={12} md={6} lg={3} p={3} key={item.id}   >
           
              <MKBox
         
       
              sx={{
                 
                backgroundColor: "#212529",
                borderRadius: "12px",
                overflow: 'hidden'
                    
              }} >
      
      
              <MKBox textAlign="center" p={1}  >
              <Stack spacing={3}>
                <MKBox textAlign="center" >
                  <MKTypography color="white" variant="footerTextCopy" sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}  >
                    <p >File Name: {item.name} </p>
                    </MKTypography>
                </MKBox>

                {!item.tokenId?(<>
                  <Button color="primary" size="small" variant="outlined" component="label" sx={{
                      color: 'white',
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: '#26F8FF',
                        color: '#000'
                      }
                      }}
                      
                      onClick={()=>deleteFile(item)}
                      >
                      Delete
                    </Button>
                </>):(
                  null
                )
                
                }
                </Stack>
    
              </MKBox>
      
              
                <Grid container item  style={{marginTop:"10px"}}   xs={12} sm={12} md={12} lg={12} xl={12}      component="span"   
                                    
                                   
                           
                                    sx={{
                                   
                                      backgroundImage: `url(${item.imageUrl})`,
                                      backgroundSize: "cover",
                                   backgroundPosition: "center",
                                   display: "grid",
                                    placeItems: "center",
                                    position:'relative',
                                    zIndex:'1',
                                    padding:"0px",
                                    
                                        minHeight:"25vh",
                             
                                      borderRadius: "10px",
                                      
                                      '&:hover': {
                                       backgroundColor: "#000",
                                       opacity: [0.5, 0.5, 0.5]
                                     },
                             
                                    }}
               
                                 
               
               
                 onMouseEnter={e => {
                    setHover({display: 'block'});
                 }}
                 onMouseLeave={e => {
                    setHover({display: 'none'})
                 }}
               >
                   
                

                  </Grid>
                






      
              <Box textAlign={'center'} p={{ lg: '6%', xs: '2%', md: '1%' }} >
    
                <Grid item container spacing={1}>
                   <Grid
                 
                     item xs={12} md={12} lg={12} >
    
                           
      <Stack direction="row" spacing={8}>
                    <Button color="primary" size="small" variant="outlined" component="label" sx={{
                      color: 'white',
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: '#26F8FF',
                        color: '#000'
                      }
                      }}
                      
                      onClick={()=>CopyLink(itemFile)}
                      >
                      Copy Link
                    </Button>

{!item.tokenId?(<>
  <Button color="primary" size="small" variant="outlined" component="label" sx={{
                      color: 'white',
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: '#26F8FF',
                        color: '#000'
                      }
                      }}
                      
                      onClick={()=>toogleMint(item)}
                      >
                      Mint Now
                    </Button>
</>):(<>
  <a href={openseaUrl} target="blank">
  
  <Button color="primary" size ="small" variant="outlined"  component="label"  sx={{
      color:'white',
      textTransform:'capitalize',
     '&:hover': {
      backgroundColor: '#26F8FF',
      color:'#000'
    }
  }}>
   View Nft
    </Button>
  </a>
</>)}


                    </Stack>
                  </Grid>
                </Grid>
              </Box>
     
      
               
      
            </MKBox>
             
      
           
    
         </Grid>
          )
      })};
      
    </>
    
    );
      








    

    return (
        <div id="newPage">
     
 

     <Snackbar open={openNotification} autoHideDuration={6000} onClose={()=>setOpenNotification(false)}>
            <Alert onClose={()=>setOpenNotification(false)} severity={notificationMessageType}>{notificationMessage}</Alert>
      </Snackbar>

      <Snackbar open={openNotificationCopy} autoHideDuration={6000} onClose={()=>setOpenNotificationCopy(false)}>
            <Alert onClose={()=>setOpenNotificationCopy(false)} severity="success">{notificationMessage}</Alert>
      </Snackbar>



        
    
                      <>

            <Grid container item pl={{ base: '2px', md: '10px', lg: '120px' }} pr={{ base: '2px', md: '10px', lg: '120px' }} >

                

            <Grid item xs={12} md={6} lg={4} p={3}  >   
   
   <MKButton   size ="small" type="submit" color="primary"  sx={{
   
   color:'#000',
       '&:hover': {
    backgroundColor: '#26F8FF',
     color:'#000'
        }
    }}    onClick={()=>Popup()}>
              Upload New File
</MKButton>
</Grid>




             <Grid container  >

                    
                  


                {collectionItemCardCol4}

 
          </Grid>
          

      
            </Grid>

</>
         


            
<Modal open={showProfile} onClose={closeProfile} 
  disableEscapeKeyDown sx={{ display: "grid", placeItems: "center" }}>
    <Slide direction="down" in={showProfile} timeout={500}>
                    <MKBox
                        p={2}
        position="relative"
                        width="350px"
                        height="350px"
        display="flex"
        flexDirection="column"
        borderRadius="xl"
        bgColor="black"
              shadow="xl"
              
         
      >


              <MKBox display="flex" alginItems="center" justifyContent="space-between">
                  <MKTypography variant="h5">File Upload
                  </MKTypography>
                <CloseIcon   
               
                 fontSize="medium" sx={{ cursor: "pointer" }} onClick={closeProfile} />
                </MKBox>

 
                <hr style={{ color: 'white' }} />
       
                        

                <Grid container item  xs={12} sm={12} md={12} lg={12} xl={12} component="span"   
                                    
                                   
                           
                                    sx={{
                                      border: '2px dashed grey',
                                
                                   backgroundPosition: "center",
                                   display: "grid",
                                    placeItems: "center",
                                    position:'relative',
                                    zIndex:'1',
                                    padding:"0px",
                                      height: "460px",
                                      borderRadius: "10px",
                                      
                                      '&:hover': {
                                       backgroundColor: "#000",
                                       opacity: [0.5, 0.5, 0.5]
                                     },
                             
                                    }}
          
               >
                   
                 
                    



                  
                     {upload == false ?
                      (
                      <>
                            <IconButton color="primary"  style={{ fontSize:"50px"}}     aria-label="upload picture" component="label">
                              <input hidden type="file" accept=".glb, .gltf" onChange={(e)=> handleGltfChange(e)} ref={gltfInput} />
                        

                         {circles == true ? (
                                      <CircularProgress
                                            size={38}
                                          sx={{
                                        color: "#26F8FF",
                                             textAlign: 'center'
                                             }}
                                      />
                         ) : (
                               <>
                                 
                            <CloudUploadIcon/>
                          
                             </>
                         )
                      
                         }   
                         
                             </IconButton>
                       </>
                        ) : (
                     <>
                       

                     
                            {circles == true ? (
                                      <CircularProgress
                                            size={38}
                                          sx={{
                                        color: "#26F8FF",
                                             textAlign: 'center'
                                             }}
                               />
                               ) : (
                                 <>


<model-viewer id="canvas" style={{  width: "100%", height: "100%", borderRadius: "10px" }}
                         src={gltfFileView} shadow-intensity="1">
                    
                    
                       <IconButton color="primary"    style={{width:"100%", marginTop:"400px" }} aria-label="upload picture" component="label">
                              <input hidden type="file" accept=".glb, .gltf" onChange={ handleGltfChange} ref={gltfInput} />
                            <CloudUploadIcon/> 
                     
                           
                       </IconButton>
                       </model-viewer>
                       </>
                             )
                          
                             }   
                     </>
                      
                      )}
                 
            
                 </Grid>
                               
                               
              
              
          

       
      </MKBox>
    </Slide>
  </Modal>



            


  <Modal open={showMintModal} onClose={closeMint}   sx={{ 
    display: "grid", placeItems: "center",
    overflow:'scroll',
    height:'100%'}}>
    <Slide direction="down" in={showMintModal} timeout={500}>
      <MKBox
        position="relative"
        width="750px"
        display="flex"
        flexDirection="column"
        borderRadius="xl"
        bgColor="black"
        shadow="xl"
        padding="20px"
      >
             
 
      

             
             <MKBox display="flex" alginItems="center" justifyContent="space-between">
                  <MKTypography variant="h5">Mint Nft
                  </MKTypography>
                  <CloseIcon   
               
               fontSize="medium" sx={{ cursor: "pointer", zIndex:'20', color:'#fff' }} onClick={closeMint} />
                </MKBox>
       

        <Stack spacing={2}>
        
    <>
        
         
<form onSubmit={handleSubmit(onSubmit)}>
             

              
             <Grid pt={2} pl={{lg:'30%', xs:'2%', md:'1%'}} pr={{lg:'30%', xs:'2%', md:'1%'}} container  spacing={3} >
             
             


               
                 <Grid  item xs={12} sm={12} md={12} lg={12} xl={12} >

                  
             {circles == true ? (
                                      <CircularProgress
                                            size={38}
                                          sx={{
                                        color: "#26F8FF",
                                             textAlign: 'center'
                                             }}
                                      />
                         ) : (null)}
             
                   <InputLabel shrink htmlFor="bootstrap-input">
                    Full Name* 
                 </InputLabel>
              
                 <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  The Name must contain alphabetical characters (letters)  only.  </Typography>
                
                <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>
             
                   <TextField   placeholder="Item name" fullWidth {...register("fullName", {required: true})} />
             
                 </Grid>
             
             
             
                 <Grid  item xs={12} sm={12} md={12} lg={12} xl={12} >
             
                 <InputLabel shrink htmlFor="bootstrap-input">
                   Supply* 
                 </InputLabel>
                
                 <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  The number of items that can be minted. No gas cost to you!  </Typography>
                
                 <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>
              
              
                 <TextField  fullWidth type="number"
                      InputProps={{
                        inputProps: { 
                          max: 10, min: 1 
                          }
                   }}
                   placeholder="Supply" 
                   
                   {...register("supply", {required: true})}/>
             
             
                 </Grid>
               
             
                 <Grid  item xs={12} sm={12} md={12} lg={12} xl={12}  >
                  
             
                   <InputLabel shrink htmlFor="bootstrap-input">
                    External link* 
                 </InputLabel>
                 
                 <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}> You are welcome to link to your own webpage with more details.  </Typography>
                
                 <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>
              
             
                   <TextField  fullWidth    placeholder="https://yoursite.io/item/123" {...register("externalUrl", {required: true})} />
             
             
             
                    
                 </Grid>
             
             
             
             
             
                  <Grid  item  xs={12} sm={12} md={12} lg={12} xl={12}>
             
              
                             <InputLabel shrink htmlFor="bootstrap-input">
                                 Description 
                             </InputLabel>
              
                            <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  The description will be included on the item's detail page underneath its image. Markdown syntax is supported.  </Typography>
                 
                            <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>
             
             
                 
             
                <TextField
             
                     multiline
                   rows={4}
                   style={{width:"100%"}}
                   placeholder="Provide a detailed description of your item."
                   {...register("description", {required: true})}
                      />    
               </Grid>
               
             
             
             
             
             <Grid  item  xs={12} sm={12} md={12} lg={12} xl={12}>
               
               <Button   type="submit" variant="contained" color="primary"  sx={{
             
             width:"100%",
             color:'#000',
             '&:hover': {
             backgroundColor: '#26F8FF',
             color:'#000'
             }
             }}>MINT</Button>
              
             </Grid>
             
             
             <br/><br/>  
             
             </Grid>   
             
             </form>

        </>

        
</Stack>


     
      </MKBox>
    </Slide>
  </Modal>

        </div>
    )
}

export default FileUpload