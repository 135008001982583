
import Back from "../assets/images/loginBg.jpg";
import MKBox from "components/MKBox";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import CircularProgress from '@mui/material/CircularProgress';
import Image from "mui-image";
import { useAccount, useConnect, useDisconnect, useEnsName } from 'wagmi';
import {  useState, useEffect } from "react";
import { useDispatch , useSelector} from 'react-redux'
import axios from 'axios'
import { Box } from "@mui/material";
import Button from '@mui/material/Button';
import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  
} from "@stripe/react-stripe-js";

import { useNavigate } from 'react-router-dom';
import moment from "moment";


var url = window.location.search;

console.log(url);
let newdata =[];

function ManageSubscribe() {

  const { address, isConnected } = useAccount();
  const navigate = useNavigate();
  const currUser = useSelector(state => state.user);
  const [loading,setLoading]= useState(true);

  const [message,setMessage] = useState();


useEffect(()=>{
const fetchOrder =async()=>{



const response = await axios.get(`https://mint.infinityvoid.io/gameApi/stripeorder/?filter[walletAddress]=${address.toLowerCase()}`);
console.log(response.data.rows);

for(let i=0; i<response.data.rows.length; i++){
  var data = response.data.rows[i];
  var activeScene =  await axios.get(`https://mint.infinityvoid.io/gameApi/masterinventory/${data.unitId}`);
  Object.assign(data, {unitName:activeScene.data.name});

  newdata.push(data);
 
}

await delay(4000).then(()=>{
  setLoading(false);
})


console.log(newdata);
}
  fetchOrder();
},[newdata])

  
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const resumeSubscribe = async(item)=>{
    console.log(item);

    var data ={
      status:"active"
    }

    await axios.put(`https://mint.infinityvoid.io/gameApi/stripeOrder/cancelSubscription/${item.id}`,data).then((res)=>{
if(res.status == 200){
  window.location.reload(true);
}
})
  }

  const cancelSubscribe = async(item)=>{
console.log(item);

var data ={
  status:"pending"
}

await axios.put(`https://mint.infinityvoid.io/gameApi/stripeOrder/cancelSubscription/${item.id}`,data).then((res)=>{
if(res.status == 200){
  window.location.reload(true);
}
})

  }


  const ItemCard = newdata && newdata.map(item => {

    
    var Itemid = item.id;

   
    return (

      <Grid item xs={12} md={6} lg={3} p={3} key={item.id} >

<MKBox key={item.id} 
     
   
   sx={{
           
             backgroundColor: "#212529",
             borderRadius:"12px",
              
           }} >



<MKBox textAlign="center" p={1}  >
     
         <MKBox textAlign="center"  >
         <MKTypography color="white" variant="footerTextCopy"  sx={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.unitName}</MKTypography>
           </MKBox>
    
</MKBox>

<MKBox
    minHeight="25vh"
    width="100%"
    sx={{
      backgroundImage: `url("https://dashboard.infinityvoid.io/Nftplaceholder.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
     
    }}
   
/> 
<Box textAlign={'center'} p={{lg:'6%', xs:'2%', md:'1%'}} >

<Grid item container spacing={1}>
     <Grid item xs={6} md={6} lg ={6} >

{item.status == "active"?(
  <Button onClick={()=>cancelSubscribe(item)} color="primary" size ="small" variant="outlined"  component="label"  sx={{
    color:'white',
    textTransform:'capitalize',
   '&:hover': {
    backgroundColor: '#26F8FF',
    color:'#000'
  }
}}>
 Cancel Subscription
  </Button>
):(
<Button  color="primary" size ="small" variant="outlined"  component="label"  sx={{
    color:'white',
    textTransform:'capitalize',
   '&:hover': {
    backgroundColor: '#26F8FF',
    color:'#000'
  }
}}>
 Pending
 {moment(item.endDate).format('DD/MM/YYYY')}

  </Button>
)}


</Grid>

<Grid item xs={6} md={6} lg ={6} >

{item.status == "active"?(
 <Button  color="primary" size ="small" variant="outlined"  component="label"  sx={{
  color:'white',
  textTransform:'capitalize',
 '&:hover': {
  backgroundColor: '#26F8FF',
  color:'#000'
}
}}>End Date:
{moment(item.endDate).format('DD/MM/YYYY')}
</Button>
):(
<Button onClick={()=>resumeSubscribe(item)}  color="primary" size ="small" variant="outlined"  component="label"  sx={{
    color:'white',
    textTransform:'capitalize',
   '&:hover': {
    backgroundColor: '#26F8FF',
    color:'#000'
  }
}}>
 Resume
  </Button>
)}

</Grid>
</Grid>
</Box>

           </MKBox>

   
</Grid>
    )
          })


 
  return (
    <>       
    
    
  <MKBox  pt={2}>
    
    <Grid container item pl={{ base: '2px', md: '10px', lg: '135px' }} pr={{ base: '2px', md: '10px', lg: '135px' }} pb={12}>
    {!loading?(
      <>
        {ItemCard}
        </>
    ):(
      <Grid item xs={12} lg={12} md={12}  >
      <MKBox   sx={{display: "grid", placeItems: "center", textAlign:'center'}}>
      <CircularProgress
      size={38}
      sx={{
        color: "#26F8FF",
        textAlign:'center'
      }}
    />
    </MKBox>
   </Grid>
    )} 
    
     </Grid>
    
      
      </MKBox>


   
    </>
  );
}

export default ManageSubscribe;
