import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building302(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building302.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0.031]} scale={2.54}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_1.geometry}
            material={materials.Bianca_Carrara_Gioia_Marble}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_2.geometry}
            material={materials.Bianca_Carrara_Gioia_Marble_1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_3.geometry}
            material={materials.Crema_Cenia_Marble}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_4.geometry}
            material={materials.Granite_Brown}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_5.geometry}
            material={materials.M_0096_SkyBlue}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_6.geometry}
            material={materials.Material__749}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_7.geometry}
            material={materials.PLain}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_8.geometry}
            material={materials.Translucent_Glass_Gray}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_9.geometry}
            material={materials._LightGray_}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_BuildingElevation_10.geometry}
            material={materials.adskMatPLain}
          />
        </group>
      </group>
    </group>
  );
}

