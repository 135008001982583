import { createSlice } from "@reduxjs/toolkit"

const masterInventoryDetail = createSlice({
    name: 'masterInventoryDetail',
    initialState: {
      name:'',
      category:'',
      inventorySetting:{
        allowedAddress:[],
        entryType:'',
        allowedContractAddress:[],
        mapImageUrl:'',
        externalWebUrl:'',
        e1BannerImageUrl:'',
      }
    },
    reducers: {
 

        getMasterInventoryDetailSlice: (state, action) => {
            state = action.payload
            return state
        },

    }
})

export const { getMasterInventoryDetailSlice } = masterInventoryDetail.actions

export default masterInventoryDetail.reducer