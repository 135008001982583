import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building504(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building504.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_04_1.geometry}
            material={materials.MI_Marble_17_SM_Building_05_04}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_04_2.geometry}
            material={materials.MI_Veneer_09_SM_Building_05_04}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_04_3.geometry}
            material={materials["Material__305.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_04_4.geometry}
            material={materials["Material__307.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_04_5.geometry}
            material={materials.Material__309}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_04_6.geometry}
            material={materials.Material__310}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_04_7.geometry}
            material={materials.NewMaterial1}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_04_8.geometry}
            material={materials.NewMaterial_Inst4}
          />
        </group>
      </group>
    </group>
  );
}

