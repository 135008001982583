

import axios from 'axios'

axios.defaults.baseURL = 'https://mint.infinityvoid.io/gameApi'



export const getUsersAPI =  async () => axios.get('/gameUser')
export const getUserByIdAPI = async (id) => {
    axios.get(`/gameUser/${id}`)
}

export const getUserByWalletAPI = async (walletAddress) => {
    const newAddress = walletAddress && walletAddress.toLowerCase();
    const response = await axios.get(`/gameUser/byWallet/${newAddress}`);

    return response.data;
}

export const createUserAPI = async (user) => {
console.log(user);
    axios.post(`/gameUser`, user)
}


export const createbuilderSceneAPI = async (data) => {
    console.log(data);
      axios.post(`/builderscene`, data).then(function (response) {
        if(response.status =200){
            window.location.replace('https://dashboard.infinityvoid.io/inventory/?Experience');
 
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }


export const updateUserAPI = async (id,data) => {
 
    axios.put(`/gameUser/${id}`, data)
}

export const getMasterInventoryAPI =  async (walletAddress) => {
    const newAddress = walletAddress && walletAddress.toLowerCase();
    const response = await axios.get(`/masterInventory?filter[currentOwner]=${newAddress}`);
   
     return response.data


}


export const getBuilderSceneAPI =  async (walletAddress) => {
    const newAddress = walletAddress && walletAddress.toLowerCase();
    
    const response = await axios.get(`/builderscene?filter[fileOwner]=${newAddress}`);
   console.log(response);
     return response.data


}


export const getBuilderSceneSearchAPI =  async (walletAddress,searchQuerry) => {
    const newAddress = walletAddress && walletAddress.toLowerCase();
    const response = await axios.get(`/builderscene?filter[name]=${searchQuerry}&filter[fileOwner]=${newAddress}`);
 
     return response.data


}


export const getMasterInventorySearchAPI =  async (walletAddress,searchQuerry) => {
    const newAddress = walletAddress && walletAddress.toLowerCase();
    const response = await axios.get(`/masterInventory?filter[name]=${searchQuerry}&filter[currentOwner]=${newAddress}`);
 
     return response.data


}



export const getMasterInventoryByIdAPI = async (id) => {

    const response = await axios.get(`/masterInventory/${id}`);

    return response.data

    
}

export const getMasterInventoryByCategoryAPI = async (category,walletAddress) => {
    const response = await axios.get(`/masterInventory?filter[category]=${category}`);
    const newAddress = walletAddress && walletAddress.toLowerCase();
    const filterdata = response.data.rows.filter(e => e.currentOwner == newAddress);

    return filterdata

}

export const updateMasterInventoryAPI = async (id,data) => {

    axios.put(`/masterInventory/${id}`, data)
}

export const updateBuilderSceneAPI = async (id,data) => {
  
    axios.put(`/builderScene/${id}`, data).then(function (response) {
       
        if(response.status =200){
            window.location.replace('https://dashboard.infinityvoid.io/inventory');

          
        }
    
      })
      .catch(function (error) {
        console.log(error);
      });
}

export const getENSAPI = async (walletAddress) => {
    const response = await axios.get(`/masterInventory?filter[category]=UName`);
    const newAddress = walletAddress && walletAddress.toLowerCase();
    const filterdata = response.data.rows.filter(e => e.currentOwner == newAddress);
    return filterdata

}



export const getBuildingSelectAPI =  async () => axios.get(`/plotBuilding`);
 


export const getBuildingSelectSearchAPI =  async (data) => {
    console.log(data)                                                
    const response = await axios.get(`/plotBuilding?filter[types]?q=${data}  `);
    return response.data
}





export const getBuildingSelectByIdAPI = async (id, ) => {
    const response = await axios.get(`/plotBuilding/${id}`);
    return response.data   
}




                        
export const updatePlotBuildingAPI = async (id, data) => {
  
    axios.put(`/plotBuilding/${id}`,  data)
}

