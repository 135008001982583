import { Fragment, useState, useEffect } from "react";
import MKBox from "components/MKBox";
import { Header } from "./header";
import HeaderBanner from "./headerBanner";

import { SubHeader } from "./subHeader";
import { useAccount} from 'wagmi';

let crossAdd = localStorage.getItem("crossmintAddress");



  
export function Layout() {
    const {isConnected } = useAccount();
 
useEffect(()=>{
 
  if(crossAdd !== null){
   console.log(crossAdd);
  }
},[crossAdd])

    
    return (

  <MKBox> 
<Header/>

  {isConnected  ?(
    <>
    <SubHeader/>
    <HeaderBanner/>
    </>
  ):(null
  )}

  

 </MKBox>
    );
}