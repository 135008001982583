import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function  Building1601(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building1601.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_16_01_1.geometry}
            material={materials["MI_Color_01.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_16_01_2.geometry}
            material={materials.MI_Metal_15_SM_Building_16_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_16_01_3.geometry}
            material={materials.Material__331}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_16_01_4.geometry}
            material={materials.Material__332}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_16_01_5.geometry}
            material={materials.Material__336}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_16_01_6.geometry}
            material={materials["NewMaterial_Inst3.005"]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB//Building1601.glb");

