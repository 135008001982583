import { createSlice } from "@reduxjs/toolkit"

const masterInventory = createSlice({
    name: 'masterInventorySlice',
    initialState: [{
        rows: [],
        count: 0,
        loading: false,
        filter: {},
        selectedKeys: [],
    }],
    reducers: {
        getMasterInventorySlice: (state, action) => {
            state = action.payload
            return state
        },
        
        addMasterInventorySlice: (state, action) => {
            state.push(action.payload)
            return state
        },
        editMasterInventorySlice: (state, action) => {
            state = state.map(i => i.id == action.payload.id ? action.payload : i)
            return state
        }
    }
})

export const { getMasterInventorySlice, addMasterInventorySlice, editMasterInventorySlice } = masterInventory.actions

export default masterInventory.reducer 