
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building2201(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building2201.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_01_1.geometry}
            material={materials.MI_Concrete_Floor_wbstejl_2K_Inst}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_01_2.geometry}
            material={materials.MI_Marble_01_SM_Building_22_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_01_3.geometry}
            material={materials.MI_Metal_18_SM_Building_22_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_01_4.geometry}
            material={materials.MI_Veneer_08_SM_Building_22_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_01_5.geometry}
            material={materials.Material__727}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_01_6.geometry}
            material={materials["NewMaterial1.006"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_01_7.geometry}
            material={materials.NewMaterial2_Inst5_SM_Building_22_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_01_8.geometry}
            material={materials["NewMaterial_Inst3.011"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_01_9.geometry}
            material={materials["Object_Formica_Speckled_Grey_1.009"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_22_01_10.geometry}
            material={materials["WorldGridMaterial.008"]}
          />
        </group>
      </group>
    </group>
  );
}

