import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building1201(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building1201.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_12_01_1.geometry}
            material={materials.MI_Color_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_12_01_2.geometry}
            material={materials.MI_Metal_01_SM_Building_12_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_12_01_3.geometry}
            material={materials.MI_Veneer_08_SM_Building_12_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_12_01_4.geometry}
            material={materials["NewMaterial_Inst3.002"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_12_01_5.geometry}
            material={materials["Object_Formica_Speckled_Grey_1.001"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_12_01_6.geometry}
            material={materials["WorldGridMaterial.003"]}
          />
        </group>
      </group>
    </group>
  );
}
