
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building501(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building501.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_01_1.geometry}
            material={materials.MI_Color_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_01_2.geometry}
            material={materials.MI_Color_04}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_01_3.geometry}
            material={materials.MI_Leather_02_SM_Building_05_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_01_4.geometry}
            material={materials.MI_Marble_01_SM_Building_05_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_01_5.geometry}
            material={materials.MI_Marble_06_SM_Building_05_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_01_6.geometry}
            material={materials.MI_Metal_05_SM_Building_05_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_01_7.geometry}
            material={materials.MI_Metal_15_SM_Building_05_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_01_8.geometry}
            material={materials.NewMaterial_Inst3}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_05_01_9.geometry}
            material={materials.NewMaterial_Inst4}
          />
        </group>
      </group>
    </group>
  );
}


