
const TYPES = {
    GET_USERS      : 'GET_USERS',
    
    GET_USER_BY_ID : 'GET_USER_BYid',
    GET_USER_BY_WALLET_ADDRESS: 'GET_USER_BYwalletaddress',
    CREATE_USER    : 'CREATE_USER',

    UPDATE_USER_BY_ID : 'UPDATE_USER_BYid',
    DELETE_USER_BY_ID : 'DELETE_USER_BYid',

    GET_INVENTORY      : 'GET_INVENTORY',
    GET_INVENTORY_BY_ID      : 'GET_INVENTORY_Byid',
    GET_INVENTORY_BY_CATEGORY: 'GET_INVENTORY_BYcategory',
    UPDATE_INVENTORY_BY_ID : 'UPDATE_INVENTORY_BYid',
    GET_ENS      : 'GET_ENS',
    GET_INVENTORY_SEARCH :'GET_INVENTORY_SEARCH',
    GET_BUILDER_SCENES_SEARCH :'GET_BUILDER_SCENES_SEARCH',
    GET_BUILDER_SCENES      : 'GET_BUILDER_SCENES',
    CREATE_BUILDER_SCENES    : 'CREATE_BUILDER_SCENES',
    UPDATE_BUILDER_SCENES      : 'UPDATE_BUILDER_SCENES',
    GET_BUILDING_SELECT : 'GET_BUILDING_SELECT',
    GET_BUILDING_SELECT_BY_ID : 'GET_BUILDING_SELECT_BY_ID',
    GET_BUILDING_SELECT_SEARCH : 'GET_BUILDING_SELECT_SEARCH',

  }
  
  export default TYPES