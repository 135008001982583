import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Alchemy, Network } from "alchemy-sdk";
import logoImage from "./assets/images/logoicon.webp"
import InfiLogo from "./assets/images/infilogo.png"
import { polygon, mainnet } from '@wagmi/core/chains'



import { Buffer } from "buffer";
import { Web3AuthConnector } from "@web3auth/web3auth-wagmi-connector";
import { Web3Auth } from "@web3auth/modal";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { IAdapter, log, LoginMethodConfig, WALLET_ADAPTERS } from "@web3auth/base";
import { CHAIN_NAMESPACES } from "@web3auth/base"
import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { Chain } from "wagmi";
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
 
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { createPublicClient, http } from 'viem'




Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

/*
const { chains, provider, webSocketProvider } = configureChains(
  [mainnet, polygon],
  [alchemyProvider({ apiKey: 'yourAlchemyApiKey' }), publicProvider()],
)
const { chains, provider, webSocketProvider } = configureChains(
  [mainnet, polygon],
  [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_APP_API_KEY })],
)
*/



const {
  chains,
publicClient,
webSocketPublicClient
} = configureChains(
  [mainnet, polygon],
  [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_APP_API_KEY })],
)

const chainConfig =   {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: "0x" + chains[0].id.toString(16),
  rpcTarget: chains[0].rpcUrls.default.http[0], // This is the public RPC we have added, please pass on your own endpoint while creating an app
  displayName: chains[0].name,
  tickerName: chains[0].nativeCurrency?.name,
  ticker: chains[0].nativeCurrency?.symbol,
  blockExplorer: chains[0].blockExplorers?.default.url[0],
}

const web3AuthInstance = new Web3Auth({
  clientId: "BEzqEVQs4ICKxokQCyOqFzI5BumHGUCAmIAJ8cPEb1tegu0yatDQcx_La9HUq8DLuJ9Yjrc13Fdg0Xhbk3um87M",
  chainConfig,
  uiConfig: {
    appName: "W3A",
    theme: "dark",
    loginMethodsOrder: ["google","twitter","discord","linkedin","twitch"],
    defaultLanguage: "en",
    appLogo: "https://web3auth.io/images/w3a-L-Favicon-1.svg", // Your App Logo Here
    modalZIndex: "2147483647",
    loginGridCol: 2,
    primaryButton: "externalLogin",
  },
});

const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });
/*
const openloginAdapterInstance = new OpenloginAdapter({
  privateKeyProvider,
  adapterSettings: {
    network: "mainnet",
    uxMode: "redirect",
    loginConfig: {
      sms_passwordless: {
          showOnModal: false,
          showOnDesktop: false,
          showOnMobile: false,
      },
    },
    whiteLabel: {
      name: "Infinity Void",
      logoLight: "https://web3auth.io/images/w3a-L-Favicon-1.svg",
      logoDark: "https://web3auth.io/images/w3a-D-Favicon-1.svg",
      defaultLanguage: "en",
      dark: true, // whether to enable dark mode. defaultValue: false
    },
  },
});

*/
const openloginAdapterInstance = new OpenloginAdapter({
  privateKeyProvider,
  adapterSettings: {
    network: "mainnet",
    uxMode: "redirect",
    loginConfig: {
      sms_passwordless: {
          showOnModal: false,
          showOnDesktop: false,
          showOnMobile: false,
      }
  },
    whiteLabel: {
      name: "Infinity Void",
      logoLight: "https://web3auth.io/images/web-3-auth-logo-light.svg",
      logoDark: "https://web3auth.io/images/web-3-auth-logo-dark.svg",
      defaultLanguage: "en",
      dark: true, // whether to enable dark mode. defaultValue: false
    },
  },
});

web3AuthInstance.configureAdapter(openloginAdapterInstance);



const config = {
  apiKey: 'r2J0yAXJyJ4Vsh-fSm4oWIjOYlMJJ5-f',
  network: Network.MATIC_MAINNET,
};
const alchemy = new Alchemy(config);


const connectors= [
/*
  new MagicAuthConnector({
    options: {
      apiKey: "pk_live_9F873F4BDB1368A0",
      oauthOptions : {
      providers: ['google', 'discord', 'linkedin'],
      callbackUrl:"https://dashboard.infinityvoid.io/account/"},
      appName: 'Email',
      accentColor: '#26F8FF',
      customLogo: InfiLogo,
      customHeaderText: '    ',
      isDarkMode: true,
      appSub: 'Using your email or social media',
      imageIcon:'https://substackcdn.com/image/fetch/f_auto,q_auto:good,fl_progressive:steep/https%3A%2F%2Fbucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com%2Fpublic%2Fimages%2F8bdf29af-7a83-4be8-9393-e4cd57ed5254_256x256.png'
    },}),
*/ 

new Web3AuthConnector({
  chains,
  options: {
    appName: 'Email',
    appSub: 'Using your email or social media',
    imageIcon:'https://web3auth.io/images/favicon.png',
    web3AuthInstance,

    modalConfig: {
      [WALLET_ADAPTERS.OPENLOGIN]: {
        loginMethods: {
          google: {
            name: "google login",
            logoDark: "url to your custom logo which will shown in dark mode",
          },
          facebook: {
            // it will hide the facebook option from the Web3Auth modal.
            name: "facebook login",
            showOnModal: false,
          },
          linkedin: {
            // it will hide the facebook option from the Web3Auth modal.
            name: "linkedin login",
            showOnModal: true,
          },
          twitter: {
            // it will hide the facebook option from the Web3Auth modal.
            name: "twitter login",
            showOnModal: true,
          },
          github: {
            // it will hide the facebook option from the Web3Auth modal.
            name: "github login",
            showOnModal: false,
          },
          reddit: {
            // it will hide the facebook option from the Web3Auth modal.
            name: "reddit login",
            showOnModal: false,
          },
          apple: {
            // it will hide the facebook option from the Web3Auth modal.
            name: "apple login",
            showOnModal: false,
          },
          wechat: {
            // it will hide the facebook option from the Web3Auth modal.
            name: "wechat login",
            showOnModal: false,
          },
          weibo: {
            // it will hide the facebook option from the Web3Auth modal.
            name: "weibo login",
            showOnModal: false,
          },
          line: {
            // it will hide the facebook option from the Web3Auth modal.
            name: "line login",
            showOnModal: false,
          },
          twitch: {
            // it will hide the facebook option from the Web3Auth modal.
            name: "twitch login",
            showOnModal: true,
          },
          kakao: {
            // it will hide the facebook option from the Web3Auth modal.
            name: "kakao login",
            showOnModal: false,
          },
        },
      },
      [WALLET_ADAPTERS.WALLET_CONNECT_V1]: {
        showOnModal: false,
      },
      [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
        showOnModal: false,
      },
      [WALLET_ADAPTERS.TORUS_EVM]: {
        showOnModal: false,
      },
      [WALLET_ADAPTERS.METAMASK]: {
        showOnModal: false,
      },
      [WALLET_ADAPTERS.COINBASE]: {
        showOnModal: false,
      },
    },
  },
}),
  new MetaMaskConnector({ chains,   
   options: {
    appName: 'Metamask',
    appSub: 'Using your browser extension',
    imageIcon:'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png'
  }, }),

    new WalletConnectConnector({
      chains,
      options: {
        projectId: '23cc5d757cd05a82e2e1aeab51c8ef73',
        appName: 'Wallet Connect',
        appSub: 'Using a mobile wallet',
        imageIcon:'https://seeklogo.com/images/W/walletconnect-logo-EE83B50C97-seeklogo.com.png'
      },
    }),

  new CoinbaseWalletConnector({
    chains,
    options: {
      appName: 'Coinbase Wallet',
      appSub: 'Using your mobile or browser extension',
      imageIcon:'https://cdn6.aptoide.com/imgs/0/b/6/0b619167f601e6355364c4f63f0ca166_icon.png'
    },
  }),


];





const clientWagmi = createConfig({
  autoConnect: true,
  connectors: connectors,
  webSocketPublicClient,
  publicClient,
})



ReactDOM.render(
  <BrowserRouter>
  <WagmiConfig config={clientWagmi}>
  <App />
  </WagmiConfig>
       
     

 
  </BrowserRouter>,
  document.getElementById("root")
);
