import Grid from "@mui/material/Grid";
//import Back from "../assets/images/svgBg.svg";
import plotImg from "../assets/images/Plots.png";
import mallImg from "../assets/images/Mall.png"
import builderImg from "../assets/images/Builder.png";
import uNameImg from "../assets/images/UNames.png";
import MKButton from "components/MKButton";
import { Link } from 'react-router-dom';
import MKAvatar from "components/MKAvatar";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useDispatch , useSelector} from 'react-redux'
import TYPES from "../redux/types"
import { useAccount, useConnect, useDisconnect, useEnsName } from 'wagmi';
import axios from 'axios'

function Summary() {
  const currUser = useSelector(state => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { address, isConnected } = useAccount();
  const [mallUnits,setmallUnits]= useState();
  const [ensNftsUnits,setEnsNftsUnits]= useState();
  const [landUnits,setLandUnits]= useState();
  const [builderScensNumber,setBuilderScenesNumber]= useState();




  useEffect(()=>{

    if(currUser.name == undefined){
      navigate("/account");
    }
    
    getNoOfItems();

    })

    const getNoOfItems = async()=>{
      const response = await axios.get(`https://mint.infinityvoid.io/gameApi/masterInventory/?filter[currentOwner]=${address.toLowerCase()}`);
      var allitems = response.data.rows;
      var mallItems = allitems.filter(e=> e.category == "Mall").length;
      var ensItems =  allitems.filter(e=> e.category == "UName").length;
      var landItems =  allitems.filter(e=> e.category == "Land").length;
      setmallUnits(mallItems);
      setEnsNftsUnits(ensItems);
      setLandUnits(landItems);
      const responseScenes = await axios.get(`https://mint.infinityvoid.io/gameApi/builderscene/?filter[fileOwner]=${address.toLowerCase()}`);
      var allitemsScene = responseScenes.data.count;
      console.log(allitemsScene);
      setBuilderScenesNumber(allitemsScene);
  
  
    }

  return (
    <>       
    <MKBox mt={-12}  sx={{

backgroundSize: "cover",
backgroundRepeat:"no-repeat",
backgroundPosition: "center",
display: "grid",
padding:"0px"

}} >

    <MKBox mt={{ base: '120px', xs:'120px', md: '130px', lg: '135px' }} >
               <MKBox textAlign="center">    
                 <MKTypography variant="h5" style={{ color: "white" }}> <h1> Future depends upon what you do <span style={{ color:"#26F8FF"}}>now</span>! </h1> </MKTypography>
               </MKBox>
           </MKBox>


         
              <Grid container pt={5} item pl={{ base: '2px', md: '10px', lg: '120px' }} pr={{ base: '2px', md: '10px', lg: '135px' }} >
                <Grid item xs={12} md={6} lg={3} p={2} 
                
                
                >

                <MKBox   sx={{
        width: "100%",
        maxHeight: "95vh",
        border:"1px solid #26F8FF",
        borderRadius: "12px",
        backgroundColor: "#0A0519",
        opacity: [0.9, 0.9, 0.9],
        transition: "transform .2s",
        position:'relative',
        zIndex:'1',

        '&:hover': {
          backgroundColor: "#0A0519",
          opacity: [1,1, 1],
          border:"1px solid #1AADB2",
          transform: "scale(1.05)"

          
        },
      }}>


<MKBox textAlign="center" p={2} >
                       
                       <MKTypography textAlign="center" variant="subtitle1" color="white">Land Parcels</MKTypography>
                       <MKTypography textAlign="center" variant="body1" color="white">x{landUnits}</MKTypography>                   
                          <MKTypography textAlign="center" variant="subtitle2" color="white">Deploy Scenes, link names and manage permissions of your land.</MKTypography>
                             
                     <MKBox p={5}   display="flex" bgcolor="lightgreen" alignItems="center" justifyContent="center">
                             <MKAvatar src={plotImg} size="xxl" sx={{ width: 150, height: 150 }}/>
                     </MKBox>
                    

                    
                                               <Link to={`/inventory/?Land`} >
       <MKButton p={2}  style={{backgroundColor:" #00FFFF",  }}    sx={{ width: "80%" }}  >Manage Units</MKButton>
       </Link>                       
                  
                      <a href="https://docs.infinityvoid.io/digital-nft-assets/virtual-land-parcel" target="blank">
                       <MKTypography p={2} variant="subtitle1" style={{ fontSize:"13px" }}    color="white">LEARN MORE</MKTypography>
                       </a>
                   

                   </MKBox>

                    </MKBox>

               </Grid>


               <Grid item xs={12} md={6} lg={3} p={2} >

               <MKBox   sx={{
        width: "100%",
        maxHeight: "95vh",
        border:"1px solid #26F8FF",
        borderRadius: "12px",
        backgroundColor: "#0A0519",
        opacity: [0.9, 0.9, 0.9],
        transition: "transform .2s",

        '&:hover': {
          backgroundColor: "#0A0519",
          opacity: [1.9,1.9, 1.9],
          border:"1px solid #1AADB2",
          transform: "scale(1.05)"

          
        },
      }}>


<MKBox textAlign="center" p={2}>
       
       <MKTypography textAlign="center" variant="subtitle1" color="white">Mall Units</MKTypography>
   
       <MKTypography textAlign="center" variant="body1" color="white">x{mallUnits}</MKTypography>

          <MKTypography textAlign="center" variant="subtitle2" color="white">Deploy Scenes, link names and manage permissions of your units.</MKTypography>
             
     <MKBox p={5}   display="flex" bgcolor="lightgreen" alignItems="center" justifyContent="center">
             <MKAvatar src={mallImg} size="xxl" sx={{ width: 150, height: 150 }}/>
     </MKBox>
    

    
      <Link to={`/inventory/?Mall`} >
       <MKButton p={2}  style={{backgroundColor:" #00FFFF",  }}    sx={{ width: "80%" }}  >Manage Units</MKButton>
       </Link>
     
       
  
       <a href="https://docs.infinityvoid.io/digital-nft-assets/virtual-mall-unit" target="blank">
                       <MKTypography p={2} variant="subtitle1" style={{ fontSize:"13px" }}    color="white">LEARN MORE</MKTypography>
       </a>
   

   </MKBox>

    </MKBox>

</Grid>


<Grid item xs={12} md={6} lg={3} p={2} >

<MKBox   sx={{
        width: "100%",
        maxHeight: "95vh",
        border:"1px solid #26F8FF",
        borderRadius: "12px",
        backgroundColor: "#0A0519",
        opacity: [0.9, 0.9, 0.9],
        transition: "transform .2s",

        '&:hover': {
          backgroundColor: "#0A0519",
          opacity: [1.9,1.9, 1.9],
          border:"1px solid #1AADB2",
          transform: "scale(1.05)"

          
        },
      }}>


<MKBox textAlign="center" p={2}>
       
       <MKTypography textAlign="center" variant="subtitle1" color="white">Experiences</MKTypography>
       <MKTypography textAlign="center" variant="body1" color="white">x{builderScensNumber}</MKTypography>
   
          <MKTypography textAlign="center" variant="subtitle2" color="white">Create scenes for your land, mint them in form of NFT and monetise.</MKTypography>
             
     <MKBox p={5}   display="flex" bgcolor="lightgreen" alignItems="center" justifyContent="center">
             <MKAvatar src={builderImg} size="xxl" sx={{ width: 150, height: 150 }}/>
     </MKBox>
    
     <Link to={`/inventory/?Experience`} >
       <MKButton p={2}  style={{backgroundColor:" #00FFFF",  }}    sx={{ width: "80%" }}  >Manage Scenes</MKButton>
       </Link>
     
    
         
           
       
  
       <a href="https://docs.infinityvoid.io/infinity-void-ecosystem/infinity-void-scenes" target="blank">
      <MKTypography p={2} variant="subtitle1" style={{ fontSize:"13px" }}    color="white">LEARN MORE</MKTypography>
       </a>
   

   </MKBox>

    </MKBox>

</Grid>


<Grid item xs={12} md={6} lg={3} p={2} >

<MKBox   sx={{
        width: "100%",
        maxHeight: "95vh",
        border:"1px solid #26F8FF",
        borderRadius: "12px",
        backgroundColor: "#0A0519",
        opacity: [0.9, 0.9, 0.9],
        transition: "transform .2s",

        '&:hover': {
          backgroundColor: "#0A0519",
          opacity: [1.9,1.9, 1.9],
          border:"1px solid #1AADB2",
          transform: "scale(1.05)"

          
        },
      }}>


<MKBox textAlign="center" p={2}>
       
       <MKTypography textAlign="center" variant="subtitle1" color="white">Names</MKTypography>
       <MKTypography textAlign="center" variant="body1" color="white">x{ensNftsUnits}</MKTypography>
   
          <MKTypography textAlign="center" variant="subtitle2" color="white">Mint and manage names and assign it to your land, mall units or  avatar.</MKTypography>
             
     <MKBox p={5}   display="flex" bgcolor="lightgreen" alignItems="center" justifyContent="center">
             <MKAvatar src={uNameImg} size="xxl" sx={{ width: 150, height: 150 }}/>
     </MKBox>
    

    
      <Link to={`/inventory/?UName`} >
       <MKButton p={2}  style={{backgroundColor:" #00FFFF",  }}    sx={{ width: "80%" }}  >Manage Names</MKButton>
       </Link>
     
       
  
       <a href="https://docs.infinityvoid.io/digital-nft-assets/unique-names" target="blank">
      <MKTypography p={2} variant="subtitle1" style={{ fontSize:"13px" }}    color="white">LEARN MORE</MKTypography>
       </a>
   

   </MKBox>

    </MKBox>

</Grid>


            </Grid>


      
            </MKBox>

   
    </>
  );
}

export default Summary;
