import { createSlice } from "@reduxjs/toolkit"

const masterInventoryEns = createSlice({
    name: 'masterInventoryENSSlice',
    initialState: [{
        rows: [],
        count: 0,
        loading: false,
        filter: {},
        selectedKeys: [],
    }],
    reducers: {
        getMasterInventoryENSSlice: (state, action) => {
            state = action.payload
            return state
        },
        
     
    }
})

export const { getMasterInventoryENSSlice } = masterInventoryEns.actions

export default masterInventoryEns.reducer 