import { all } from "redux-saga/effects"
import { watchUsersAsync } from "./userSaga"
import { watchInventoryAsync } from "./masterInventorySaga"
import {watchBuilderSceneAsync} from "./builderSceneSaga"
import { watchBuildingSelectAsync } from "./buildingSelectSaga"

export function* rootSaga() {
    yield all([
        watchUsersAsync(),
        watchInventoryAsync(),
        watchBuilderSceneAsync(),
        watchBuildingSelectAsync()
    ])
}