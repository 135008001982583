
import { Grid } from '@mui/material';
import React ,{ useState,useEffect, useRef, } from 'react';
import { useForm } from "react-hook-form";
import { Alchemy, Network } from "alchemy-sdk";

import { Typography } from '@mui/material';

import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Web3 from "web3";

import InputLabel from '@mui/material/InputLabel';

import MKTypography from "components/MKTypography";

import CircularProgress from '@mui/material/CircularProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import Alert from '@mui/material/Alert';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { useAccount,useNetwork} from 'wagmi'
import { useWalletClient } from 'wagmi'



import { NFTStorage,  Blob } from "nft.storage"
import { Buffer } from "buffer";
import { Biconomy } from "@biconomy/mexa";
import {
  getConfig,
  getSignatureParametersWeb3
} from "../utils";


let biconomy, web3, contractInstance, tokenMetaUrl, contractTokenId, tokenSupply

const client = new NFTStorage({ token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweGZCZTI1MjAyM0VCOTFhOTJiNTE4ZkY5NDEyNDFjRWUzZTkwODlFQjQiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3ODUxNTQ1Mzk2MywibmFtZSI6IkluZmluaXR5Vm9pZE1hcCJ9.mj7AFDSMYowKjKoMpPyTjeZfSUbJ_qLgBgMfI2dgDm4" })

const config = {
  apiKey: 'r2J0yAXJyJ4Vsh-fSm4oWIjOYlMJJ5-f',
  network: Network.MATIC_MAINNET,
};
const alchemy = new Alchemy(config);

function MintAsset() {
  const { register, handleSubmit, setValue,  formState: { errors }   } = useForm({ mode: 'onBlur' });

  const { address } = useAccount()
  const { chain } = useNetwork()
  
  const { data: walletClient } = useWalletClient()



  const gltfInput = useRef();
  const [gltfFileView, setGltfFileView] = useState();
  const [formData, setformData] = useState();
  const [circles, setCircles] = useState(false);
  const timer = useRef();
  const [upload, setUpload] = useState(false);
  const [gltfCID, setGltfCID] = useState();
  const [gltfIpfsCID, setGltIpfsCID] = useState();
  const [thumbCID, setThumbCID] = useState();
  const [polygonCount, setPolygonCount] = useState();
  const [config, setConfig] = useState(getConfig("").assetMint)
  const [gltfFileName, setGltfFileName] = useState();
  const [ipfsUploadDone, setIpfsUploadDone] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [openNotification, setOpenNotification] = useState(false);
  const [notificationMessageType, setNotificationMessageType] = useState("");


  useEffect(() => {
    const conf = getConfig(chain?.id.toString() || "").assetMint
    setConfig(conf)
  }, [chain?.id])

  useEffect(() => {
    const initBiconomy = async () => {
     
    
      biconomy = new Biconomy((walletClient), {
        apiKey: config.apiKey.prod,
        debug: true,
        contractAddresses: [config.contract.address]
      })

      await biconomy.init()
      web3 = new Web3(window.ethereum)


    contractInstance =  new web3.eth.Contract(
        config.contract.abi,
        config.contract.address
      )

    /*  contractInstance =   getContract({
        address: config.contract.address,
        abi:  config.contract.abi,
      })
  */

    }
    initBiconomy()
  }, [address, chain, config, walletClient?.PublicClient])



  const handleGltfChange = async() => {

    const file = gltfInput.current.files[0];
    setGltfFileView(URL.createObjectURL(file));
    setCircles(true);
    setOpenNotification(true);
    setNotificationMessage("please wait");
    setNotificationMessageType('warning');
   const cid = await client.storeBlob(file)
    setGltIpfsCID(cid);
   var tokenUrl = "https://ipfs.io/ipfs/"+cid;
  
    setGltfCID(tokenUrl);
    setGltfFileName(file.name);

  
   
    timer.current = window.setTimeout(() => {
      
      if (file.length != 0) {
        setUpload(true)
       }

      setCircles(false)
    }, 6000);

    setOpenNotification(true);
    setNotificationMessage("File uploade complete");
    setNotificationMessageType('success');



    setTimeout(function() {
            
      canvasCheck();
      }, 10000);
  
  
  

      
    const extension = file.name.split('.').pop();
    const loader = new GLTFLoader();
    if (extension === 'glb') {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('/draco/');
      loader.setDRACOLoader(dracoLoader);
    }
    loader.load(URL.createObjectURL(file), (gltf) => {
      let numFaces = 0;
      gltf.scene.traverse((node) => {
        if (node.isMesh) {
          numFaces += node.geometry.index.count / 3; 
        }
      });
      console.log(numFaces);
      setPolygonCount(numFaces);
   
    });




  }

  

  const canvasCheck = async(e) => {
   

    setOpenNotification(true);
    setNotificationMessage("thumbnail processing");
    setNotificationMessageType('warning');

    const canvas = document.getElementById("canvas");
    const dataURL = canvas.toDataURL();

fetch(dataURL)
  .then(res => res.blob())
  .then(async(blob) => {
      const file = new File([blob], e, { type: "image/png" })
      const cid = await client.storeBlob(file)
     var tokenUrl = "https://ipfs.io/ipfs/"+cid;
     setThumbCID(tokenUrl);
     setIpfsUploadDone(true);

     setOpenNotification(true);
     setNotificationMessage("thumbnail generate succesfully");
     setNotificationMessageType('success');

    
  })
   
}

const onSubmit = async(data) => {
setformData(data);
 
  if (!address) {
    return
  }
 
  if (ipfsUploadDone) {

    const response = await alchemy.nft.getNftsForContract('0x9ED129993C80Da3679834825758403336Ab5DC88', {omitMetadata: false});
    contractTokenId = response.nfts.length+1;
    tokenSupply = data.supply;
  

   //var iframeUrl = "https://ipfs.io/ipfs/QmVAWj6dsYdccjCcx2zassNxiD9cEoBTbUss8AvxZmj62s/index.html?glb="+gltfIpfsCID+"&id="+gltfFileName;


  var  metadata = {
      "name": data.fullName,
      "description": data.description,
      "image": thumbCID,
      "externalUrl":data.externalUrl,
      "animation_url":gltfCID,
    
      "attributes": [
        {
          "trait_type": "Polygons", 
          "value": polygonCount.toString()
        }
      ]
    }
 
        const json = JSON.stringify(metadata, null, 3);
        console.log(json);
        
        const file = new Blob([json], { type: "application/json" });
        
        
        
        const cid = await client.storeBlob(file)
         tokenMetaUrl = "https://ipfs.io/ipfs/"+cid;
        
       

    let functionSignature = await contractInstance.methods
      .mint(address,contractTokenId,tokenSupply,tokenMetaUrl,"0x")
      .encodeABI()
    let message = {
 
      from: address,
      functionSignature: functionSignature
    }
    const domainType = [
      { name: "name", type: "string" },
      { name: "version", type: "string" },
      { name: "verifyingContract", type: "address" },
      { name: "salt", type: "bytes32" }
    ]
    const metaTransactionType = [
      //  { name: "nonce", type: "uint256" },
      { name: "from", type: "address" },
      { name: "functionSignature", type: "bytes" }
    ]
    let domainData = {
      name: "InfinityAssetV3",
      version: "1",
      verifyingContract: config.contract.address,
      salt: "0x" + (chain?.id || 137).toString(16).padStart(64, "0")
    }
    const dataToSign = JSON.stringify({
      types: {
        EIP712Domain: domainType,
        MetaTransaction: metaTransactionType
      },
      domain: domainData,
      primaryType: "MetaTransaction",
      message: message
    })



    web3.currentProvider.send(
      {
        jsonrpc: "2.0",
        id: 999999999999,
        method: "eth_signTypedData_v4",
        params: [address, dataToSign]
      },
      function(error, response) {
        console.info(`User signature is ${response.result}`)
        if (error || (response && response.error)) {
        
        } else if (response && response.result) {
          let { r, s, v } = getSignatureParametersWeb3(response.result)
          sendSignedTransaction(address, functionSignature, r, s, v)
        }
      }
    )
  } else {
    console.log("Sending normal transaction")
    setOpenNotification(true);
    setNotificationMessage("Please wait ");
    setNotificationMessageType('error');

  }
}


const sendSignedTransaction = async (userAddress, functionData, r, s, v) => {
  try {
    const web3 = new Web3(biconomy.provider)
    const contractInstance = new web3.eth.Contract(
      config.contract.abi,
      config.contract.address
    )
    biconomy.on("txHashGenerated", data => {
   
    
    })
    biconomy.on("txMined", data => {
    
    
    
    })
    const tx = await contractInstance.methods
      .mint(address,contractTokenId,tokenSupply,tokenMetaUrl,"0x")
      .send({
        from: userAddress
      })
   
  } catch (error) {
    console.log(error)

  }
}




    return (
        <>
     
       
     <Snackbar open={openNotification} autoHideDuration={6000} onClose={()=>setOpenNotification(false)}>
            <Alert onClose={()=>setOpenNotification(false)} severity={notificationMessageType}>{notificationMessage}</Alert>
      </Snackbar>

<Grid
  container

  alignItems="center"
  justifyContent="center"
  
>

  <Grid item   style={{ width:"80%"}}   >
        

               <Grid container  md={12} sm={12}  lg={12} xl={12} style={{paddingTop:"20px"}}>
              
                      <MKTypography color="primary" variant="subtitle1">
                          Create New Item
                      </MKTypography>

            </Grid>
            


            <form onSubmit={handleSubmit(onSubmit)}>
             

              
            <Grid pt={2} pl={{lg:'30%', xs:'2%', md:'1%'}} pr={{lg:'30%', xs:'2%', md:'1%'}} container  spacing={3} >
             
                     <Grid  item  xs={12} sm={12} md={12} lg={12} xl={12}  >
                 
                 
                 
                          <InputLabel shrink htmlFor="bootstrap-input">
                               3D File Upload* 
                           </InputLabel>
             
                           <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  File types: GLB,GLTF,Max Size: 100MB  </Typography>
                
                          <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>

                                 <Grid container item  xs={12} sm={12} md={12} lg={12} xl={12} component="span"   
                                    
                                   
                           
                                     sx={{
                                       border: '2px dashed grey',
                                 
                                    backgroundPosition: "center",
                                    display: "grid",
                                     placeItems: "center",
                                     position:'relative',
                                     zIndex:'1',
                                     padding:"0px",
                                       height: "460px",
                                       borderRadius: "10px",
                                       
                                       '&:hover': {
                                        backgroundColor: "#000",
                                        opacity: [0.5, 0.5, 0.5]
                                      },
                              
                                     }}
           
                >
                    
                  
                     



                   
                      {upload == false ?
                       (
                       <>
                             <IconButton color="primary"  style={{ fontSize:"50px"}}     aria-label="upload picture" component="label">
                               <input hidden type="file" accept=".glb, .gltf" onChange={(e)=> handleGltfChange(e)} ref={gltfInput} />
                         

                          {circles == true ? (
                                       <CircularProgress
                                             size={38}
                                           sx={{
                                         color: "#26F8FF",
                                              textAlign: 'center'
                                              }}
                                       />
                          ) : (
                                <>
                                  
                             <CloudUploadIcon/>
                           
                              </>
                          )
                       
                          }   
                          
                              </IconButton>
                        </>
                         ) : (
                      <>
                        

                      
                             {circles == true ? (
                                       <CircularProgress
                                             size={38}
                                           sx={{
                                         color: "#26F8FF",
                                              textAlign: 'center'
                                              }}
                                />
                                ) : (
                                  <>


<model-viewer id="canvas" style={{  width: "100%", height: "100%", borderRadius: "10px" }}
                          src={gltfFileView} shadow-intensity="1" camera-controls touch-action="pan-y">
                     
                     
                        <IconButton color="primary"    style={{width:"100%", marginTop:"400px" }} aria-label="upload picture" component="label">
                               <input hidden type="file" accept=".glb, .gltf" onChange={ handleGltfChange} ref={gltfInput} />
                             <CloudUploadIcon/> 
                      
                            
                        </IconButton>
                        </model-viewer>
                        </>
                              )
                           
                              }   
                      </>
                       
                       )}
                  
             
                  </Grid>
                                
                           
                  
                     </Grid>



                   
              
                <Grid  item xs={12} sm={12} md={12} lg={12} xl={12} >
          
                  <InputLabel shrink htmlFor="bootstrap-input">
                   Full Name* 
                </InputLabel>
             
                <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  The Name must contain alphabetical characters (letters)  only.  </Typography>
               
               <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>
            
                  <TextField   placeholder="Item name" fullWidth {...register("fullName", {required: true})} />

                </Grid>



                <Grid  item xs={12} sm={12} md={12} lg={12} xl={12} >
           
                <InputLabel shrink htmlFor="bootstrap-input">
                  Supply* 
                </InputLabel>
               
                <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  The number of items that can be minted. No gas cost to you!  </Typography>
               
                <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>
             
             
                <TextField  fullWidth type="number"
                     InputProps={{
                       inputProps: { 
                         max: 10, min: 0 
                         }
                  }}
                  placeholder="Supply" 
                  
                  {...register("supply", {required: true})}/>


                </Grid>
              

                <Grid  item xs={12} sm={12} md={12} lg={12} xl={12}  >
                 
            
                  <InputLabel shrink htmlFor="bootstrap-input">
                   External link* 
                </InputLabel>
                
                <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}> You are welcome to link to your own webpage with more details.  </Typography>
               
                <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>
             

                  <TextField  fullWidth    placeholder="https://yoursite.io/item/123" {...register("externalUrl", {required: true})} />



                   
                </Grid>


            

         
                 <Grid  item  xs={12} sm={12} md={12} lg={12} xl={12}>
       
             
                            <InputLabel shrink htmlFor="bootstrap-input">
                                Description 
                            </InputLabel>
             
                           <Typography mt={-1} style={{ color: "white", fontSize: "11px" }}>  The description will be included on the item's detail page underneath its image. Markdown syntax is supported.  </Typography>
                
                           <Typography mt={1} shrink htmlFor="bootstrap-input"> </Typography>


                

               <TextField
         
                    multiline
                  rows={4}
                  style={{width:"100%"}}
                  placeholder="Provide a detailed description of your item."
                  {...register("description", {required: true})}
                     />    
              </Grid>
              
           



<Grid  item  xs={12} sm={12} md={12} lg={12} xl={12}>
              
              <Button   type="submit" variant="contained" color="primary"  sx={{

width:"100%",
color:'#000',
'&:hover': {
backgroundColor: '#26F8FF',
color:'#000'
}
}}>MINT</Button>
             
</Grid>


<br/><br/>  

  </Grid>   

  </form>
  <br/><br/>
</Grid> 
</Grid>
        

        </>
    )

}


export default MintAsset

