import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Building1701(props) {
  const { nodes, materials } = useGLTF("https://preset-buildings.fra1.cdn.digitaloceanspaces.com/GLB/Building1701.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={100}>
        <group rotation={[Math.PI / 2, -1.484, 0]} scale={0.01}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_17_01_1.geometry}
            material={materials["MI_Color_01.005"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_17_01_2.geometry}
            material={materials.MI_Marble_01_SM_Building_17_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_17_01_3.geometry}
            material={materials.MI_Marble_02_SM_Building_17_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_17_01_4.geometry}
            material={materials.MI_Marble_07_SM_Building_17_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_17_01_5.geometry}
            material={materials.MI_Metal_07_SM_Building_17_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_17_01_6.geometry}
            material={materials.MI_Metal_15_SM_Building_17_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_17_01_7.geometry}
            material={materials.MI_Veneer_08_SM_Building_17_01}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_17_01_8.geometry}
            material={materials.Material__702}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_17_01_9.geometry}
            material={materials["NewMaterial_Inst3.006"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_17_01_10.geometry}
            material={materials["NewMaterial_Inst4.004"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_17_01_11.geometry}
            material={materials["Object_Formica_Speckled_Grey_1.005"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_17_01_12.geometry}
            material={materials["Object_Formica_Speckled_Grey_1_Inst.003"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_17_01_13.geometry}
            material={materials["WorldGridMaterial.006"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.SM_Building_17_01_14.geometry}
            material={materials.adskMatMI_Color_01}
          />
        </group>
      </group>
    </group>
  );
}



